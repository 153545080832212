export const UNICPODE_RELOAD = '\u21BB';
export const UNICODE_LANGUAGE = '\u6587';
export const UNICODE_GLOBE = '\uD83C\uDF10';
export const UNICODE_LEFT_RIGHT_ARROW = '\u2B0D';
export const UNICODE_MULTIPLY = '\u00D7';
export const UNICODE_PLUS = '\uFF0B';
export const UNICODE_CIRCLE = '\u25EF';
export const UNICODE_DASH = '\u2212';
export const UNICODE_PLUS_CODE = '\u002B';
export const UNICODE_UP_ARROW = '\u2BC5';
export const UNICODE_DOWN_ARROW = '\u2BC6';
export const ASCII_SPACE = '&nbsp;';
export const ASCII_PLUS = '&#43;';
export const ASCII_DASH = '&#45;';
