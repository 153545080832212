import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs';
import { FormatStateModel } from './format.statemodel';
import { Format } from './format.actions';
import { tPgService } from '../../core/services/tPg.service';

@State<FormatStateModel>({
  name: 'format',
  defaults: {
    formatLocalCell: null,
  },
})
@Injectable()
export class FormatState {
  constructor(private _tPgService: tPgService) {}

  @Selector([FormatState])
  static getFormatLocalCell(state: FormatStateModel) {
    return state.formatLocalCell;
  }

  @Action(Format.FormatLocalCell)
  formatLocalCell(
    ctx: StateContext<FormatStateModel>,
    action: Format.FormatLocalCell
  ) {
    return this._tPgService.formatLocalCell(action.payload).pipe(
      tap((data: any) => {
        // Retrieve the current state
        const state = ctx.getState();
      })
    );
  }
}
