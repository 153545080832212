import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { FontSelectServiceService } from '../../services/font-select-service/font-select-service.service';
import { DialogFontComponent } from '../dialog-font/dialog-font.component';
import { Dialog } from '@angular/cdk/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FontStyle } from '../../store/fontStyle/font.actions';

@Component({
  selector: 'app-format-local-shared-col',
  // standalone: true,
  // imports: [],
  templateUrl: './format-local-shared-col.component.html',
  styleUrls: ['./format-local-shared-col.component.css']
})
export class FormatLocalSharedColComponent  implements OnInit{
  formatLocalColForm: FormGroup;
  private modalRef!: NgbModalRef;
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  constructor(
    public dialogRef: MatDialogRef<FormatLocalSharedColComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: FormBuilder,
    private service:FontSelectServiceService,
    private dialog:MatDialog,
    private modalService: NgbModal,
  ) {
    this.formatLocalColForm = this.fb.group({
      formatId: [{ value: 'Format ID', disabled: true }],
      formatUser: [{ value: 'Format User', disabled: true }],
      columnId: [{ value: 'Column ID', disabled: true }],
      status: [''],
      minWidth: [''],
      fontStyle: [''],
      comment: [''],
      transactions: [{ value: 'Transactions', disabled: true }]
    });
  }
  ngOnInit(): void {
    this.open(this.content);
  }
  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: this.data,
    });
    dialogRef.afterClosed().subscribe((result) => {
      let alignment;
        switch (this.service.getTextAlignment()) {
          case '0':
            alignment = 'justify';
            break;
          case '1':
            alignment = 'left';
            break;
          case '2':
            alignment = 'right';
            break;
          default:
            alignment='';
            break;
        }
        let type;
        switch (this.service.getSelectedFontType()) {
          case '0':
            type = 'Regular';
            break;
          case '1':
            type = 'Italic';
            break;
          case '2':
            type = 'Bold';
            break;
          default:
            type='Regular';
            break;
        }
       const fontStyle= {
          "FontStyle": {"Asian text font": this.service.getAsianTextFont(),
          "Font Style": type,
          "Size": this.service.getFontSize(),
          "Font": this.service.getFontFamily(),
          "Font Color": this.service.getTextColor(),
          "Background Color": this.service.getBackgroundColor(),
          "Text Alignment": alignment,
          "Effects": this.service.getFontStyle()
        }
        }

        this.formatLocalColForm = this.fb.group({
          formatId: [{ value: 'Format ID', disabled: true }],
          formatUser: [{ value: 'Format User', disabled: true }],
          columnId: [{ value: 'Column ID', disabled: true }],
          status: this.formatLocalColForm.value['status'],
          minWidth:  this.formatLocalColForm.value['minWidth'],
          fontStyle: JSON.stringify(fontStyle),
          comment: this.formatLocalColForm.value['comment'],
          transactions: [{ value: 'Transactions', disabled: true }]
        });
    });
}
  onSave(): void {
    if (this.formatLocalColForm.valid) {
      this.dialogRef.close({
        fontStyleMinWidth: `${this.formatLocalColForm.value['fontStyle']}: ${this.formatLocalColForm.value['minWidth']}`,
        formatLocalColForm: this.formatLocalColForm.value
      });
      this.modalRef.dismiss();
      this.modalService.dismissAll();
    } else {
      console.log('Form is invalid');
    }
  }

  onCancel(): void {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if(this.modalService){
      this.modalService.dismissAll();
    }
    this.modalRef.close();
    this.modalService.dismissAll();
  }
  open(content: any) {
    if (this.modalRef) {
      this.modalRef.close();
    }
    if(this.modalService){
      this.modalService.dismissAll();
    }
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      this.dialog.closeAll();
      return 'by pressing ESC';
      
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      this.dialog.closeAll();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
