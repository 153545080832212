<ng-template #content let-modal>
    <angular-window style="background-color:white; border-radius: 3px; width: auto;
    min-width: 313px;" [dragHolder]="header">
      <div class="modal-header" #header>
        <span class="heading"></span>
        <span class="heading">View: Item (ID:{{clicked}})</span>
        <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');dialogRef.close()">
          <span aria-hidden="true">{{closeTab}}</span>
        </button>
      </div>
      <div class="edit-item-container" *ngIf="clicked=='Item'">
       
        <form  [formGroup]="formatLocalColForm">
        <div class="form-group">
          <label class="main-label-item" for="item-input font-style">Page ID</label>
          <input type="text" id="page_id" formControlName="page_id" class="text-field" >
          <label class="main-label-item" for="item-input font-style">Format Local-Item</label>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Format ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Format User</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Local-Item ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Status</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Formula</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Font-Style</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Comment</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Transactions</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="form-group">
          <label class="main-label-item" for="item-input font-style">Format Shared-Item</label>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Format ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Format User</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Shared-Item ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Status</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Formula</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Comment</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Item Transactions</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Column Transactions</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div class="label-container">
          <label class="field-label" for="item-input font-style">Pages sharing Item</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
      </form>
      </div>
      <div class="edit-item-container" *ngIf="clicked=='Cell'">
       
        <form  [formGroup]="formatLocalColForm">
        <div class="form-group">
          <label class="main-label-item" for="item-input font-style">Page ID</label>
          <input type="text" id="page_id" formControlName="page_id" class="text-field" >
          <label class="main-label-item" for="item-input font-style">Format Local-Cell</label>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Format ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Format User</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Local-Cell ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Data-Type</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell DropDown-Source</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Default-Data</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Status</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Formula</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Font-Style</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Comment</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Transactions</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="form-group">
          <label class="main-label-item" for="item-input font-style">Format Shared-Item</label>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Format ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Format User</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Shared-Cell ID</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Data-Type</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell DropDown-Source</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Default-Data</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Status</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Formula</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Cell Font-Style</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Column Component</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Column Transactions</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
        <div class="item-field-container" >
          <div>
          <label class="field-label" for="item-input font-style">Pages sharing Cell</label>
          </div>
          <div>
            <input type="text" id="page_id" formControlName="page_id" class="text-field-item" >
          </div>
        </div>
      </form>
      </div>
    </angular-window>
  </ng-template>