import {
  Component,
  Input,
  ElementRef,
  HostListener,
  Renderer2,
  OnDestroy
} from '@angular/core';
import { TooltipService } from '../../../services/tooltip-service/tooltip.service';
@Component({
  selector: 'app-tooltip',
  template: '<ng-content></ng-content>',
  styleUrls: ['./tooltip.component.css'],
  standalone: true,
})
export class TooltipComponent implements OnDestroy {
  @Input() tooltip!: string;
  private tooltipElement: HTMLElement | null = null;
  private hoverTimeout: any;
  private tooltipId: string;

  constructor(private elementRef: ElementRef, private renderer: Renderer2, private tooltipService: TooltipService) {
    this.tooltipId = Math.random().toString(36).substring(2);
    this.tooltipService.activeTooltip$.subscribe(activeTooltipId => {
      if (activeTooltipId && activeTooltipId !== this.tooltipId) {
        this.hideTooltip(); // Hide current tooltip if another one is active
      }
    });
  }

  @HostListener('mouseenter')
  onMouseEnter() {
    clearTimeout(this.hoverTimeout);
    // Set a timeout to show the tooltip after a delay
    this.hoverTimeout = setTimeout(() => {
      this.tooltipService.showTooltip(this.tooltipId); // Notify service to show this tooltip
      this.showTooltip();
    }, 500); // Delay before showing the tooltip
  }

  @HostListener('mouseleave')
  onMouseLeave() {
    clearTimeout(this.hoverTimeout);
    this.hideTooltip();
    this.tooltipService.hideTooltip(); // Notify service to hide this tooltip
  }

  showTooltipTemporarily(duration: number = 3000): void {
    this.showTooltip();
    setTimeout(() => {
      this.hideTooltip();
    }, duration);
}

  showTooltip(): void {
    if (!this.tooltipElement) {
      this.createTooltip();
    }
    if (this.tooltipElement) {
      this.tooltipElement.innerHTML = this.tooltip; // Set the tooltip content
      this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
      this.updateTooltipPosition();
    }
  }

  createTooltip(): void {
    this.tooltipElement = this.renderer.createElement('div');
    if (this.tooltipElement) {
      this.tooltipElement.innerText = this.tooltip;
      this.renderer.addClass(this.tooltipElement, 'custom-tooltip');
      this.renderer.appendChild(document.body, this.tooltipElement);
      this.renderer.setStyle(this.tooltipElement, 'position', 'absolute');
      this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
      this.updateTooltipPosition();
    }
  }

  updateTooltipPosition(): void {
    const hostElem = this.elementRef.nativeElement;
    const rect = hostElem.getBoundingClientRect();
    const tooltipRect = this.tooltipElement!.getBoundingClientRect();

    let top = rect.bottom + window.scrollY + 1;
    let left = rect.left + (rect.width - tooltipRect.width) / 2;
    if (left + tooltipRect.width > window.innerWidth) {
      left = window.innerWidth - tooltipRect.width - 10;
    }
    if (left < 0) {
      left = 2;
    }

    this.renderer.setStyle(this.tooltipElement, 'top', `${top}px`);
    this.renderer.setStyle(this.tooltipElement, 'left', `${left}px`);
    this.renderer.setStyle(this.tooltipElement, 'opacity', '1');
  }

  hideTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.setStyle(this.tooltipElement, 'opacity', '0');
      setTimeout(() => {
        this.removeTooltip();
      }, 300); // Match the CSS transition duration
    }
  }

  removeTooltip(): void {
    if (this.tooltipElement) {
      this.renderer.removeChild(document.body, this.tooltipElement);
      this.tooltipElement = null;
    }
  }

  ngOnDestroy(): void {
    clearTimeout(this.hoverTimeout);
    this.removeTooltip();
  }
}