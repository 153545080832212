import { Injectable } from '@angular/core';
import { LanguageStateModel } from './language.statemodel';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { tap } from 'rxjs';
import { Language } from './language.actions';

@State<LanguageStateModel>({
  name: 'language',
  defaults: {
    languages: [],
  },
})
@Injectable()
export class LanguageState {
  constructor(private _tPgService: tPgService) {}

  @Selector([LanguageState])
  static getLanguages(state: LanguageStateModel) {
    return state.languages;
  }
  @Action(Language.FetchLanguages)
  getLanguages(
    ctx: StateContext<LanguageStateModel>,
    action: Language.FetchLanguages
  ) {
    return this._tPgService.getLanguages(action.sheetId).pipe(
      tap((data: any) => {
        const state = ctx.getState();
        const stateModel: LanguageStateModel = {
          languages: data,
        };
        // Update the existing state
        ctx.setState({
          ...state,
          ...stateModel,
        });
      })
    );
  }
}
