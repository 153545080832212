export const constant = {
    original_Url: '3000000325',
    backup_Url: '3000000326'
}
export const pagesId = {
    All_Pages : 1000000001,
    AllCols: 1000000006,
}
export const PAGE_IDS = {
    ALL_REGIONS: 1000000013
}
export const COL_STATUSES = {
    HIDDEN: 'Hidden',
    DDS_COL: 'DDS-Col'
}