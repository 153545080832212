import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { DialogFontComponent } from '../dialog-font/dialog-font.component';
import { FontSelectServiceService } from '../../services/font-select-service/font-select-service.service';
import { MenuBarService } from '../../services/menu-bar-service/manu-bar.service';
import { UNICODE_MULTIPLY } from '../../shared/unicode/unicode';

@Component({
  selector: 'app-format-page-dialog',
  templateUrl: './format-page-dialog.component.html',
  styleUrls: ['./format-page-dialog.component.css'],
})
export class FormatPageDialogComponent implements OnInit {
  formatId!: string; // Assuming this is populated
  pageId!: string; // Assuming this is populated
  formatPageForm!: FormGroup;
  closeResult: string = '';
  closeTab=UNICODE_MULTIPLY;
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  constructor(
    private fb: FormBuilder, 
    public dialogRef: MatDialogRef<FormatPageDialogComponent>,
    public dialog: MatDialog,
    public service:FontSelectServiceService,
    public menuBarService:MenuBarService,
    private modalService: NgbModal,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.formatPageForm = this.fb.group({
      formatUser: [''],
      pageNestedColumn: [''],
      pageFreezeColumn: [''],
      pageExpand: [''],
      pageSort: [''],
      pageFilter: [''],
      pageLevelSet: [''],
      pageSearchSet: [''],
      pageFontStyle: [''],
      pageComment: [''],
      pageTransactions: [''] // For handling transaction list
    });
  }

  ngOnInit() {
    this.open(this.content);
    // Set initial values if necessary
    this.formatPageForm.addControl('formatId', this.fb.control({ value: this.formatId, disabled: true }));
    this.formatPageForm.addControl('pageId', this.fb.control({ value: this.pageId, disabled: true }));
  }


  addTransaction(transaction: string) {

  }

  removeTransaction(transaction: string) {
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  openFontDialog() {
    const dialogRef = this.dialog.open(DialogFontComponent, {
      width: '400px',
      data: this.data
    });     
    dialogRef.afterClosed().subscribe((result) => {
      
      let alignment;
        switch (this.service.getTextAlignment()) {
          case '0':
            alignment = 'justify';
            break;
          case '1':
            alignment = 'left';
            break;
          case '2':
            alignment = 'right';
            break;
          default:
            alignment='';
            break;
        }
        let type;
        switch (this.service.getSelectedFontType()) {
          case '0':
            type = '0';
            break;
          case '1':
            type = '1';
            break;
          case '2':
            type = '2';
            break;
          default:
            type='0';
            break;
        }
        
        this.formatPageForm = this.fb.group({
          formatUser: [''],
          pageNestedColumn: [''],
          pageFreezeColumn: [''],
          pageExpand: [''],
          pageSort: [''],
          pageFilter: [''],
          pageLevelSet: [''],
          pageSearchSet: [''],
          pageFontStyle: this.service.getTextColor()+';'+this.service.getFontFamily()+';'+this.service.getFontSize()+';'+type+';'+alignment+';'+this.service.getBackgroundColor()+';'+this.service.getFontStyle(),
          pageComment: [''],
          pageTransactions: [''] // For handling transaction list
        });
    });
}
  onSave(): void {
    this.dialogRef.close(this.formatPageForm.value);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}