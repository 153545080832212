<ng-template #content let-modal>
  <angular-window style="background-color:white; max-width: 95vw; max-height: 95vh;" [dragHolder]="header">
    <div class="modal-header" #header>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click'); dialogRef.close()">
        <span aria-hidden="true">{{closeTab}}</span>
      </button>
    </div>
    <div class="content-container">
      <span class="form-title">View Page</span>
      <div class="section">
        <span class="section-title">Cell Information</span>
        <div class="" *ngFor="let column of data.columns" style=" padding: 2px;">
          <label class="font-style" style="margin: 0px;">{{ column.title }}:&nbsp;</label>
          <span class="font-style">{{ getFieldValue(data.cell, column.field) }}</span>
        </div>
      </div>
      <div class="section">
        <span class="section-title">Format Fields</span>
        <div class="" *ngFor="let key of getObjectKeys(data.format)">
          <label class="font-style" style="margin: 0px;">{{ processedFormatKeys[key] }}:&nbsp;</label>
          <span class="font-style">{{ data.format[key] }}</span>
        </div>
      </div>
    </div>
    <div class="button-container">
      <div class="button-close-container">
        <div class="Chip" (click)="onCancel()">Close</div>
      </div>
    </div>
  </angular-window>
</ng-template>