import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-view-col-dialog',
  templateUrl: './view-col-dialog.component.html',
  styleUrls: ['./view-col-dialog.component.css']
})
export class ViewColDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<ViewColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
  }

  onClose(): void {
    this.dialogRef.close();
  }
}
