import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { Sheet } from '../../store/sheet/sheet.actions';

@Component({
  selector: 'app-dialog-delete',
  templateUrl: './dialog-delete.component.html',
  styleUrls: ['./dialog-delete.component.css'],
})
export class DialogDeleteComponent implements OnInit {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store
  ) {}

  ngOnInit() {
    this.open(this.content);
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  onDelete(): void {
    if(this.data.name=='Item'){
      this.store.dispatch(new Sheet.DeleteItemData({
        "userId": '3000000099',
        "Object": 3000001015,
        "colId": this.data.colId,
        "rowId": this.data.rowId,
    },this.data.selectedPage)).subscribe((res)=>{
      if(res) {
        this.dialogRef.close(this.data.name);
        this.modalRef.dismiss('Save click');
      }
    });
    }
    else{
    this.store.dispatch(new Sheet.DeleteRow({RowId:this.data.rowId,PageId:this.data.selectedPage})).subscribe((res) => {
      if(res) {
        this.dialogRef.close(this.data.name);
        this.modalRef.dismiss('Save click');
      }
    })
  }
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }
}
