<ng-template #content let-modal>
  <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header"  >
    <div class="modal-header" #header>
      <span class="heading">Edit-Amount-dialog</span>
    </div>
    <div class="edit-item-container">
      <form>
        <!-- First Row of Dropdowns -->
        <div class="form-group row-group first-group">
          <div class="first-half-width">
            <label class="font-style">Qty</label>
            <input type="text" placeholder="" class="font-style input-field" name="url">
          </div>

          <div class="second-half-width">
            <label class="font-style">Unit</label>
            <select class="language-select">
            </select>
          </div>

        </div>
        <!-- Second Row of Dropdowns -->
        <div class="form-group row-group">
          <div class="first-half-width">
            <label class="font-style">StdQty</label>
            <input type="text" placeholder="" class="font-style input-field" name="url">
          </div>
          <div class="second-half-width">
            <label class="font-style">StdUnit</label>
            <input type="text" placeholder="" class="font-style input-field" disabled name="url">
          </div>
        </div>

        <!-- Buttons -->
        <div class="button-group">
          <div class="Chip" type="button">Save</div>
          <div type="button" class="Chip">Cancel</div>
        </div>
      </form>
    </div>
  </angular-window>
</ng-template>