import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { Edition } from '../../modals/page.modal';
import { Sheet } from '../../store/sheet/sheet.actions';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root'
})
export class MenuBarService {
  private sessionId$ = new BehaviorSubject<string>('User:ABC.Tab:5');
  private uiRegion$ = new BehaviorSubject<string>('');
  private uiCurrency$ = new BehaviorSubject<string>('USD');
  private isLoggedIn$ = new BehaviorSubject<boolean>(false);
  private message$ = new BehaviorSubject<string>('');
  private currentMode$ = new BehaviorSubject<'View' | 'Format' | 'Edit'>('View');
  private sortStatus$ = new BehaviorSubject<string>('Sort is Off');
  private filterStatus$ = new BehaviorSubject<string>('Filter is Off');
  private isBlinking$ = new BehaviorSubject<boolean>(true);
  private pagesSource = new BehaviorSubject<any[]>([]);
   pageFormate = new BehaviorSubject<any>('');
   pageFormateReg = new BehaviorSubject<any>('');
   pageFormateFreeze = new BehaviorSubject<any>('');
   Expand_Visibilt = new BehaviorSubject<any>({ n: 0, x: null, y: null, z: null });
   Expand_Visibilt_Reg = new BehaviorSubject<any>({ n: 0, x: null, y: null, z: null });
  pages$ = this.pagesSource.asObservable();
  deletePage = new BehaviorSubject<boolean>(false);
  public deletePages: EventEmitter<number> = new EventEmitter();
pgRow = new BehaviorSubject<any>('');
selectedId = new BehaviorSubject<any>('')
  languageListKeys = {
    'Afrikaans': 'af',
    'Albanian': 'sq',
    'Amharic': 'am',
    'Arabic': 'ar',
    'Armenian': 'hy',
    'Assamese': 'as',
    'Aymara': 'ay',
    'Azerbaijani': 'az',
    'Bambara': 'bm',
    'Basque': 'eu',
    'Belarusian': 'be',
    'Bengali': 'bn',
    'Bhojpuri': 'bho',
    'Bosnian': 'bs',
    'Bulgarian': 'bg',
    'Catalan': 'ca',
    'Cebuano': 'ceb',
    'Chichewa': 'ny',
    'Chinese (Simplified)': 'zh-CN',
    'Chinese (Traditional)': 'zh-TW',
    'Corsican': 'co',
    'Croatian': 'hr',
    'Czech': 'cs',
    'Danish': 'da',
    'Dhivehi': 'dv',
    'Dogri': 'doi',
    'Dutch': 'nl',
    'English': 'en',
    'Esperanto': 'eo',
    'Estonian': 'et',
    'Ewe': 'ee',
    'Filipino': 'fil',
    'Finnish': 'fi',
    'French': 'fr',
    'Frisian': 'fy',
    'Galician': 'gl',
    'Georgian': 'ka',
    'German': 'de',
    'Greek': 'el',
    'Guarani': 'gn',
    'Gujarati': 'gu',
    'Haitian Creole': 'ht',
    'Hausa': 'ha',
    'Hawaiian': 'haw',
    'Hebrew': 'he',
    'Hindi': 'hi',
    'Hmong': 'hmn',
    'Hungarian': 'hu',
    'Icelandic': 'is',
    'Igbo': 'ig',
    'Ilocano': 'ilo',
    'Indonesian': 'id',
    'Irish': 'ga',
    'Italian': 'it',
    'Japanese': 'ja',
    'Javanese': 'jv',
    'Kannada': 'kn',
    'Kazakh': 'kk',
    'Khmer': 'km',
    'Kinyarwanda': 'rw',
    'Konkani': 'gom',
    'Korean': 'ko',
    'Krio': 'kri',
    'Kurdish (Kurmanji)': 'ku',
    'Kurdish (Sorani)': 'ckb',
    'Kyrgyz': 'ky',
    'Lao': 'lo',
    'Latin': 'la',
    'Latvian': 'lv',
    'Lingala': 'ln',
    'Lithuanian': 'lt',
    'Luganda': 'lg',
    'Luxembourgish': 'lb',
    'Macedonian': 'mk',
    'Maithili': 'mai',
    'Malagasy': 'mg',
    'Malay': 'ms',
    'Malayalam': 'ml',
    'Maltese': 'mt',
    'Maori': 'mi',
    'Marathi': 'mr',
    'Meiteilon (Manipuri)': 'mni-Mtei',
    'Mizo': 'lus',
    'Mongolian': 'mn',
    'Myanmar (Burmese)': 'my',
    'Nepali': 'ne',
    'Norwegian': 'no',
    'Odia (Oriya)': 'or',
    'Oromo': 'om',
    'Pashto': 'ps',
    'Persian': 'fa',
    'Polish': 'pl',
    'Portuguese': 'pt',
    'Punjabi': 'pa',
    'Quechua': 'qu',
    'Romanian': 'ro',
    'Russian': 'ru',
    'Samoan': 'sm',
    'Sanskrit': 'sa',
    'Scots Gaelic': 'gd',
    'Sepedi': 'nso',
    'Serbian': 'sr',
    'Sesotho': 'st',
    'Shona': 'sn',
    'Sindhi': 'sd',
    'Sinhala': 'si',
    'Slovak': 'sk',
    'Slovenian': 'sl',
    'Somali': 'so',
    'Spanish': 'es',
    'Sundanese': 'su',
    'Swahili': 'sw',
    'Swedish': 'sv',
    'Tajik': 'tg',
    'Tamil': 'ta',
    'Tatar': 'tt',
    'Telugu': 'te',
    'Thai': 'th',
    'Tigrinya': 'ti',
    'Tsonga': 'ts',
    'Turkish': 'tr',
    'Turkmen': 'tk',
    'Twi': 'ak',
    'Ukrainian': 'uk',
    'Urdu': 'ur',
    'Uyghur': 'ug',
    'Uzbek': 'uz',
    'Vietnamese': 'vi',
    'Welsh': 'cy',
    'Xhosa': 'xh',
    'Yiddish': 'yi',
    'Yoruba': 'yo',
    'Zulu': 'zu'
  };
  languageList = [
    'Afrikaans', 'Albanian', 'Amharic', 'Arabic', 'Armenian', 'Assamese', 'Aymara', 'Azerbaijani', 'Bambara',
    'Basque', 'Belarusian', 'Bengali', 'Bhojpuri', 'Bosnian', 'Bulgarian', 'Catalan', 'Cebuano', 'Chichewa',
    'Chinese (Simplified)', 'Chinese (Traditional)', 'Corsican', 'Croatian', 'Czech', 'Danish', 'Dhivehi', 'Dogri',
    'Dutch', 'English', 'Esperanto', 'Estonian', 'Ewe', 'Filipino', 'Finnish', 'French', 'Frisian', 'Galician',
    'Georgian', 'German', 'Greek', 'Guarani', 'Gujarati', 'Haitian Creole', 'Hausa', 'Hawaiian', 'Hebrew', 'Hindi',
    'Hmong', 'Hungarian', 'Icelandic', 'Igbo', 'Ilocano', 'Indonesian', 'Irish', 'Italian', 'Japanese', 'Javanese',
    'Kannada', 'Kazakh', 'Khmer', 'Kinyarwanda', 'Konkani', 'Korean', 'Krio', 'Kurdish (Kurmanji)', 'Kurdish (Sorani)',
    'Kyrgyz', 'Lao', 'Latin', 'Latvian', 'Lingala', 'Lithuanian', 'Luganda', 'Luxembourgish', 'Macedonian', 'Maithili',
    'Malagasy', 'Malay', 'Malayalam', 'Maltese', 'Maori', 'Marathi', 'Meiteilon (Manipuri)', 'Mizo', 'Mongolian',
    'Myanmar (Burmese)', 'Nepali', 'Norwegian', 'Odia (Oriya)', 'Oromo', 'Pashto', 'Persian', 'Polish', 'Portuguese',
    'Punjabi', 'Quechua', 'Romanian', 'Russian', 'Samoan', 'Sanskrit', 'Scots Gaelic', 'Sepedi', 'Serbian', 'Sesotho',
    'Shona', 'Sindhi', 'Sinhala', 'Slovak', 'Slovenian', 'Somali', 'Spanish', 'Sundanese', 'Swahili', 'Swedish', 'Tajik',
    'Tamil', 'Tatar', 'Telugu', 'Thai', 'Tigrinya', 'Tsonga', 'Turkish', 'Turkmen', 'Twi', 'Ukrainian', 'Urdu', 'Uyghur',
    'Uzbek', 'Vietnamese', 'Welsh', 'Xhosa', 'Yiddish', 'Yoruba', 'Zulu'
  ];
  edition:string='';
  fontStyles:string='';
  AllPageData = [
    { row_id: 3000000001, page_id: 1000000001, page_name: "All Pages", page_type: "Page List", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Pg; Page; Pages", page_status: "System", page_comment: "Page: Listing of all Pages in the system. Said Page is displayed in the AllPage-dialog.", row_type: "Pg-Row", row_status: "System", _children:[
    { row_id: 3000000006, page_id: 1000000006, page_name: "All Cols", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Col; Cols; Column", page_status: "System", page_comment: "Page: Listing of all Cols in the system. Said Page is displayed in the AllCol-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row: 3000000015, page_id: 1000000015, page_name: "All Models", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Model; Models", page_status: "System; DDS Page", page_comment: "All Product Model's in the system. Said Page is displayed in the AllModel-dialog.", row_type: "Pg-Row", row_status: "System", _children:[
    { row_id: 3000000007, page_id: 1000000007, page_name: "All Profiles", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Profile; Profiles", page_status: "System; Missing Data", page_comment: "Page: All Profiles in the system. Said Page is displayed in the AllProfile-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row: 3000000015, page_id: 1000000015, page_name: "All Models", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Model; Models", page_status: "System; DDS Page", page_comment: "All Product Model's in the system. Said Page is displayed in the AllModel-dialog.", row_type: "Pg-Row", row_status: "System" },]} ,]},
    { row_id: 3000000006, page_id: 1000000006, page_name: "All Cols", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Col; Cols; Column", page_status: "System", page_comment: "Page: Listing of all Cols in the system. Said Page is displayed in the AllCol-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000009, page_id: 1000000009, page_name: "All Tokens", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Token; Tokens", page_status: "System", page_comment: "Page: Predefined DropDownSource's in the system. Said Page is displayed in the AllDDS-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000012, page_id: 1000000012, page_name: "All Languages", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Language; Languages", page_status: "System; DDS Page", page_comment: "Page: All the supported Languages in the system. Said Page is displayed in the AllLanguage-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000013, page_id: 1000000013, page_name: "All Regions", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Region; Regions", page_status: "System; DDS Page", page_comment: "Page: All Country/Region's in the system. Said Page is displayed in the AllRegion-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000014, page_id: 1000000014, page_name: "All Suppliers", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Supplier; Suppliers", page_status: "System; DDS Page", page_comment: "Page: All Manufacturer/Brand/Vendor's in the system. Said Page is displayed in the AllSupplier-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000015, page_id: 1000000015, page_name: "All Models", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Model; Models", page_status: "System; DDS Page", page_comment: "Page: All (Product) Model's in the system. Said Page is displayed in the AllModel-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000011, page_id: 1000000011, page_name: "All Units", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Unit; Units", page_status: "System; DDS Page", page_comment: "Page: All Measure-Unit's in the system. Said Page is displayed in the AllUnit-dialog.", row_type: "Pg-Row", row_status: "System" },
    { row_id: 3000000010, page_id: 1000000010, page_name: "All Labels", page_type: "", page_edition: "Default", page_owner: "Admin", page_url: "URL to open this Page", page_seo: "Label; Labels", page_status: "System; DDS Page", page_comment: "Page: All Label-Source's in the system. Said Page is displayed as part of the AllLabel-dialog.", row_type: "Pg-Row", row_status: "System" },
  ];

  constructor(
    private store: Store
  ) { }
  
  getSessionId(): Observable<string> {
    return this.sessionId$.asObservable();
  }

  get uiRegion(): Observable<string> {
    return this.uiRegion$.asObservable();
  }

  get uiCurrency(): Observable<string> {
    return this.uiCurrency$.asObservable();
  }

  get isLoggedIn(): Observable<boolean> {
    return this.isLoggedIn$.asObservable();
  }

  get message(): Observable<string> {
    return this.message$.asObservable();
  }

  get currentMode(): Observable<'View' | 'Format' | 'Edit'> {
    return this.currentMode$.asObservable();
  }


  get sortStatus(): Observable<string> {
    return this.sortStatus$.asObservable();
  }

  get filterStatus(): Observable<string> {
    return this.filterStatus$.asObservable();
  }

  get isBlinking(): Observable<boolean> {
    return this.isBlinking$.asObservable();
  }

  refreshPage() {
    console.log('Refreshing the current PG-Tab');
    this.isBlinking$.next(false);
  }

  refreshFilterCheckbox() {
    this.filterStatus$.next('Filter is Off');
  }

  blinkRefresh() {
    this.isBlinking$.next(true);
  }


  showLanguageOptions() {
    console.log('Display Language options');
  }

  showCurrencyOptions() {
    console.log('Display Currency options');
  }

  handleCheckboxChange(event: Event) {
    const checkbox = event.target as HTMLInputElement;
    console.log(`${checkbox.name} is now ${checkbox.checked ? 'checked' : 'unchecked'}`);
  }

  toggleLanguageDropdown() {}

  toggleRegionDropdown() {}

  toggleCurrencyDropdown() {}

  toggleUserDialog() {}

  showTooltip(menuItem: string) {}

  hideTooltip() {}


  setRegion(region: string) {
    this.uiRegion$.next(region);
  }

  setCurrency(currency: string) {
    this.uiCurrency$.next(currency);
  }
  setCurrencyList(currency:[]){
    
  }

  toggleAutoTranslate(event: any) {
    console.log(event);
  }

  login() {
    this.sessionId$.next('User:ABC.Tab:5');
    this.isLoggedIn$.next(true);
    this.message$.next('User logged in successfully.');
  }

  openProfile() {
    this.message$.next('Profile opened.');
  }

  logout() {
    this.sessionId$.next('');
    this.isLoggedIn$.next(false);
    this.message$.next('User logged out successfully.');
  }

  register() {
    this.message$.next('User registered successfully.');
  }

  closeDialog() {}

  switchViewMode() {
    const currentMode = this.currentMode$.getValue();
    let nextMode: 'View' | 'Format' | 'Edit';
    if (currentMode === 'View') {
      nextMode = 'Format';
    } else if (currentMode === 'Format') {
      nextMode = 'Edit';
    } else {
      nextMode = 'View';
    }
    this.currentMode$.next(nextMode);
    console.log(`Switched to ${nextMode} mode`);
  }

  toggleSort() {
    const currentStatus = this.sortStatus$.getValue();
    this.sortStatus$.next(currentStatus === 'Sort is Off' ? 'Sort is On' : 'Sort is Off');
  }

  toggleFilter() {
    const currentStatus = this.filterStatus$.getValue();
    this.filterStatus$.next(currentStatus === 'Filter is Off' ? 'Filter is On' : 'Filter is Off');
  }

  openEditionDialog() {
    console.log('Edition dialog opened');
  }

  openPageDialog() {
    console.log('Page dialog opened');
  }

  toggleCompare() {
    console.log('Compare toggled');
  }

  toggleTimer() {
    console.log('Timer toggled');
  }

  getPages(): Observable<any[]> {
    return this.pages$;
  }

  setPages(pages: any[]): void {
    this.pagesSource.next(pages);
  }

  getEditionList(): Observable<any[]> {
    return of(this.editions);
  }

  openEdition(editionId: any): Observable<any> {
    const edition = this.editions.find(ed => ed.id === editionId);
    return of(edition);
  }

  createNewEdition(pageName: string, pageEdition: string): Observable<any> {
    const newEdition = { id: (this.editions.length + 1).toString(), name: pageName, pageEdition: pageEdition };
    this.editions.push(newEdition);
    return of(newEdition);
  }

  private editions: any[] = [
    { id: 1, name: 'Default', description: 'Default Edition', isPrivate: false },
    { id: 2, name: 'Vendor', description: 'Vendor Edition', isPrivate: false },
    { id: 3, name: 'Consumer', description: 'Consumer Edition', isPrivate: true }
  ];

  getEditions(): Observable<Edition[]> {
    return of(this.editions);
  }

  saveEdition(edition: Edition): Observable<Edition> {
    const existingEdition = this.editions.find(e => e.name === edition.name);
    if (existingEdition) {
      Object.assign(existingEdition, edition);
    } else {
      edition.id = this.editions.length + 1;
      this.editions.push(edition);
    }
    return of(edition);
  }
  public pages = this.AllPageData
  public openedTabs: any[] = []; // Keep track of opened tabs
  public pgRows: EventEmitter<any> = new EventEmitter();

  getAllPages() {
    return this.AllPageData;
  }

  getLastOpenedEdition(pageId: number) {
    const lastOpenedTab = this.openedTabs.find(tab => tab.page_id === pageId);
    if (lastOpenedTab) {
      return lastOpenedTab;
    }
    const page = this.AllPageData.find(p => p.page_id === pageId);
    if (page && page._children) {
      let lastEdition:any = ''
       this.openedTabs.find(tab => 
        page._children.some((child) => {
          if(child.page_id === tab.page_id) {
            console.log(this.openedTabs[this.openedTabs.length-1])
            lastEdition = this.openedTabs[this.openedTabs.length-1]
          }
        })
      );
      return lastEdition || null;
    }
    return null
  }

  openPage(page: any) {
    if (page?.selectedPage === 'child') {
      const childPageId = page?.page?.page_id;
      const isChildOpened = this.openedTabs.find(tab => tab.page_id === childPageId);
      if (isChildOpened) {
        this.pgRows.emit(isChildOpened);
      } else {
        this.openedTabs.push(page?.page);
        this.pgRows.emit(page?.page);
      }
    } else {
      const parentPageId = page?.page_id;
      const lastEdition = this.getLastOpenedEdition(parentPageId);
  
      if (lastEdition) {
        if (!this.openedTabs.find(tab => tab.page_id === lastEdition.page_id)) {
          this.openedTabs.push(lastEdition);
        }
        this.pgRows.emit(lastEdition);
      } else {
        this.pgRows.emit(page?.page);
      }
    }
  }

  // Delete page based on provided page id
  deleteSheetPage() {
    // API call through store dispatch
    this.store.dispatch(new Sheet.DeletePage());
  }

  closePage(uniqueId: string) {

  }
  setPageEdition(edition:any){
    this.edition=edition;
    console.log('From Set Page Edition',edition);
  } 
  getEdition(){
    console.log('From Set Page Edition get',this.edition);
    return this.edition;
  }
  setFontStyles(fontStyles:string){
    this.fontStyles=fontStyles;
  }
  getFontStyles(){
    return this.fontStyles;
  }
  pg_id=null;
  setPageId(pg_id:any){
    this.pg_id=pg_id;
  }
  getPageId(){
    return this.pg_id;
  }
}