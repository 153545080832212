import { Action, Selector, State, StateContext } from "@ngxs/store";
import { FontStateModel } from "./font.statemodel";
import { Injectable } from "@angular/core";
import { MenuBarService } from "../../services/menu-bar-service/manu-bar.service";
import { FontStyle } from "./font.actions";
import { tap } from "rxjs";

@State<FontStateModel>({
    name: 'font',
    defaults: {
     fontStyle:null
    },
  })
  @Injectable()
  export class FontState {
    constructor(private service: MenuBarService) {}

    @Selector([FontState])
    static getFontStyle(state: FontStateModel) {
      return state.fontStyle;
    }

    @Action(FontStyle.SetFontStyle)
    setFontStyle(
      ctx: StateContext<FontStateModel>,
      action: FontStyle.SetFontStyle
    ) {
      // Call the service method which performs a side effect (e.g., updating font styles) but does not return data
      this.service.setFontStyles(action.payload);
    
      // Retrieve the current state
      const state = ctx.getState();
    
      // Prepare the new state model
      const stateModel: FontStateModel = {
        fontStyle: action.payload // Directly use action.payload
      };
    
      // Update the state with the new font style
      ctx.setState({
        ...state,
        ...stateModel
      });
    }
    
}