import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../shared/unicode/unicode';

@Component({
  selector: 'app-dialog-view',
  templateUrl: './dialog-view.component.html',
  styleUrls: ['./dialog-view.component.css']
})
export class DialogViewComponent implements OnInit {
  form!: FormGroup;
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  data: any;
  clicked:any;
  closeTab=UNICODE_MULTIPLY;
  formatLocalColForm: FormGroup;
  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogViewComponent>,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    private fb: FormBuilder
  ) {
    this.data = injectedData.data;
    this.clicked = injectedData.clicked;
    this.formatLocalColForm = this.fb.group({
      page_id: [{ value: '', disabled: true }],
    });
  }

  ngOnInit() {
    this.open(this.content);

    this.form = new FormGroup({
      edit_input: new FormControl(this.data?.value),
    });
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      // this.onCancel();
      return `with: ${reason}`;
    }
  }

  onDelete(): void {
    // this.dialogRef.close(this.editFormControlName.value);
    this.dialogRef.close(true);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }

  get editFormControlName(): FormControl {
    return this.form.get('edit_input') as FormControl;
  }
}
