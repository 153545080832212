<h1 mat-dialog-title>Save As New Edition</h1>
<div mat-dialog-content>
  <div>
    <mat-label>Page Name</mat-label>
    <input matInput [(ngModel)]="pageName">
  </div>
  <div>
    <mat-label>Page Edition</mat-label>
    <input matInput [(ngModel)]="pageEdition">
  </div>
</div>
<div mat-dialog-actions class="button-group">
  <div class="Chip" (click)="onSave()">Save</div>
  <div class="Chip" (click)="onCancel()">Cancel</div>
</div>