
<div class="edit-item-container" cdkDropList (cdkDropListDropped)="drop($event)">
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <div class="modal-title text-nowrap">Column Header</div>
    <div class="cross-icon" (click)="onClose()">
      {{ closeTab }}
    </div>
  </div>
  <div class="checkbox-item">
    <div cdkDropList (cdkDropListDropped)="drop($event)">
      <div *ngFor="let col of columns; let i = index" cdkDrag>
        <div class="col-container">
        <div class="col-item">
          <span class="drag-icon">&#9776;</span> <!-- Unicode character -->
          <input type="checkbox" [checked]="isVisible(col)" (change)="toggleColumnVisibility(col)"
          [disabled]="col.status.includes('Nested')"  class="checkbox-style" tabindex="-1" [ngStyle]="{height: height+'em',width:width+'em'}"/>
          <span class="text-nowrap">{{ col?.title }}</span>
        </div>
        </div>
      </div>
    </div>
  </div>
  <div class="resizer" (mousedown)="onResizeStart($event)" (mouseup)="onResizeEnd()">
    <div class="line1"></div>
    <div class="line2"></div>
  </div>
</div>