
<ng-template #content let-modal>
  <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
    <div class="modal-header" #header>
      <span class="heading">Edit-{{data.dataType}}-dialog</span>
    </div>
    <div class="edit-item-container">
      <form [formGroup]="form">
        <div class="fields-container" formArrayName="languageGroups">
          <div *ngFor="let group of languageGroups.controls; let i = index" [formGroupName]="i" class="language-group"
          [class.selected-row]="i<languageGroups.controls.length-1">
            <div class="form-group select-language">
              <div class="language-field-container">
              <label for="language{{i}}" class="font-style">Language(s)</label>
              <select id="language{{i}}" (ngModelChange)="onSelect($event,i)" formControlName="language" class="language-select">
                <option *ngFor="let item of data.language" [value]="item.row">
                  {{ item.language }}
                </option>
              </select>
            </div>
            </div>
            <div class="form-group rich-text-editor">
              <div class="text-editor-container">
              <label for="richText{{i}}" class="font-style">Language-specific Rich-Text(s)</label>
               <!-- TODO: Uncomment Code FOr Input Field -->
              <!-- <input type="text" id="richText{{i}}" (ngModelChange)="onValueChange($event,i)" formControlName="richText" class="font-style" > -->
              <!-- TODO: Uncomment Code FOr CK-Editor -->
              <div>
                <div class="main-container">
                  <div class="editor-container editor-container_classic-editor" #editorContainerElement>
                    <div class="editor-container__editor">
                      <div #editorElement>
                        <ckeditor [ngClass]="{'heightField': heightClass.value==true && heightClass.index==i}" #editor id="richText{{i}}" (change)="onValueChange($event,i)" formControlName="richText" [editor]="Editor" [config]="config" *ngIf="isLayoutReady" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>    
              </div>          
            </div>
            <button
            [ngStyle]="{display: languageGroups.controls.length==1?'none':'flex'}"
            type="button"
            class="close"
            aria-label="Close"
            (click)="modal.dismiss('Cross click'); dialogRef.close()"
          >
            <div class="cross-icon" (click)="removeLanguageGroup(i)">            
              {{closeIcon}}
            </div>

          </button>
          </div>
        </div>
        <div class="button-group">
          <div class="button-container">
          <div  class="Chip" (click)="onSave()">Save</div>
          </div>
          <div type="button" class="Chip" (click)="onCancel()" >Cancel</div>
        </div>
      </form>
    </div>
  </angular-window>
</ng-template>
