import { Component, ViewChild, OnInit, Inject, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { FontSelectServiceService } from '../../services/font-select-service/font-select-service.service';

@Component({
  selector: 'dialog-font',
  templateUrl: './dialog-font.component.html',
  styleUrls: ['./dialog-font.component.css']
})
export class DialogFontComponent implements OnInit,AfterViewInit {
  form!: FormGroup;
  selectedFontFamily: string = '';
  selectedFontStyle:string=''; // Property to hold the selected value
  closeResult: string = '';
  selectAsianTextFont:string='';
  selectFontSize='';
  color:any="Automatic";
  newColor:any='';
  previousColor:any='#000';
  colorBackground:any="(none)";
  strikeThrough:any=false;
  underLine:any=false;
  textWrap:any=false;
  superScript:any=false;
  italicText:any=false;
  textBorder:any=false;
  subScript:any=false;
  boldText:any=false;
  selectedAlignment:any='justify';
  position:any="bottom";
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  data: any;
  clicked:any;
  fontfamily:any;
  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogFontComponent>,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public injectedData: any,
    public fontService:FontSelectServiceService,
  ) {
    this.data = injectedData?.data;
    this.clicked = injectedData?.clicked;
  }

  ngOnInit() {
    this.open(this.content);
    this.previousColor=this.fontService.getTextColor()!=''? this.fontService.getTextColor():'#000';
    this.form = new FormGroup({
      edit_input: new FormControl(this.data?.value),
    });
    console.log(this.data);
  }
  ngAfterViewInit(): void {

  }
  onColorPickerSelect(event:any){}

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
    
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onCancel();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onCancel();
      return 'by clicking on a backdrop';
    } else {
      // this.onCancel();
      return `with: ${reason}`;
    }
  }

  onDelete(): void {
    // this.dialogRef.close(this.editFormControlName.value);
    this.dialogRef.close(true);
    this.modalRef.dismiss('Save click');
  }

  onCancel(): void {
    this.dialogRef.close();
    this.modalRef.dismiss('Cancel click');
  }

  get editFormControlName(): FormControl {
    return this.form.get('edit_input') as FormControl;
  }
 
  onFontStyleChange(){
  }
  onFontFamilyChange(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectedFontFamily = selectElement.value;
  }
  onFontFamilyChangeAsian(event: Event): void {
    const selectElement = event.target as HTMLSelectElement;
    this.selectAsianTextFont = selectElement.value;
  }
  onFontSizeChange(){
    // You can perform further actions here based on the selected v
  }
  onSelectAlignment(){
  }
  onStrikeThrough(){
    this.underLine=false;
    this.textWrap=false;
    this.superScript=false;
    this.italicText=false;
    this.textBorder=false;
    this.subScript=false;
    this.boldText=false;
  }
  onUnderline(){
    this.strikeThrough=false;
    this.textWrap=false;
    this.superScript=false;
    this.italicText=false;
    this.textBorder=false;
    this.subScript=false;
    this.boldText=false;
  }
  onTextWrap(){
    this.strikeThrough=false;
    this.underLine=false;
    this.superScript=false;
    this.italicText=false;
    this.textBorder=false;
    this.subScript=false;
    this.boldText=false;
  }
  onSuperScript(){
    this.strikeThrough=false;
    this.underLine=false;
    this.textWrap=false;
    this.italicText=false;
    this.textBorder=false;
    this.subScript=false;
    this.boldText=false
  }
  onSubScript(){
    this.strikeThrough=false;
    this.underLine=false;
    this.textWrap=false;
    this.italicText=false;
    this.textBorder=false;
    this.superScript=false;
    this.boldText=false;
  }
  onitalicText(){
    this.strikeThrough=false;
    this.underLine=false;
    this.textWrap=false;
    this.subScript=false;
    this.textBorder=false;
    this.superScript=false;
    this.boldText=false;
  }
  onboldText(){
    this.strikeThrough=false;
    this.underLine=false;
    this.textWrap=false;
    this.subScript=false;
    this.textBorder=false;
    this.superScript=false;
    this.italicText=false;
  }
  ontextBorder(){
    this.strikeThrough=false;
    this.underLine=false;
    this.textWrap=false;
    this.subScript=false;
    this.boldText=false;
    this.superScript=false;
    this.italicText=false;
  }
  onSave(){
    let selected:string='';
    switch (true) {
      case this.strikeThrough:
        selected='strikeThrough';
        this.fontService.setFontStyle(selected);
        break;
      case this.underLine:
        selected='underline';
        this.fontService.setFontStyle(selected);
        break;
      case this.textWrap:
       selected='textWrap';
       this.fontService.setFontStyle(selected);
        break;
      case this.superScript:
        selected='superScript';
        this.fontService.setFontStyle(selected);
        break;
      case this.italicText:
        selected='italicText';
        this.fontService.setFontStyle(selected);
        break;
      case this.textBorder:
        selected='textBorder';
        this.fontService.setFontStyle(selected);
        break;
      case this.subScript:
        selected='subScript';
        this.fontService.setFontStyle(selected);
        break;
      case this.boldText:
        selected='boldText';
        this.fontService.setFontStyle(selected);
        break;
      default:
        break;
    }
    
    this.fontService.setFontFamily(this.selectedFontFamily);
    this.fontService.setAsianTextFont(this.selectAsianTextFont);
    this.fontService.setFontSize(this.selectFontSize);
    this.fontService.setTextAlignment(this.selectedAlignment);
    this.fontService.setTextColor(this.color);
    this.fontService.setBackgroundColor(this.colorBackground);
    this.fontService.setSelectedFontType(this.selectedFontStyle);
    this.modalRef.dismiss();
    this.dialogRef.close();
  }
  fontFamily(){
    console.log(this.selectAsianTextFont+', '+this.selectedFontFamily);
    if(this.selectAsianTextFont!='' && this.selectedFontFamily==''){
      return this.selectAsianTextFont;
    }else if(this.selectAsianTextFont=='' && this.selectedFontFamily!=''){
      return this.selectedFontFamily;
    }else if(this.selectAsianTextFont=='' && this.selectedFontFamily==''){
      return '';
    }else{
      return this.selectAsianTextFont+', '+this.selectedFontFamily;
    }
  }
  onClose(){
    this.modalRef.dismiss();
    this.dialogRef.close();
  }
}
