import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class FontSelectServiceService {
  private fontValue: string = '';
  private fontFamily: string = '';
  private asianFont: string = '';
  private fontSize: string = '';
  private textAlignment: string = '';
  private textColor:string='';
  private backgroundColor:string='';
  private selectedFontStyle:string='';
  private selectedFontType:string='';
  constructor() {}
  setFontStyle(fontValue: string): void {
    this.fontValue = fontValue;
  }
  getFontStyle(): string {
    return this.fontValue;
  }
  setFontFamily(fontFamily: string): void {
    this.fontFamily = fontFamily;
  }
  getFontFamily() {
    return this.fontFamily;
  }
  setAsianTextFont(asianFont: string): void {
    this.asianFont = asianFont;
  }
  getAsianTextFont() {
    return this.asianFont;
  }
  setFontSize(fontSize: string) {
    this.fontSize = fontSize;
  }
  getFontSize() {
    return this.fontSize;
  }
  setTextAlignment(textAlignment:string) {
    this.textAlignment=textAlignment;
  }
  getTextAlignment(){
    return this.textAlignment;
  }
  setTextColor(textColor:string){ 
    this.textColor=textColor;
  }
  getTextColor(){
    return this.textColor;
  }
  setBackgroundColor(backgroundColor:string){
    this.backgroundColor=backgroundColor;
  }
  getBackgroundColor(){
    return this.backgroundColor;
  }
  setSelectedStyles(selectedFontStyle:string){
    this.selectedFontStyle=selectedFontStyle;
  }
  getSelectedStyles(){
    return this.selectedFontStyle;
  }
  setSelectedFontType(selectedFontType:string){
    this.selectedFontType=selectedFontType;
  }
  getSelectedFontType(){
    return this.selectedFontType;
  }
}
