import { State, Action, StateContext, Selector } from '@ngxs/store';
import { tPgService } from '../../core/services/tPg.service';
import { tap } from 'rxjs';
import { Injectable } from '@angular/core';
import { SheetModel } from './sheet.statemodel';
import { Sheet } from './sheet.actions';

@State<SheetModel>({
  name: 'sheet',
  defaults: new SheetModel(),
})
@Injectable()
export class SheetState {
  constructor(private _tPgService: tPgService) {}

  @Selector([SheetState])
  static getSheetData(state: SheetModel) {
    return state.sheetData;
  }
  @Selector([SheetState])
  static getVisitedData(state: SheetModel) {
    return state.visitedPages;
  }
  @Selector()
  static getSheetColumns(state: SheetModel) {
    return state.sheetColumns;
  }

  @Selector([SheetState])
  static PickDdiData(state: SheetModel) {
    return state.PickDdiData;
  }

  @Selector([SheetState])
  static PickDdiColumns(state: SheetModel) {
    return state.PickDdiColumns;
  }

  @Selector([SheetState])
  static regions(state: SheetModel) {
    return state.regions;
  }

  @Selector([SheetState])
  static addRow(state: SheetModel) {
    return state.addRow;
  }
  @Selector([SheetState])
  static filterDDSPageData(state: SheetModel) {
    return state.filteredSheetData;
  }
  @Selector([SheetState])
  static formatLocalCol(state: SheetModel) {
    return state.formatLocalCol;
  }
  @Selector([SheetState])
  static deleteItemData(state: SheetModel) {
    return state.deleteItemData;
  }

  @Selector([SheetState])
  static getFrozen(state: SheetModel) {
    return state.frozen;
  }
  @Selector([SheetState])
  static getExpandWidth(state: SheetModel) {
    return state.expandWidth;
  }
  private cachedSheetData: { [key: string]: any } = {};

  @Action(Sheet.FetchSheetData)
  fetchSheetData(ctx: StateContext<SheetModel>, action: Sheet.FetchSheetData) {
    const { sheetId } = action;
    // Check if the data for this sheetId is already cached
    if (this.cachedSheetData[sheetId]) {
      // If cached data exists, patch the state with it
      ctx.patchState({
        sheetData: this.cachedSheetData[sheetId].pageData,
        sheetColumns: this.cachedSheetData[sheetId].pageColumns,
        sheetPageId: sheetId,
      });
      return; // Exit without making an API call
    }

    // If no cached data, call the API
    return this._tPgService.getPages(sheetId).pipe(
      tap((data: any) => {
        // Cache the fetched data
        if (data.data.pageData[0].language == 'English') {
          [data.data.pageData[0], data.data.pageData[1]] = [
            data.data.pageData[1],
            data.data.pageData[0],
          ];
        }
        this.cachedSheetData[sheetId] = {
          pageData: data.data.pageData,
          pageColumns: data.data.pageColumns,
        };

        // Patch the state with the fetched data
        ctx.patchState({
          sheetData: data.data.pageData,
          sheetColumns: data.data.pageColumns,
          sheetPageId: sheetId,
        });
      })
    );
  }
  @Action(Sheet.PickDdiData)
  PickDdiData(ctx: StateContext<SheetModel>, action: Sheet.PickDdiData) {
    return this._tPgService.getPages(action.sheetId).pipe(
      tap((data: any) => {
        ctx.patchState({
          PickDdiData: data.data.pageData,
          PickDdiColumns: data.data.pageColumns,
        });
      })
    );
  }

  @Action(Sheet.UpdatePickDdiColumns)
  UpdatePickDdiColumn(
    ctx: StateContext<SheetModel>,
    action: Sheet.UpdatePickDdiColumns
  ) {
    ctx.patchState({
      PickDdiColumns: action.payload,
    });
  }

  @Action(Sheet.PageButtonPopupData)
  PageButtonPopupData(
    ctx: StateContext<SheetModel>,
    action: Sheet.PageButtonPopupData
  ) {
    return this._tPgService.getPages(action.payload).pipe(
      tap((data: any) => {
        if (!data || !data.data) {
          console.error('Invalid data received from API:', data);
          return;
        }
        // Filter columns to include 'DDS-Col' or 'Share' but exclude 'Hidden'
        const ddsFields = data.data.pageColumns
          .filter((column: { status: string | string[] }) => {
            return (
              column.status &&
              column.status.includes('DDS-Col') &&
              !column.status.includes('Hidden')
            );
          })
          .map((column: { field: any }) => column.field);
        // Filter the page data based on the presence of required fields
        const filteredData = data.data.pageData
          .map((page: any) => {
            let filteredPage: any = {};

            ddsFields.forEach((field: string) => {
              if (page.hasOwnProperty(field)) {
                filteredPage[field] = page[field];
              }
            });

            return filteredPage;
          })
          .filter((page: any) => Object.keys(page).length > 0); // Remove empty pages here

        ctx.patchState({
          filteredSheetData: filteredData,
        });
      })
    );
  }

  @Action(Sheet.updateColumns)
  UpdateColumn(ctx: StateContext<SheetModel>, action: Sheet.updateColumns) {
    ctx.patchState({
      sheetColumns: action.payload,
    });
  }

  @Action(Sheet.OrderColumns)
  OrderColumns(ctx: StateContext<SheetModel>, action: Sheet.OrderColumns) {
    return this._tPgService.orderColumns(action.pageId, action.payload).pipe(
      tap((data: any) => {
        const state = ctx.getState();
      })
    );
  }

  @Action(Sheet.getRegions)
  getRegions(ctx: StateContext<SheetModel>, action: Sheet.getRegions) {
    return this._tPgService.getRegions().pipe(
      tap((data: any) => {
        ctx.patchState({
          regions: data.data.regions,
        });
      })
    );
  }

  @Action(Sheet.AddRow)
  AddRow(ctx: StateContext<SheetModel>, action: Sheet.AddRow) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addRow(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.addColumn)
  addColumn(ctx: StateContext<SheetModel>, action: Sheet.addColumn) {
    ctx.patchState({
      addRow: [], // Set to an empty array to match the expected type
    });
    return this._tPgService.addColumn(action.payload).pipe(
      tap((resp: any) => {
        ctx.patchState({
          addRow: resp,
        });
      })
    );
  }

  @Action(Sheet.DeleteRow)
  DeleteRow(ctx: StateContext<SheetModel>, action: Sheet.DeleteRow) {
    return this._tPgService.deleteRow(action.payload).pipe();
  }

  @Action(Sheet.addUrl)
  addUrl(ctx: StateContext<SheetModel>, action: Sheet.addUrl) {}

  @Action(Sheet.DeleteColumn)
  DeleteColumn(ctx: StateContext<SheetModel>, action: Sheet.DeleteColumn) {
    const state = ctx.getState();

    return this._tPgService.deleteColumn(action.payload).pipe();
  }

  @Action(Sheet.DeletePage)
  DeletePage(ctx: StateContext<SheetModel>, action: Sheet.DeletePage) {
    const state = ctx.getState();

    // Getting page id from state
    const pageId: number | undefined = state.sheetPageId;

    // Ensure the `page` is found and `pageId` is accessible
    if (!pageId) {
      throw new Error('page_id is not accessible in the state');
    }

    return this._tPgService.deletePage(pageId).pipe();
  }
  @Action(Sheet.FormatLocalColData)
  formatLocalCol(
    ctx: StateContext<SheetModel>,
    action: Sheet.FormatLocalColData
  ) {
    return this._tPgService.formatLocalCol(action.payload, action.page_id).pipe(
      tap((data: any) => {
        ctx.patchState({
          formatLocalCol: data,
        });
      })
    );
  }
  @Action(Sheet.DeleteItemData)
  deleteItemData(ctx: StateContext<SheetModel>, action: Sheet.DeleteItemData) {
    return this._tPgService.deleteItemData(action.payload, action.page_id).pipe(
      tap((resp: any) => {
        ctx.patchState({
          deleteItemData: resp,
        });
      })
    );
  }
  @Action(Sheet.SetFrozen)
  SetFrozen(ctx: StateContext<SheetModel>, action: Sheet.SetFrozen) {
    ctx.patchState({
      frozen: action.payload,
    });
  }
  @Action(Sheet.SetWidthExpand)
  SetWidthExpand(ctx: StateContext<SheetModel>, action: Sheet.SetWidthExpand) {
    ctx.patchState({
      expandWidth: action.payload,
    });
  }
}
