export const AllPages_columns:any = [
  {
    title: 'Row*',
    field: 'row',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'PageID',
  },
  {
    title: 'Page ID*',
    field: 'page_id',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#≤1','System'],
    colDataType:'PageID',
  },
  {
    title: 'Page Name*',
    field: 'page_name',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1', 'Unique', 'Nested'],
    colDataType:'MLText',
  },
  {
    title: 'Page Type',
    field: 'page_type',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'DropDown',
  },
  {
    title: 'Page Edition*',
    field: 'page_edition',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'DropDown',
  },
  {
    title: 'Page Owner*',
    field: 'page_owner',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'UserID',
  },
  {
    title: 'Page URL',
    field: 'page_url',
    width: 'auto',
    editor: false,
    status:['Item#=1','Unique', 'System'],
    colDataType:'URL',
  },
  {
    title: 'Page Seo',
    field: 'page_seo',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'MLText',
  },
  {
    title: 'Page Status',
    field: 'page_status',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'DropDown',
  },
  {
    title: 'Page Comment',
    field: 'page_comment',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'MLText',
  },
  {
    title: 'Row Type',
    field: 'row_type',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'DropDown',
  },
  {
    title: 'Row Status',
    field: 'row_status',
    width: 'auto',
    editor: false,
    tooltip: (cell:any) => cell.getValue(),
    status:['Item#=1','Unique', 'System'],
    colDataType:'DropDown',
  },
];