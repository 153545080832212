import {
  Component,
  ElementRef,
  ViewChild,
  OnInit,
  OnDestroy,
  AfterViewInit,
  Inject,
  ChangeDetectorRef,
  HostListener,
} from '@angular/core';

import { TabulatorFull as Tabulator } from 'tabulator-tables';
import { Sheet } from '../../store/sheet/sheet.actions';
import { NgxsOnChanges, Select, Store } from '@ngxs/store';
import { SheetState } from '../../store/sheet/sheet.store';
import {
  debounceTime,
  distinctUntilChanged,
  expand,
  Observable,
  Subject,
  Subscription,
  takeUntil,
} from 'rxjs';
import { MenuBarService } from '../../services/menu-bar-service/manu-bar.service';
import {
  MAT_DIALOG_DATA,
  MatDialog,
  MatDialogRef,
} from '@angular/material/dialog';
import { PickddiPGColDialogComponent } from './pickddi-pgcol-dialog/pickddi-pgcol-dialog.component';
import { COL_STATUSES, PAGE_IDS } from '../../constant';
import { AutoAdjustWidthDirective } from '../../shared/directives/auto-adjust-width.directive';
import {
  UNICODE_LEFT_RIGHT_ARROW,
  UNICODE_GLOBE,
  UNICODE_LANGUAGE,
  UNICPODE_RELOAD,
  UNICODE_MULTIPLY,
  UNICODE_DASH,
  UNICODE_PLUS_CODE,
  UNICODE_UP_ARROW,
  UNICODE_DOWN_ARROW,
  ASCII_DASH,
  ASCII_PLUS,
  ASCII_SPACE,
} from '../../shared/unicode/unicode';
import { AllRegions_Data } from '../../core/storage/AllRegions_Data.storage';

@Component({
  selector: 'app-pick-ddi-dialogue',
  templateUrl: './pick-ddi-dialogue.component.html',
  styleUrls: ['./pick-ddi-dialogue.component.css'],
})
export class PickDdiDialogueComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  private tabulatorTable!: Tabulator;
  @ViewChild('tabulatorDiv2', { static: true }) tabulatorDiv2!:
    | ElementRef
    | undefined;
  private unsubscribe$ = new Subject<void>();
  allData: any = ([] = []);
  clickedRowData: any;
  parentRows: any[] = [];
  allDataWithoutNested: any = ([] = []);
  allColumns: any = ([] = []);
  freezLevel: any = -1;
  pageFreezeColumn: any = 0;
  frozen: any[] = [];
  ExpandLevels: any = { n: '99.', x: null, y: null, z: null };
  public sortStatus = false;
  public sorted = false;
  private isFilter: boolean = false;
  firstIncrement: boolean = true;
  rowLevel: any = 0;
  private expandChangeSubject = new Subject<string>();
  private sub: Subscription | null = null;
  @Select(SheetState.PickDdiData) data$: Observable<any> | undefined;
  @Select(SheetState.PickDdiColumns) columns$: Observable<any> | undefined;
  @Select(SheetState.getFrozen) frozen$: Observable<any> | undefined;
  @Select(SheetState.getExpandWidth) expandWidth$: Observable<any> | undefined;
  expandLevels: boolean[] = [];
  selectedColumns: any[] = [];
  columnOrder: any[] = [];
  triggered: boolean = false;
  private originalData: any[] = [];
  inputWidth = '28px'; // Initial width
  refresh = UNICPODE_RELOAD;
  language = UNICODE_LANGUAGE;
  globe = UNICODE_GLOBE;
  expand = UNICODE_LEFT_RIGHT_ARROW;
  closeTab = UNICODE_MULTIPLY;
  @ViewChild(AutoAdjustWidthDirective)
  widthDirective!: AutoAdjustWidthDirective;
  isDragging: boolean = false;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  initialPopupX: number = 0; // Initial X position of the popup
  initialPopupY: number = 0; // Initial Y position of the popup
  height: any = 0;
  width: any = 0;
  previousFontSize: number;
  currentFontSize: number;
  newFontSize: number = 0;
  offsetX = 0;
  offsetY = 0;
  scrollbarWidth = this.getScrollbarWidth(); // Getting the side scrollbar width
  levels:any;
  isVisible:any;
  inputValue: string = '';
  newWidth : number = 0;
  newHeight : number = 0;
  level:any;
  columnsWithHeaderContext: any = [];
  updatedRow:any;
  constructor(
    private store: Store,
    private cdr: ChangeDetectorRef,
    private menuBarService: MenuBarService,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private el: ElementRef,
    public dialogRef: MatDialogRef<PickDdiDialogueComponent>
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }

  ngOnInit() {
    this.getData();
    this.renderTabulator();
    this.freezeLevel();
    this.expandLevel();
    this.store.dispatch(new Sheet.SetFrozen(this.data?.data?.frozen));
    this.store.dispatch(new Sheet.SetWidthExpand(this.data?.data?.expandWidth));
    if (this.data?.data?.frozen != undefined) {
      this.ExpandLevels.n = this.data?.data?.frozen;
    }
    this.expandChangeSubject
      .pipe(
        debounceTime(300),
        distinctUntilChanged() // Prevent processing the same value multiple times
      )
      .subscribe((value) => {
        this.processExpandChange(value);
      });
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  changeFontSize(newSize: number): void {
    // Update previous font size before changing it
    this.previousFontSize = this.currentFontSize;

    // Change the current font size
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }
  onInputChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    const newValue = input?.value || '';
    this.expandChangeSubject.next(newValue);
  }
  @ViewChild('parentScroller') parentScroller!: ElementRef;
  ngAfterViewInit() {
    this.freezeLevel();
    this.expandLevel();
    setTimeout(()=>{
      if (this.parentScroller) {
        this.parentScroller.nativeElement.scrollLeft = 0;
      }  
    },200)
  }
  // Function to calculate the side scrollbar width
  getScrollbarWidth(): number {
    return window.innerWidth - document.documentElement.clientWidth;
  }
  getData() {
    // Reset state
    this.store.reset(new Sheet.PickDdiData(PAGE_IDS.ALL_REGIONS));

    // Dispatch new state
    this.store.dispatch(new Sheet.PickDdiData(PAGE_IDS.ALL_REGIONS));
    this.data$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data != undefined) {
        this.originalData = data; // Store the original data for reference
        this.allDataWithoutNested =data;
        this.allData = this.buildNestedDataIterative(data);
      }
    });
   
  }

  hasChildrenInAnyRow = false;
  buildNestedDataIterative(inputData: any) {
    this.hasChildrenInAnyRow = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
      const newItem = {
        ...item,
      };
      if (item?.ParentRow?.Row === null || item?.ParentRow === null) {
        structuredData.push(newItem);
      } else {
        if (item?.ParentRow?.Row !== null) {
          this.hasChildrenInAnyRow = true;
        }
        const parentRow = map[item?.ParentRow?.Row];
        if (parentRow) {
          if (!parentRow._children) {
            parentRow._children = []; // Initialize only if it doesn't exist
          }
          parentRow._children.push(newItem);
        }
      }

      map[item?.row] = newItem;
    });

    return structuredData;
  }

  dataSelection() {
    let selectedColumns;
    selectedColumns = this.allData;
    return selectedColumns;
  }

  underlineFormatter = (cell: { getValue: () => any }) => {
    const value = cell.getValue();
    if (value) {
      return `<span style="text-decoration: underline;">${value}</span>`;
    } else {
      return value;
    }
  };
  onClose(): void {
    this.dialogRef.close();
  }
  boldSectionHead = (cell: { getValue: () => any; getData: () => any }) => {
    const value = cell.getValue();
    const data = cell.getData();
    if (data.RowLevel == 0) {
      return `<span style="font-weight:bold;">${value}</span>`;
    }
    return value;
  };

  // Check if the column contains any semicolon
  checkColumnForSemicolons(column: any): boolean {
    return this.checkColStatus(column.status);
  }

  generateColumnCode(columnSelection: any = []) {
    if (columnSelection.length != 0) {
      const columns = columnSelection.map((column: any, index: number) => {
        const hasSemicolon = this.checkColumnForSemicolons(column); // Check if the column has semicolons
        const formatter = (
          cell: any,
          formatterParams: any,
          onRendered: any
        ) => {
          const value = cell.getValue();

          // If the column has a semicolon, format all items in that column with chips
          if (hasSemicolon) {
            return this.chipFormater()(cell, formatterParams, onRendered);
          } else {
            return value; // Return the original value if no semicolon
          }
        };

        if (index < +this.freezLevel && this.pageFreezeColumn != 0) {
          if (column.visible === true) {
            column.frozen = true;
          }
        }

        if (column.status.includes('Nested')) {
          return { ...column, formatter: this.boldSectionHead };
        }

        // Use underline formatter for "Page URL" column
        if (column.field === 'page_url' && column.field !== 'undefined') {
          return { ...column, formatter: this.underlineFormatter };
        } else {
          return { ...column, formatter }; // Apply the dynamic formatter to other columns
        }
      });

      return columns;
    }
  }
  private rowVisibilityFormatter(row: any) {
    this.menuBarService.Expand_Visibilt_Reg.subscribe((res) => {
      this.levels = res;
      const data = row.getData();
      const rowElement = row.getElement();

      if (rowElement) {
        // Ensure the row element exists
        if (
          data.row_status === 'Hidden' &&
          (res.x == null || res.x == undefined)
        ) {
          rowElement.style.display = 'none'; // Hide the row
        } else if(res.y==null && res.z==null){
          rowElement.style.display = ''; // Show the row
        }
      } else {
      }
    });

  }
  private cellVisibilityFormatter(cell: any) {
    if(cell.getColumn().getDefinition().visible==true){
    const rowData = cell.getRow().getData();
    const field = cell.getField();
   
    this.menuBarService.Expand_Visibilt_Reg.subscribe((res) => {
      if(res.x==1 || res.x==null){
        const cellElement = cell.getElement();
        if (cellElement) {
            if (
                rowData.Cell_visibility && 
                rowData.Cell_visibility[field] === false && 
                (res.y == null || res.y == undefined)
            ) {
                cell.getElement().style.display = 'none'; // Hide the cell
            } else {
                cell.getElement().style.display = '';
            }
        }
      }
    });
  }
    // Return the value immediately (synchronously)
}
private ItemVisibilityFormatter(cell: any) {
  const rowData = cell.getRow().getData();
  const field = cell.getField();
  const originalValueKey = `_original_${field}`;

  // Cache the original value if not already cached
  if (!rowData[originalValueKey]) {
    rowData[originalValueKey] = cell.getValue();
  }

  this.menuBarService.Expand_Visibilt_Reg?.subscribe((res) => {
    const cellElement = cell.getElement();
    if (cellElement) {
      if (
        rowData.Item_Status &&
        rowData.Item_Status[field] === false &&
        (res.z == null || res.z == undefined)
      ) {
        rowData.Item_Status.items.map((items:any)=>{
          if(items.visibility!='Hidden'){
            rowData.row_type=rowData.row_type;
          }else{
            rowData.row_type=items.item_name;
          }
        })
      } else {
        cell.setValue(rowData[originalValueKey]); // Restore original value
        
      }
    } else {
    }
  });
}

  renderTabulator(): void {
    if (this.tabulatorTable) {
      this.tabulatorTable.destroy(); // Destroy previous table instance
    }

    this.columns$?.subscribe((columns) => {
      if (columns != undefined) {
        this.selectedColumns =
          this.columnOrder.length != 0 ? this.columnOrder : columns;
        this.allColumns = columns.map((col: any) => ({ ...col }));

        this.columnsWithHeaderContext = this.selectedColumns?.map((column: any) => {
          this.isVisible = column?.status?.includes(COL_STATUSES.DDS_COL);
          return {
            ...column,
            headerFilterLiveFilter: false,
            visible: this.isVisible,
            headerFilter: this.isFilter ? this.customFilterEditor : false,
            headerSortTristate: true,
            headerFilterFunc: this.customFilterFunction,
            headerClick: (e: any, column: any) => {
              this.headerClickFunc(e, column); // Attach header click function
            },
          };
        });

       const nestedColumn = this.columnsWithHeaderContext.find(
          (column: { status?: string | string[] }) =>
            column.status?.includes('Nested')
        )?.field;

        // Initialize the Tabulator table
        this.tabulatorTable = new Tabulator(this.tabulatorDiv2?.nativeElement, {
          data: this.dataSelection(),
          columns: this.generateColumnCode(this.columnsWithHeaderContext),
          dataTree: true,
          dataTreeFilter: true,
          dataTreeStartExpanded: this.expandLevel(),
          dataTreeElementColumn: nestedColumn,
          addRowPos: 'bottom',
          validationMode: 'highlight',
          dataTreeCollapseElement:
            '<div class="collapse-sign"></div>',
          dataTreeExpandElement:
            '<div class="collapse-sign"></div>',
          headerSortElement: function (column, dir) {
            switch (dir) {
              case 'asc':
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
              case 'desc':
                return (
                  '<div class="sorter">' + `${UNICODE_DOWN_ARROW}` + '</div>'
                );
              default:
                return (
                  '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>'
                );
            }
          },
          columnDefaults: {
            resizable: true, // Ensure columns are resizable,
          },
          progressiveLoad: 'scroll',
          layout: 'fitDataFill',
          layoutColumnsOnNewData: true,
          movableColumns: true,
          spreadsheet: true,
          spreadsheetRows: 250,
          movableRows: false,
          columnHeaderSortMulti: true, // Use sortStatus
          headerSortClickElement: 'icon', // Enable or disable sorting on icon
          rowFormatter: (row) => {
              this.updatedRow=row;
              this.formatRow(row, nestedColumn);
              row.getCells().forEach((cell) => {
              const value = cell.getValue();
              // Handle tooltip for cells with chips or values
              if (value && typeof value === 'string' && value.includes(';')) {
                cell.getElement().removeAttribute('title');
              } else {
                cell.getElement().setAttribute('title', value);
              }
            });
          },
        });

        this.tabulatorTable.on('rowClick', (e, row) => {
          const element = e.target as HTMLElement;
          if (element.id === 'line-id') {
              // Toggle expansion state
              const isExpanded = row.isTreeExpanded();
              isExpanded ? row.treeCollapse() : row.treeExpand();
          }else{
              this.handleRowClick(row); // Handle row clicks
          }
        });

        this.tabulatorTable.on('columnMoved', (column: any, columns: any) => {
          const allColumns = this.tabulatorTable.getColumns();
          allColumns.map((column, index) => {
            this.columnOrder[index] = column.getDefinition();
          });
          this.triggered = true;
        });
      }
      
      // Apply filters after rendering
      setTimeout(() => {
        if (this.isFilter) {
          this.updateTableWithFilters(true);
        } else {
          this.updateTableWithFilters(false);
        }
      }, 300);
    });
  }

  headerClickFunc = (e: any, column: any) => {
    var tabulator = column.getTable();
    this.sortStatus ? '' : tabulator.clearSort();
  };

  toggleSort() {
    this.sortStatus = !this.sortStatus; // Toggle the sort status

    if (!this.sortStatus) {
      this.tabulatorTable.clearSort(); // Clear sort if sorting is disabled
    }
  }

  openColDialog() {
    const dialogRef = this.dialog.open(PickddiPGColDialogComponent, {
      width: '750px',
      data: {},
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed');
    });
  }

  toggleFreeze(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      this.pageFreezeColumn += 1;
      let obj1 = { pageFreezeColumn: this.pageFreezeColumn };
      this.menuBarService.pageFormateFreeze.next(obj1);
    } else if (
      y > rect.height / 2 + centralAreaHeight / 2 &&
      this.pageFreezeColumn > 0
    ) {
      this.pageFreezeColumn = Math.max(0, this.pageFreezeColumn - 1);
      let obj2 = { pageFreezeColumn: this.pageFreezeColumn };
      this.menuBarService.pageFormateFreeze.next(obj2);
      this.tabulatorTable.getColumns()?.map((column) => {
        if (column.getDefinition().frozen == true) {
          column.updateDefinition({
            frozen: false,
            title: column.getDefinition().title,
          });
          this.selectedColumns = this.columnOrder;
        }
      });
    }
  }

  chipFormater() {
    return (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // Create a container for chips
      const chipContainer = document.createElement('div');
      chipContainer.classList.add('button-container');

      // Split by semicolon or treat as single item
      const items =
        value && typeof value === 'string'
          ? value.includes(';')
            ? value.split(';').map((item) => item.trim())
            : [value.trim()]
          : [];

      // Create chips for each item
      items.forEach((item) => {
        // Create the outer div for each item
        const outerDiv = document.createElement('div');
        outerDiv.className = 'menu-item-container'; // Set the class name

        // Create the Button div
        const chipDiv = document.createElement('div');
        chipDiv.className = 'Chip';
        chipDiv.title = item; // Set the chip tooltip
        chipDiv.textContent = item;

        // Append the Button div to the outer div
        outerDiv.appendChild(chipDiv);

        // Append the outer div to the chip container
        chipContainer.appendChild(outerDiv);
      });

      // Remove the cell tooltip by removing the title attribute
      cell.getElement().removeAttribute('title');

      return chipContainer.outerHTML;
    };
  }


  expandLevel(): boolean[] {
    if (!this.sub) {
      this.sub = this.menuBarService.pageFormateReg.subscribe((res) => {
        if (res.pageExpand) {
          this.expandLevels = Array(res.pageExpand.n).fill(true);
          this.renderTabulator();
        }
      });
    }
    return this.expandLevels;
  }

  freezeLevel() {
    this.freezLevel = 0;
    this.menuBarService.pageFormateFreeze.subscribe((res) => {
      if (res.pageFreezeColumn) {
        this.freezLevel = res.pageFreezeColumn;
        // this.renderTabulator();
        this.tabulatorTable.setColumns(this.generateColumnCode(this.columnsWithHeaderContext));
      }
    });
  }

  handleRowClick(row: any) {
    this.clickedRowData = row.getData();

    if (this.isFilter) {
      // Use the original data to find the parent rows
      this.parentRows = this.findParentRowsInOriginalData(this.clickedRowData);
    } else {
      // Collect parent rows normally
      this.parentRows = this.collectParentRows(row);
    }
    this.parentRows.reverse();
    this.parentRows.push(this.clickedRowData);
    this.closeDialog({ parentRows: this.parentRows });
  }

  findParentRowsInOriginalData(childRowData: any): any[] {
    const parentRows: any[] = [];
    const findParent = (row: any) => {
      const parentId = row.ParentRow?.Row; // Assuming you have a field that points to the parent
      if (parentId) {
        const parentRow = this.originalData.find((r) => r.row === parentId);
        if (parentRow) {
          parentRows.push(parentRow);
          findParent(parentRow); // Recursively find parent
        }
      }
    };
    findParent(childRowData);
    return parentRows;
  }

  collectParentRows(row: any): any[] {
    let parentRows: any[] = [];
    let currentRow = row.getTreeParent(); // Get the parent row

    while (currentRow) {
      parentRows.push(currentRow.getData());
      currentRow = currentRow.getTreeParent(); // Move to the next parent
    }

    return parentRows; // Return the collected parent rows
  }

  closeDialog(data: any) {
    this.dialogRef.close(data);
  }

  formatRow(row: any, nestedColumn: string): void {
    const depth = row.getData().RowLevel; // Get the RowLevel
    const hasChildren = row.getTreeChildren().length > 0;
    if (!this.hasChildrenInAnyRow) {
      row
        .getCells()
        .forEach((cell: { getElement: () => any; getField: () => string }) => {
          const cellElement = cell.getElement();
        });
    }
    row
      .getCells()
      .forEach((cell: { getElement: () => any; getField: () => string }) => {
        const field = cell.getField();

        if (field === nestedColumn) {
          const cellElement = cell.getElement();
          Array.from(cellElement.querySelectorAll('.line')).forEach((line) => {
            (line as HTMLElement).remove();
          });
          const parentDiv = document.createElement('div');
          parentDiv.classList.add('RowHeaderDiv');
          
          if (depth > 0) {
            for (let i = 0; i < depth; i++) {
              const lineDiv = document.createElement('div');
              if(hasChildren && i==depth-1){
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML=row.isTreeExpanded()?ASCII_DASH:ASCII_PLUS;
                lineDiv.id='line-id';
              }else{
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML=ASCII_SPACE;
              }
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
            }
          }
          if(depth==0){
            const lineDiv = document.createElement('div');
              lineDiv.classList.add('SectionRowHeader');
              lineDiv.innerHTML=row.isTreeExpanded()?ASCII_DASH:ASCII_PLUS;
              lineDiv.id='line-id';
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
          }
        } else {
          const cellElement = cell.getElement();
          }
      });
  }

  onExpandChange(value: string) {
    // Emit the new value to the subject for debouncing
    this.expandChangeSubject.next(value);
  }

  private processExpandChange(value: string) {
    // Handle adjustments and dispatch actions here
    setTimeout(() => {
      this.widthDirective.adjustWidth();
    }, 0);
    this.store.dispatch(new Sheet.SetFrozen(value));
    if (value.length <= 7) {
      this.level = this.parseExpandLevel(value);
      if(Number.isNaN(this.level.n)){
        this.menuBarService?.Expand_Visibilt_Reg?.next({ n: 0, x: null, y: null, z: null });
        this.menuBarService?.pageFormateReg?.next({ pageExpand: { n: 0, x: null, y: null, z: null } });
      }else{
        if(this.level.n.toString().length<=2){
          this.menuBarService?.Expand_Visibilt_Reg?.next(this.level);
          this.menuBarService?.pageFormateReg?.next({ pageExpand: this.level });
        }
      }
    }
  }
  get expandLevelN(): any {
      return this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '';
  }

  set expandLevelN(value: string) {
    this.ExpandLevels.n = value !== '' ? value : '';
    this.onInputChange(this.ExpandLevels.n);
  }

  handleKeydown(event: KeyboardEvent): boolean {
    const inputElement = event.target as HTMLInputElement;
    const key = event.key;
  
    // Allow numbers, backspace, dot, and arrow keys
    if (
      (key >= '0' && key <= '9') || 
      key === 'Backspace' || 
      key === '.' || 
      key === 'ArrowLeft' || 
      key === 'ArrowRight' || 
      key === 'ArrowUp' || 
      key === 'ArrowDown'
    ) {
      return true;
    }
    event.preventDefault();
    return false;
  }
  
  
  
  

  onExpandToggle(event: MouseEvent): void {
    const target = event.currentTarget as HTMLElement;
    const rect = target.getBoundingClientRect();
    const y = event.clientY - rect.top;
    let nValue =
      this.ExpandLevels.n !== null ? this.ExpandLevels.n.toString() : '0';
    if (!nValue || isNaN(parseFloat(nValue))) {
      nValue = '0';
      this.ExpandLevels.n = 0;
      return;
    } else if (nValue.startsWith('.')) {
      nValue = '0' + nValue;
    }
    const [integerPart, decimalPart] = nValue.split('.');
    let intValue = parseInt(integerPart, 10);
    const centralAreaHeight = rect.height * 0.2;
    if (y < rect.height / 2 - centralAreaHeight / 2) {
      if (this.firstIncrement) {
        this.firstIncrement = false;
      }
      intValue += 1;
    } else if (y > rect.height / 2 + centralAreaHeight / 2 && intValue > 0) {
      intValue = Math.max(0, intValue - 1);
    }
    this.ExpandLevels.n = decimalPart
      ? `${intValue}.${decimalPart}`
      : `${intValue}`;
    this.onExpandChange(this.ExpandLevels.n); // Send updated value
  }

  parseExpandLevel(level: string): {
    n: number;
    x: number | null;
    y: number | null;
    z: number | null;
  }  {
    let nValue = this.ExpandLevels.n
      ? parseInt(this.ExpandLevels.n.toString().split('.')[0])
      : 0;

    const match = level.match(
      /(\d+)(?:\.(1))?(?:(2))?(?:(3))?(?:\.(12|13|23|123))?/
    );
    if (!match) return { n: nValue, x: null, y: null, z: null }; // Default return if no match

    nValue = parseInt(match[1]); // Captures 'n'
    let xValue = match[2] ? 1 : null; // x is only 1 if matched
    let yValue = match[3] ? 2 : null; // y is only 2 if matched
    let zValue = match[4] ? 3 : null; // z is only 3 if matched

    // Handle combined cases for y and z
    if (match[5]) {
      if (match[5].includes('1')) xValue = 1; // If part of "12", "13", or "123"
      if (match[5].includes('2')) yValue = 2; // If part of "12", "23", or "123"
      if (match[5].includes('3')) zValue = 3; // If part of "13", "23", or "123"
    }
    if (level.includes('.12') || level.includes('.2')) {
      xValue = 1;
      yValue = 2;
      zValue=null;
    }

    if(level.includes('.123')){
      xValue = 1;
      yValue = 2;
      zValue = 3;
    }
    if(level.includes('.13')){
      xValue=1;
      yValue=null;
      zValue=3;
    }
    if(level.includes('.23')){
      xValue=null;
      yValue=2;
      zValue=3;
    }
    if(level.includes('.3')){
      xValue=null;
      yValue=null;
      zValue=3;
    }

    return {
      n: nValue,
      x: xValue,
      y: yValue,
      z: zValue,
    };
  }

  toggleFilter() {
    this.isFilter = !this.isFilter;
    if (this.isFilter) {
      this.updateTableWithFilters(true);
    } else if (!this.isFilter) {
      this.tabulatorTable.clearHeaderFilter();
      this.updateTableWithFilters(false);
    }
  }

  clearFilters() {
    this.parentRows.pop();
    this.parentRows.push(null);
    this.clickedRowData = null;
    this.sortStatus = false;
    this.sorted = false;
    this.expandLevelN = '99';
    this.onExpandChange('99');
    this.dialogRef.close({ parentRows: null, clicked: true });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  updateTableWithFilters(enable: any) {
    // Replace tabulator data without nested data
    enable
      ? this.tabulatorTable?.replaceData(this.allDataWithoutNested)
      : this.tabulatorTable?.replaceData(this.allData);

    var headers = document.querySelectorAll(
      '.tabulator2.tabulator .tabulator-header'
    );
    let columns = this.tabulatorTable
      ?.getColumnDefinitions()
      .map((colDef: any) => {
        colDef.headerFilter = enable ? this.customFilterEditor : false;
        return colDef;
      });

    this.tabulatorTable?.setColumns(columns);
  }

  /**
   * Custom filter editor for Tabulator's column headers.
   * This function creates an input field inside the header for custom filtering,
   * with real-time validation, error handling, and event-based triggers for filter application.
   *
   * @param {Object} cell - The Tabulator cell object where the editor is being applied.
   * @param {Function} onRendered - Callback that is called once the editor is rendered.
   * @param {Function} success - Callback to pass the input data back to Tabulator once the filter is applied.
   * @param {Function} cancel - Callback to cancel the editor input (triggered on 'Escape').
   * @param {Object} editorParams - Additional editor parameters passed by Tabulator's configuration.
   * @returns {HTMLElement} - A container element with the input field and an optional error message display.
   */
  customFilterEditor = (
    cell: any,
    onRendered: any,
    success: any,
    cancel: any,
    editorParams: any
  ): HTMLElement => {
    // Create a container to hold the input and error message
    const container = document.createElement('span') as HTMLElement;

    // Create and style the input element for user input
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.padding = '2px';
    input.style.width = '100%';
    input.value = cell.getValue();

    // Create and style the error message container (initially hidden)
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'red';
    errorMessage.style.fontSize = '12px';
    errorMessage.style.display = 'none';
    errorMessage.style.marginTop = '2px';
    errorMessage.textContent = 'Invalid syntax';
    errorMessage.style.fontWeight = '200';

    // Validate the input value and adjust the UI accordingly
    const validateInputValue = () => {
      const isValid = this.validateInput(input.value);
      const tableHeader = document.querySelector(
        '.tabulator2.tabulator .tabulator-header[role="rowgroup"]'
      ) as HTMLDivElement;
      const tabulator = document.querySelector(
        '.tabulator2.tabulator[role="grid"]'
      ) as HTMLDivElement;
      const tabulatorTable = document.querySelector(
        '.tabulator2.tabulator[tabulator-layout=fitDataFill] .tabulator-tableholder .tabulator-table'
      ) as HTMLDivElement;
      const tabulatorPlaceholder = document.querySelector(
        '.tabulator2.tabulator .tabulator-tableholder'
      ) as HTMLDivElement;

      if (!isValid) {
        // If input is invalid, style the input in red and display the error message
        input.style.color = 'red';
        errorMessage.style.display = 'block';
      } else {
        // If input is valid, revert the styles back to normal
        input.style.color = 'black';
        errorMessage.style.display = 'none';
      }
    };

    // Function to build input values and pass them back
    const buildValues = () => {
      success({
        inputColumnStatus: cell.getColumn().getDefinition().status,
        inputColumn: cell.getColumn().getField(),
        inputValue: input.value,
      });
    };

    // Debounce buildValues and validateInput functions
    const debounceBuildValues = this.debounceInput(buildValues, 300);
    const debounceValidateInput = this.debounceInput(validateInputValue, 300);

    // Input event listener (debounced)
    input.addEventListener('input', () => {
      debounceBuildValues();
      debounceValidateInput();
    });

    // Keypress listener for Enter and Escape
    input.addEventListener('keydown', (e: KeyboardEvent) => {
      if (e.key === 'Enter') buildValues();
      if (e.key === 'Escape') cancel();
    });

    // Finalize filter on blur
    input.addEventListener('blur', buildValues);

    // Append the input and error message to the container
    container.appendChild(input);
    container.appendChild(errorMessage);

    // Return the container element that will be used as the filter editor
    return container;
  };

  private showErrorBelowElement(element: HTMLElement, message: string): void {
    console.log('before');
    const parentElement = document.querySelector(
      '.edit-item-container'
    ) as HTMLElement;
    const errorMessageElement = document.getElementById('errorMessage2');
    console.log('errorMessageElement', errorMessageElement);
    if (errorMessageElement) {
      errorMessageElement.innerHTML = `<span style="color: red;background-color: white ">${message}</span>`;
      // Position the error message below the target element
      const rect = element.getBoundingClientRect();
      const parentRect = parentElement.getBoundingClientRect();
      errorMessageElement.style.position = 'absolute';
      errorMessageElement.style.top = `${rect.bottom - parentRect.top}px`;
      errorMessageElement.style.left = `${rect.left - parentRect.left}px`;
      errorMessageElement.style.display = 'block';
    }
  }

  /**
   * Debounces a function, ensuring that the provided function `func` is invoked only after
   * the specified `wait` time has passed since the last time the debounced function was called.
   *
   * @param {Function} func - The function to be debounced.
   * @param {number} wait - The number of milliseconds to wait before calling the function after the last invocation.
   * @returns {Function} - A new debounced function that delays the execution of `func` until after `wait` milliseconds
   *                       have passed since the last invocation.
   */
  debounceInput(func: any, wait: any): Function {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  /**
   * Validates the input filter string based on the allowed filter types and ensures proper syntax.
   *
   * @param {string} inputValue - The filter input string to validate.
   * @returns {boolean} - Returns `true` if the input passes validation, otherwise `false`.
   */
  validateInput(inputValue: string): boolean {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
      'R',
      'L',
      'S',
      'E',
      '&',
      '|',
      'M',
      '!',
    ];
    const validationPassed: boolean = true;
    const validationFailed: boolean = false;

    // Split the input string into filter types and filter strings
    let filterParts: string[] = inputValue.match(/\[.*?\]|[^ ]+/g) ?? [];

    // Create filter objects from the input parts
    const filters = this.createFilters(filterParts);

    // Check if all filters have valid types and non-empty filter strings
    const validator = filters.every((filter: any) => {
      return filter.types.every((type: any) => {
        if (filterTypes.includes(type) && filter.strings.length > 0) {
          if (type == 'Regex' || type == 'R') {
            return filter.strings.every((string: string) => {
              if (!this.isValidRegex(string)) {
                return false;
              }
              if (string.trim() === '') {
                return false;
              }
              return true;
            });
          }
          return true;
        }
      });
    });

    // If validator fails or parts couldn't be split properly, return false
    if (!validator || (inputValue && !filterParts)) {
      return validationFailed;
    }

    // Specific edge cases for syntax validation
    if (filterParts && filterParts.length > 0 && filterParts[0] === '[]') {
      return validationFailed;
    }

    if (filterParts) {
      for (let i = 0; i < filterParts?.length; i++) {
        // Check for consecutive or mismatched brackets
        if (
          filterParts[i]?.startsWith('[') &&
          filterParts[i]?.endsWith(']') &&
          filterParts.length > 1 &&
          filterParts[i + 1]?.startsWith('[') &&
          filterParts[i + 1]?.endsWith(']')
        ) {
          return validationFailed;
        } else if (
          (filterParts[i]?.startsWith('[') && !filterParts[i]?.endsWith(']')) ||
          filterParts[i]?.startsWith(']')
        ) {
          return validationFailed;
        }
      }
    } else {
      return validationPassed;
    }

    // If all checks pass, return true
    return validationPassed;
  }

  isValidRegex(pattern: string) {
    try {
      new RegExp(pattern);
      return true; // If no error is thrown, the regex is valid
    } catch (e) {
      return false; // If an error is thrown, the regex is invalid
    }
  }

  /**
   * Custom filter function to apply filters on both parent and child rows.
   *
   * @param {any} headerValue - The header value object containing the input and column status.
   * @param {any} rowValue - The value of the current row for the specified column.
   * @param {any} rowData - The data object for the current row, which may include child rows.
   * @param {any} filterParams - Additional parameters passed to the filter.
   *
   * @returns {boolean} - Returns `true` if the row or any of its child rows match the filter.
   */
  customFilterFunction = (
    headerValue: any,
    rowValue: any,
    rowData: any,
    filterParams: any
  ) => {
    // Split the input string into filter types and filter strings
    let filterParts: string[] = headerValue.inputValue.match(/\[.*?\]|[^ ]+/g);

    // Validate input, return true if the input is invalid to avoid filtering out rows
    const validator = this.validateInput(headerValue.inputValue);
    if (!validator) return true;

    // Create filter objects based on the parsed input parts
    const filters: string[] = this.createFilters(filterParts);

    // If no filters are found, return true to show all records
    if (filters.length == 0) {
      return true;
    }

    // Perform special replacement if column status requires it
    if (this.checkColStatus(headerValue.inputColumnStatus)) {
      const search = ';';
      const replacement = ' ';

      // Create a regular expression with the global flag
      rowValue = rowValue?.replace(new RegExp(search, 'g'), replacement);

      // Split the string by space, trim each word, and filter out any empty elements
      rowValue = rowValue
        ?.split(' ')
        .map((name: string) => name.trim())
        .filter((name: string) => name);

      // Join the cleaned names back into a single string
      rowValue = rowValue?.join(' ');
    }

    // Apply filter on parent row
    let rowtMatches =
      rowValue &&
      headerValue.inputValue &&
      this.matchesFilter(rowValue, filters);

    return rowtMatches;
  };

  /**
   * Recursive function to check if a child or any of its descendants match the filter.
   *
   * @param {any} child - The current child row to check.
   * @param {any} headerValue - The header value object containing the input column to match.
   * @param {Array} filters - The array of filters to apply for matching.
   *
   * @returns {boolean} - Returns `true` if the child or any of its descendants match the filter, otherwise `false`.
   */
  checkChildMatch(child: any, headerValue: any, filters: any): boolean {
    const childRowValue = child[headerValue.inputColumn]; // Adjust to match the child field

    // Check if the current child matches the filter
    if (this.matchesFilter(childRowValue, filters)) {
      return true;
    }

    // Recursively check if any descendants match the filter
    if (child._children && Array.isArray(child._children)) {
      return child._children.some((nestedChild: any) =>
        this.checkChildMatch(nestedChild, headerValue, filters)
      );
    }

    return false;
  }

  /**
   * Evaluates if a given value matches all specified filters.
   *
   * @param {any} value - The value to be checked against the filters.
   * @param {Array} filters - An array of filter objects containing filter types and filter strings.
   *
   * @returns {boolean} - Returns `true` if the value matches all filters, otherwise `false`.
   */
  matchesFilter(rowValue: any, filters: string[]): boolean {
    return filters.every((filter: any) => {
      return filter.types.every((filterType: any) => {
        return filter.strings.every((str: any) => {
          const value = rowValue?.toString();
          const filterString = str?.replace(/^['"](.*)['"]$/, '$1');
          const lowerValue = value?.toLowerCase();
          const lowerFilterString = filterString?.toLowerCase();
          switch (filterType) {
            case 'Like':
            case 'L': // Case-insensitive partial match
              return lowerValue?.includes(lowerFilterString);
            case 'Starting':
            case 'S': // Case-insensitive starting match
              return lowerValue?.startsWith(lowerFilterString);
            case 'Ending':
            case 'E': // Case-insensitive starting match
              return lowerValue?.endsWith(lowerFilterString);
            case 'Match':
            case 'M': // Case-sensitive partial match
              return rowValue?.includes(filterString);
            case 'Regex':
            case 'R':
              try {
                return new RegExp(filterString).test(value);
              } catch (e) {
                console.error('Invalid regex pattern:', filterString);
                return false;
              }
            case 'And':
            case '&': // Case-insensitive "and" match for multiple terms
              return filterString
                .toString()
                .split(' ')
                .every((str: string) => lowerValue.includes(str.toLowerCase()));
            case 'Or':
            case '|': // Case-insensitive "or" match for multiple terms
              return filterString
                .toString()
                .split(' ')
                .some((str: string) => lowerValue.includes(str.toLowerCase()));
            case 'Not':
            case '!': // Case-insensitive "not" match
              return !lowerValue.includes(lowerFilterString);
            default: // Default case uses 'Like' and 'And' behavior if no filter type is given
              return filterString
                .toString()
                .split(' ')
                .every((str: string) => lowerValue.includes(str.toLowerCase()));
          }
        });
      });
    });
  }

  /**
   * Checks if any of the provided statuses meet specific filtering rules related to items.
   *
   * @param {string[]} statuses - An array of status strings to check.
   * @returns {boolean} - Returns `true` if any status includes 'Item' and matches predefined rules; otherwise, returns `false`.
   */
  checkColStatus(statuses: []): boolean {
    // Define the rules for valid item statuses
    const rules = ['Item#≥0', '≥1', '≤2', '=2', '≥2'];

    // Find the first status that includes the substring 'Item'
    const status = statuses.find(
      (status: string) => typeof status === 'string' && status.includes('Item')
    );
    if (status && rules.includes(status)) return true;
    return false;
  }

  /**
   * Parses a filter type string and returns an array of corresponding filter types.
   *
   * @param {string} filterType - The filter type string to be parsed.
   * @returns {string[]} - An array of parsed filter types.
   */
  createFilters(filterParts: string[]): string[] {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];

    const filters: any = [];
    let currentFilterTypes: any = [];
    let currentFilterStrings: any = [];

    if (!filterParts) return [];
    if (filterParts.length == 0) return [];

    // Creaet filters based on provided parts of filter-type and filter-string
    for (let i = 0; i < filterParts.length; i++) {
      if (filterParts[i].startsWith('[') && filterParts[i].endsWith(']')) {
        // If there is an existing filter type and strings, push them to the filters array
        if (currentFilterStrings.length > 0) {
          filters.push({
            types:
              currentFilterTypes.length == 0 ? ['Like'] : currentFilterTypes,
            strings: currentFilterStrings,
          });

          currentFilterTypes = [];
          currentFilterStrings = [];
        }

        // Push parsed filter type
        const filterType = this.parseFilterType(filterParts[i]);
        currentFilterTypes.push(...filterType);
      } else {
        // Accumulate filter strings
        currentFilterStrings.push(filterParts[i]);

        // TODO: Uncomment the below line of code, as this will be used to filter hyphrn (-) separated words
        // currentFilterStrings.push(parts[i].replace(/-+/g, ' '));
      }
    }

    // Push the last filter type and strings
    if (currentFilterTypes.length > 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: currentFilterTypes.map((type: any) => type),
        strings: currentFilterStrings,
      });
    }

    // Apply the "Like" if filter-type is not present
    if (currentFilterTypes.length == 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: ['Like'],
        strings: currentFilterStrings,
      });
    }

    // Push empty filter-type for empty filter-type
    if (currentFilterTypes.length > 0 && currentFilterStrings.length == 0) {
      filters.push({
        types: currentFilterTypes,
        strings: currentFilterStrings,
      });
    }

    return filters;
  }

  parseFilterType(filterType: string) {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];
    if (!filterType.slice(1, -1)) {
      return ['[]'];
    }
    const parsedFilterType = filterType
      .slice(1, -1)
      .split(' ')
      .map(
        (type: string) => type[0].toUpperCase() + type.slice(1).toLowerCase()
      )
      .map((type: string) => {
        let processedType = type;
        if (type == 'Start(ing)') {
          processedType = 'Starting';
        } else if (type == 'End(ing)') {
          processedType = 'Ending';
        }
        return processedType;
      });

    return parsedFilterType;
  }

  // Custom filter function
  filterData(rowData: any, filterString: any) {
    const filterLower = filterString.toLowerCase();

    // Base case: Check if there are no children
    if (!rowData?._children || rowData._children.length === 0) {
      return rowData?.page_name?.toLowerCase().includes(filterLower)
        ? { page_name: rowData.page_name }
        : null;
    }

    // Check if the parent matches the filter string
    const parentMatches = rowData?.page_name
      ?.toLowerCase()
      .includes(filterLower);

    // Recursively check children for matches
    const matchedChildren = rowData._children
      .map((child: any) => this.filterData(child, filterString)) // Call recursively
      .filter((child: any) => child !== null); // Remove null results

    // If there are matched children, return them without the parent
    if (matchedChildren.length > 0) {
      // debugger;
      return matchedChildren; // Return matched children
    }

    // If the parent matches and no children matched, return the parent
    if (parentMatches && matchedChildren.length === 0) {
      return { page_name: rowData.page_name }; // Return only the parent if no children matched
    }

    // If neither matches, return null or handle accordingly
    return null; // or whatever you need in case of no matches
  }

  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.isResizing = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    const popup = document.querySelector('.edit-item-container') as HTMLElement;
    this.initialWidth = popup.offsetWidth; // Get the current width
    this.initialHeight = popup.offsetHeight; // Get the current height

    document.body.classList.add('no-select');
    event.preventDefault();

    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }

  // Function to handle drag start
  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.offsetX =
      event.clientX -
      this.popupContainer.nativeElement.getBoundingClientRect().left;
    this.offsetY =
      event.clientY -
      this.popupContainer.nativeElement.getBoundingClientRect().top;
  }

  // Function to handle mouse movement
  @HostListener('document:mousemove', ['$event'])
  onMouseMove(event: MouseEvent) {
    if (this.isDragging) {
      this.moveDialog(event);
    }
  }

  // Function to handle drag end
  @HostListener('document:mouseup')
  onMouseUp() {
    this.isDragging = false;
  }
  @ViewChild('popupContainer') popupContainer!: ElementRef; // to get the reference of the popup container for further manipulaiton

  // Move the dialog within the viewport
  moveDialog(event: MouseEvent) {
    const dialogElement = this.popupContainer.nativeElement;
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // Calculating the new position
    let newLeft = event.clientX - this.offsetX;
    let newTop = event.clientY - this.offsetY;

    // Limitizing the window for dialog to be draggable within the viewport
    newLeft =
      Math.max(
        0,
        Math.min(
          newLeft,
          viewportWidth - dialogElement.offsetWidth - (this.scrollbarWidth + 15)
        )
      ) +
      dialogElement.offsetWidth / 1.85;
    newTop =
      Math.max(
        0,
        Math.min(newTop, viewportHeight - dialogElement.offsetHeight)
      ) +
      dialogElement.offsetHeight / 2;
    // Defining new position for the dialog
    dialogElement.style.left = `${newLeft}px`;
    dialogElement.style.top = `${newTop}px`;
  }
  onResize = (event: MouseEvent) => {
    if (this.isResizing) {
      event.preventDefault();
      // Check if the mouse is within the viewport bounds
      if (event.clientX < 0 || event.clientY < 0 || event.clientX > window.innerWidth - this.scrollbarWidth || event.clientY > window.innerHeight) {
        return; // Stop resizing if the mouse is outside the viewport
      }
  
      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;
      const popup = this.popupContainer.nativeElement;
      const viewportWidth = window.innerWidth;
      const viewportHeight = window.innerHeight;
  
      // Get the current position and dimensions of the popup
      const popupRect = popup.getBoundingClientRect();


      // Calculate the new width and height based on mouse movement
      this.newWidth = Math.max(100, this.initialWidth + dx);
      this.newHeight = Math.max(100, this.initialHeight + dy);
  
      // Check if the dialog is near the left boundary and allow shrinking
      if (popupRect.left <= 0) {
        if (dx < 0) {
          // Allow shrinking when resizing to the left (dx < 0)
          this.newWidth = Math.max(100, popupRect.width - Math.abs(dx));
        } else {
          // Prevent increasing size if moving to the right
          this.newWidth = popupRect.width + popupRect.left;
        }
      }
  
      // Check if the dialog is near the top boundary and allow shrinking
      if (popupRect.top <= 0) {
        if (dy < 0) {
          // Allow shrinking when resizing upwards (dy < 0)
          this.newHeight = Math.max(100, popupRect.height - Math.abs(dy));
        } else {
          // Prevent increasing size if moving downwards
          this.newHeight = popupRect.height + popupRect.top;
        }
      }
  
      // Stop resizing if the popup is touching or crossing the right boundary
      if (popupRect.left + this.newWidth >= viewportWidth - this.scrollbarWidth) {
        this.newWidth = viewportWidth - popupRect.left - this.scrollbarWidth;
      }
  
      // Stop resizing if the popup is touching or crossing the bottom boundary
      if (popupRect.top + this.newHeight >= viewportHeight) {
        this.newHeight = viewportHeight - popupRect.top;
      }
  
      // Apply the constrained width and height to the popup element
      popup.style.width = `${this.newWidth}px`;
      popup.style.height = `${this.newHeight}px`;
    }
  };
  
  onResizeEnd = () => {
    this.isResizing = false;
    document.body.classList.remove('no-select');
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  };
}
