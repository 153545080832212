<div class="menu-bar-container">
  <!-- Refresh Chip -->
  <div class="menu-item-container">
  <div class="Chip">
    <app-tooltip tooltip="{{ sessionId }}">
      <div class="dropdown-button-container" (mouseenter)="blinkRefresh()">
        <div
        class="language-Icon"
          src="./../../../../../../assets/Icons/reload-Icon.svg"
          alt="reload-Icon"
          [class.blink]="isBlinking"
          (click)="refreshPage()"
        >
        {{refresh}}
      </div>
      </div>
    </app-tooltip>
  </div>
  </div>
  <div class="menu-item-container">
    <div class="Chip">
      <app-tooltip #tooltips [tooltip]="tooltipContent">
        <div class="dropdown-button-container" (click)="showCountryRegion()">
          {{globe}}
        </div>
      </app-tooltip>
    </div>
  </div>
  <div class="menu-item-container">

  <div class="Chip">
    <app-tooltip tooltip="{{ tooltip }}">
      <div class="dropdown-button-container" [matMenuTriggerFor]="languageMenu">
        <div class="tooltip-container">
        {{language}}
        </div>
        <input
          type="checkbox"
          id="languageCheckbox"
          name="languageCheckbox"
          class="checkbox"
          [(ngModel)]="isChecked"
          (change)="toggleAutoTranslate($event)"
          [ngStyle]="{height: height+'em',width:width+'em'}"
        />
      </div>
    </app-tooltip>
  </div>
  <mat-menu class="dropdown" #languageMenu="matMenu">
    <div
      [ngStyle]="{ 'background-color': tooltip == lang ? '#eeeef2' : '#fff' }"
      class="button-language notranslate"
      mat-menu-item
      *ngFor="let lang of filteredLanguages"
      (click)="setLanguage(lang)"
    >
      {{ lang }}
    </div>
    <div
      class="button-language notranslate"
      mat-menu-item
      (click)="setLanguage(null)"
    >
      None
    </div>
  </mat-menu>
</div>
  <!-- currency -->
  <div class="menu-item-container">

    <div class="Chip">
      <app-tooltip translate="no" tooltip="{{ uiCurrency }}"
        [matMenuTriggerFor]="currencyList">
        {{ uiCurrency }}
      </app-tooltip>
    </div>
    <mat-menu #currencyList="matMenu" class="custom-menu-panel currency-list">
      <div mat-menu-item class="notranslate currency-button" *ngFor="let item of currencies"
        (click)="setCurrency(item)">
        <!-- Directly use item.mode -->
        {{ item }}
      </div>
    </mat-menu>
  </div>
  <!-- User -->
  <div class="menu-item-container">

    <div class="Chip" (click)="openUserDialog()">
        <div class="user-custom-label">User</div>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip">
      <app-tooltip translate="no" tooltip="tooltip-container" tooltip="Page Name: All Pages
      Page Edition: Default
      Page Comment: Page: Listing of all Pages in the system. Said Page is displayed in the AllPage-dialog"
        [matMenuTriggerFor]="pageList">
        Page
      </app-tooltip>
    </div>

    <mat-menu #pageList="matMenu" class="custom-menu-panel page-list">
      <!-- Directly use item.mode -->
      <div mat-menu-item (click)="formatPage()">Format Page</div>
      <div mat-menu-item (click)="deletePage()">Delete Page</div>
      <div mat-menu-item (click)="viewPage()">View Page</div>
    </mat-menu>
  </div>
  <div class="menu-item-container">

    <div class="Chip" (click)="openEditionDialog()">
      <app-tooltip tooltip="Page Name: All Pages
  Page Edition: Default
  Page Comment: Page: Listing of all Page's in the system. Said Page is displayed in the AllPage-dialog">
        <div class="dropdown-button-container">
            <div class="tooltip-container">{{ lastEditionopen }}</div>
            <input type="checkbox" id="editionCheckbox" name="editionCheckbox" class="checkbox"
              (change)="handleCheckboxChange($event)" [disabled]="!disabled" [(ngModel)]="EditionCheckbox" [ngStyle]="{height: height+'em',width:width+'em'}"/>
        </div>
      </app-tooltip>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip" [matMenuTriggerFor]="viewMode">
        {{ currentPgMode }}
    </div>

    <mat-menu #viewMode="matMenu" class="custom-menu-panel view-list">
      <div mat-menu-item *ngFor="let item of contextMenuItems" [ngClass]="{ dimmed: item.isDimmed }"
        (click)="setViewMode(item.mode)">
        {{ item.name }}
      </div>
    </mat-menu>

  </div>
  <div class="menu-item-container">

    <div class="Chip" (click)="openColDialog()">
        Cols
    </div>
  </div>
  <div class="menu-item-container">

  <div class="Chip">
    <div class="dropdown-button-container">
      <div class="tooltip-container">
        Freeze:
      </div>
      <div class="tooltip-container">
        <u>{{ pageFreezeColumn }}</u>
      </div>
      <div (click)="toggleFreeze($event)" class="expand-icon">
        {{expand}}
      </div>
    </div>
  </div>
</div>
<!-- Assuming this is the correct location for your template file -->
<div class="menu-item-container">
  <div class="Chip">
    <div class="dropdown-button-container">
      <div class="tooltip-container">Expand:</div>
      <div class="tooltip-container">
          <input
          type="text"
          class="expand-input"
          id="expand-level-input"
          onkeydown="return event.key >= '0' && event.key <= '9' || event.key === 'Backspace' || event.key === '.' ||  event.key ==='ArrowLeft'||event.key === 'ArrowRight'||event.key ==='ArrowUp'||event.key === 'ArrowDown';"  [(ngModel)]="expandLevelN"
          (input)="onInputChange($event)" autocomplete="off" appAutoAdjustWidth
          />
      </div>
        <div class="expand-icon" (click)="onExpandToggle($event)">
          {{expand}}
        </div>
    </div>
  </div>
</div>

<div class="menu-item-container">

    <div class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Sort</div>
        <input type="checkbox" id="sortCheckbox" name="sortCheckbox" class="checkbox" (change)="toggleSort()" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Filter</div>
        <input type="checkbox" id="filterCheckbox" name="filterCheckbox" class="checkbox" (change)="toggleFilter()" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Level-Set</div>
        <input type="checkbox" id="levelSetCheckbox" name="levelSetCheckbox" class="checkbox"
          (change)="handleCheckboxChange($event)" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip" (click)="OpenAmountDialoag()">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Row-Set</div>
        <input type="checkbox" id="showSetCheckbox" name="showSetCheckbox" class="checkbox"
          (change)="handleCheckboxChange($event)" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
  <div class="menu-item-container">

    <div class="Chip" (click)="OpenJSONDialog()">
      <div class="dropdown-button-container">
         Cats
      </div>
    </div>
  </div>
  <!-- Compare -->
  <div class="menu-item-container">
    <div class="Chip" (click)="OpenTextDialog()">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Compare</div>
        <input type="checkbox" id="compareCheckbox" name="compareCheckbox" class="checkbox"
          (change)="toggleCompare()" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
  <div class="menu-item-container">
    <!-- timer -->
    <div class="Chip" (click)="OpenFormulaDialog()">
      <div class="dropdown-button-container">
        <div class="tooltip-container">Timer</div>
        <input type="checkbox" id="timerCheckbox" name="timerCheckbox" class="checkbox" (change)="toggleTimer()" [ngStyle]="{height: height+'em',width:width+'em'}"/>
      </div>
    </div>
  </div>
</div>
