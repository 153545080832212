import {
  Component,
  OnInit,
  Inject,
  ViewChildren,
  QueryList,
  ElementRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/sheet/sheet.actions';
import { SheetState } from '../../store/sheet/sheet.store';
import { Observable } from 'rxjs';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { UNICODE_MULTIPLY } from '../../shared/unicode/unicode';

@Component({
  selector: 'app-pg-col-dialog',
  templateUrl: './pgcol-dialog.component.html',
  styleUrls: ['./pgcol-dialog.component.css'],
})
export class PGColDialogComponent implements OnInit {
  closeResult: string = '';
  columns: any[] = [];
  unselected: boolean = false;
  autofocus = false;
  // Resizable and Draggable properties
  isDragging: boolean = false;
  isResizing: boolean = false;
  initialMouseX: number = 0;
  initialMouseY: number = 0;
  initialWidth: number = 0;
  initialHeight: number = 0;
  initialPopupX: number = 0; // Initial X position of the popup
  initialPopupY: number = 0; // Initial Y position of the popup
  height:any=0;
  width:any=0;
  previousFontSize: number=0;
  currentFontSize: number=0;
  newFontSize: number=0;
  closeTab = UNICODE_MULTIPLY;
  @ViewChildren('checkboxElement') checkboxItems!: QueryList<ElementRef>;

  @Select(SheetState.getSheetColumns) columns$!: Observable<any>;

  constructor(
    public dialogRef: MatDialogRef<PGColDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store
  ) {
    this.previousFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
      this.currentFontSize = this.previousFontSize;
  }

  ngOnInit() {
    this.columns$.subscribe((columns) => {
      if(columns == undefined) {
        this.store.dispatch(new Sheet.FetchSheetData(this.data?.selectedId));
        this.columns$.subscribe((columns) => {
          this.columns = columns.map((col: any) => ({ ...col }));
        });
      }else{
        this.columns = columns.map((col: any) => ({ ...col }));
      }
    });
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }
  changeFontSize(newSize: number): void {
    // Update previous font size before changing it
    this.previousFontSize = this.currentFontSize;

    // Change the current font size
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(getComputedStyle(document.documentElement).fontSize);
    this.height=(bodyFontSize/this.previousFontSize)-0.2;
    this.width=(bodyFontSize/this.previousFontSize)-0.2;
  }
  // Dragging functionality
  onDragStart(event: MouseEvent) {
    this.isDragging = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    // Get the current position of the popup
    const popup = document.querySelector('.edit-item-container') as HTMLElement;
    const transform = getComputedStyle(popup).transform;

    if (transform !== 'none') {
      const matrix = new DOMMatrix(transform);
      this.initialPopupX = matrix.m41; // Current X position
      this.initialPopupY = matrix.m42; // Current Y position
    } else {
      this.initialPopupX = 0;
      this.initialPopupY = 0;
    }

    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onDragEnd);
  }

  onDrag = (event: MouseEvent) => {
    if (this.isDragging) {
      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;

      const popup = document.querySelector(
        '.edit-item-container'
      ) as HTMLElement;
      popup.style.transform = `translate(${this.initialPopupX + dx}px, ${
        this.initialPopupY + dy
      }px)`;
    }
  };

  onDragEnd = () => {
    this.isDragging = false;
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onDragEnd);
  };

  // Resizing functionality
  onResizeStart(event: MouseEvent) {
    this.isResizing = true;
    this.initialMouseX = event.clientX;
    this.initialMouseY = event.clientY;

    const popup = document.querySelector('.edit-item-container') as HTMLElement;
    this.initialWidth = popup.offsetWidth; // Get the current width
    this.initialHeight = popup.offsetHeight; // Get the current height

    document.body.classList.add('no-select');
    event.preventDefault();

    document.addEventListener('mousemove', this.onResize);
    document.addEventListener('mouseup', this.onResizeEnd);
  }

  onResize = (event: MouseEvent) => {
    if (this.isResizing) {
      event.preventDefault();

      const dx = event.clientX - this.initialMouseX;
      const dy = event.clientY - this.initialMouseY;

      const popup = document.querySelector(
        '.edit-item-container'
      ) as HTMLElement;
      const newWidth = Math.max(100, this.initialWidth + dx); // Set minimum width
      const newHeight = Math.max(100, this.initialHeight + dy); // Set minimum height

      popup.style.width = `${newWidth}px`;
      popup.style.height = `${newHeight}px`;
    }
  };

  onResizeEnd = () => {
    this.isResizing = false;
    document.body.classList.remove('no-select');
    document.removeEventListener('mousemove', this.onResize);
    document.removeEventListener('mouseup', this.onResizeEnd);
  };

  onClose(): void {
    this.dialogRef.close();
  }

  toggleColumnVisibility(column: any) {
    if (column.status.includes('Nested')) {
      return;
    }
    if (this.isVisible(column)) {
      column.status.push('Hidden');
    } else {
      const index = column.status.indexOf('Hidden');
      if (index !== -1) {
        column.status.splice(index, 1);
      }
    }
    this.store.dispatch(new Sheet.updateColumns(this.columns));

  }

  isVisible(column: any): boolean {
    return !column.status.includes('Hidden');
  }

  drop(event: CdkDragDrop<any[]>): void {
    moveItemInArray(this.columns, event.previousIndex, event.currentIndex);
    this.store.dispatch(new Sheet.updateColumns(this.columns));
  }

  ngOnDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  }

  onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      this.onClose();
    }
  };

  ngAfterViewInit() {
    document.addEventListener('keydown', this.onKeydown);
  }
}
