import { Component, ViewChild, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OpenEditionDialogComponent } from '../open-edition-dialog/open-edition-dialog.component';
import { EditionPGNameDialogComponent } from '../edition-pgname-dialog/edition-pgname-dialog.component';
import {
  NgbModal,
  NgbModalRef,
  ModalDismissReasons,
} from '@ng-bootstrap/ng-bootstrap';
import { UNICODE_MULTIPLY } from '../../shared/unicode/unicode';

@Component({
  selector: 'app-pg-edition-dialog',
  templateUrl: './pgedition-dialog.component.html',
  styleUrls: ['./pgedition-dialog.component.css']
})
export class PGEditionDialogComponent {
  closeResult: string = '';
  @ViewChild('content', { static: true }) content: any;
  private modalRef!: NgbModalRef;
  closeTab=UNICODE_MULTIPLY;
  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<PGEditionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialog: MatDialog
  ) {}

  ngOnInit() {
    this.open(this.content);

  
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.onClose();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.onClose();
      return 'by clicking on a backdrop';
    } else {
      // this.onCancel();
      return `with: ${reason}`;
    }
  }

  // onSave(): void {
  //   // this.dialogRef.close(this.editFormControlName.value);
  //   this.modalRef.dismiss('Save click');
  // }

  // onCancel(): void {
  //   this.dialogRef.close();
  //   this.modalRef.dismiss('Cancel click');
  // }

  onOpen(): void {
    this.dialog.open(OpenEditionDialogComponent);
    this.modalRef.dismiss('Save click');

  }

  onSaveAs(): void {
    this.dialog.open(EditionPGNameDialogComponent);
    this.modalRef.dismiss('Save click');

  }

  onClose(): void {
    this.dialogRef.close();
  }
}
