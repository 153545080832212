<div class="edit-item-container" #popupContainer>
  <div class="modal-header" (mousedown)="onDragStart($event)">
    <div class="modal-title text-nowrap">Edit-DD-dialog</div>
    <div type="button" class="close" aria-label="Close" (click)="onClose()">
      <span aria-hidden="true">{{ closeTab }}</span>
    </div>
  </div>
  <!-- <div class="parent-scroller " #parentScroller > -->
    <!-- <div class="child-scroller "> -->
      <div class="menu-bar-container">
        <div class="menu-item-container">
          <div class="Chip" (click)="openColDialog()">
            <div>Cols</div>
          </div>
        </div>
        <div class="menu-item-container">
          <div class="Chip">
            <div class="dropdown-button-container">
              <div class="tooltip-container">Freeze:</div>
              <div class="tooltip-container">
                <u>{{ pageFreezeColumn }}</u>
              </div>
              <div class="expand-icon" (click)="toggleFreeze($event)">
                {{ expand }}
              </div>
            </div>
          </div>
        </div>
    
        <div class="menu-item-container">
          <div class="Chip">
            <div class="dropdown-button-container">
              <div class="tooltip-container">Expand:</div>
              <div class="tooltip-container">
                <input
                  type="text"
                  class="expand-input"
                  id="expand-level-input"
                  (keydown)="handleKeydown($event)"
                  [(ngModel)]="expandLevelN"
                  (input)="onInputChange($event)"
                  autocomplete="off"
                  appAutoAdjustWidth
                  mask="99999"
                />
              </div>
              <div class="expand-icon" (click)="onExpandToggle($event)">
                {{ expand }}
              </div>
            </div>
          </div>
        </div>
        <div class="menu-item-container">
          <div class="Chip">
            <div class="dropdown-button-container">
              <div class="tooltip-container">
                <span>Sort</span>
              </div>
              <input
                type="checkbox"
                id="sortCheckbox"
                name="sortCheckbox"
                class="checkbox"
                (change)="toggleSort()"
                [ngStyle]="{ height: height + 'em', width: width + 'em' }"
              />
            </div>
          </div>
        </div>
        <div class="menu-item-container">
          <div class="Chip">
            <div class="dropdown-button-container">
              <div class="tooltip-container">
                <span>Filter</span>
              </div>
              <input
                type="checkbox"
                id="filterCheckbox"
                name="filterCheckbox"
                class="checkbox"
                (change)="toggleFilter()"
                [ngStyle]="{ height: height + 'em', width: width + 'em' }"
              />
            </div>
          </div>
        </div>
        <div class="menu-item-container">
          <div class="Chip" (click)="clearFilters()">
            <div class="dropdown-button-container">
              <span style="color: red">Clear</span>
            </div>
          </div>
        </div>
      </div>
      <div class="tabulator-container">
        <div #tabulatorDiv2 class="tabulator tabulator2"></div>
      </div>
    <!-- </div>
  </div> -->
  <div class="resizer" (mousedown)="onResizeStart($event)">
    <div class="line1">
      <div class="border1">

      </div>
      <div class="border2">

      </div>
    </div>  
  </div>
</div>
