import {
  Component,
  Input,
  ViewChild,
  ElementRef,
  OnChanges,
  SimpleChanges,
  OnInit,
  AfterViewInit,
  HostListener,
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  CellComponent,
  ColumnComponent,
  RowComponent,
  TabulatorFull as Tabulator,
} from 'tabulator-tables';
import { DialogEditComponent } from '../../../modals/dialog-edit/dialog-edit.component';
import { DialogDeleteComponent } from '../../../modals/dialog-delete/dialog-delete.component';
import { DialogAddComponent } from '../../../modals/dialog-add/dialog-add.component';
import { DialogViewComponent } from '../../../modals/dialog-view/dialog-view.component';
import { DialogFormatComponent } from '../../../modals/dialog-format/dialog-format.component';
import * as XLSX from 'xlsx';
import { HttpClient } from '@angular/common/http';
import { FormatLocalSharedColComponent } from '../../../modals/format-local-shared-col/format-local-shared-col.component';
import { ViewColDialogComponent } from '../../../modals/view-col-dialog/view-col-dialog.component';
import { AddRowDialogComponent } from '../../../modals/add-row-dialog/add-row-dialog.component';
import { FormatRowDialogComponent } from '../../../modals/format-row-dialog/format-row-dialog.component';
import { MenuBarService } from '../../../services/menu-bar-service/manu-bar.service';
import { AllPages_columns } from '../../../core/storage/AllPages_Columns.storage';
import { FontSelectServiceService } from '../../../services/font-select-service/font-select-service.service';
import { Select, Store } from '@ngxs/store';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscribable,
  Subscription,
  takeUntil,
  Unsubscribable,
} from 'rxjs';
import { SheetState } from '../../../store/sheet/sheet.store';
import { Sheet } from '../../../store/sheet/sheet.actions';
import { DialogEditUrlComponent } from '../../../modals/dialog-edit-url/dialog-edit-url.component';
import { SheetService } from '../../../services/sheet-service/sheet.service';
import { Item } from '../../../store/item/item.actions';
import { ItemState } from '../../../store/item/item.store';
import { Language } from '../../../store/language/language.actions';
import { LanguageState } from '../../../store/language/language.store';
import { FontState } from '../../../store/fontStyle/font.store';
import { FormatService } from '../../../services/format-service/format.service';
import { DropdownDialogComponent } from '../../../modals/dropdown-dialog/dropdown-dialog.component';
import { EditAmountDialogComponent } from '../../../modals/edit-amount-dialog/edit-amount-dialog.component';
import { pagesId } from '../../../constant';
import {
  ASCII_DASH,
  ASCII_PLUS,
  ASCII_SPACE,
  UNICODE_DASH,
  UNICODE_DOWN_ARROW,
  UNICODE_PLUS_CODE,
  UNICODE_UP_ARROW,
} from '../../../shared/unicode/unicode';

@Component({
  selector: 'app-sheet',
  templateUrl: './sheet.component.html',
  styleUrls: ['./sheet.component.css'],
})
export class SheetComponent implements OnChanges, OnInit, AfterViewInit {
  @Input() sheet: any;
  @Input() sheetSelected: any;
  private tabulatorTable!: Tabulator;
  public tableData: any = [];
  public tableData1: any = [];
  public showDropdown: boolean = false;
  public deletedColumnDef: any;
  public deleteColumnName: any;
  private currentColumn: any = null;
  private filterParams: any = [];
  showRowHeader: boolean = false;
  load: any = false;
  activeTab: any;
  @ViewChild('tabulatorDiv', { static: true }) tabulatorDiv!: ElementRef;
  public emptyRecords: any[] = Array(50).fill({});
  private currentCell: any = null;
  freezLevel: any = 0;
  expandLevels: boolean[] = [];
  public sortStatus = false;
  public isSorted = false;
  private isFilter: boolean = false;
  private unsubscribe$ = new Subject<void>();
  private sub: Subscription | null = null;
  public allCols: any[] = [];
  disabled: boolean = false;
  private languageArray: [{}] = [{}];
  public columnData: any[] = [];
  height: any = 0;
  width: any = 0;
  previousFontSize: number;
  currentFontSize: number;
  newFontSize: number = 0;
  lineDiv:any;
  constructor(
    private menuBarService: MenuBarService,
    public dialog: MatDialog,
    private http: HttpClient,
    private fontService: FontSelectServiceService,
    private store: Store,
    private sheetService: SheetService,
    private formatService: FormatService
  ) {
    this.previousFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.currentFontSize = this.previousFontSize;
  }

  array: any = [];
  pages: any = [];
  cols: any[] = [];
  tokens: any[] = [];
  languages: any[] = [];
  regions: any[] = [];
  suppliers: any[] = [];
  models: any[] = [];
  labels: any[] = [];
  units: any[] = [];
  profiles: any[] = [];
  last: any = 0;
  allTokenData: any[] = [];
  allLanguagesData: any = ([] = []);
  allRegionsData: any[] = [];
  allSuppliersData: any[] = [];
  allModelsData: any[] = [];
  allLabelsData: any[] = [];
  allUnitsData: any[] = [];
  allProfilesData: any[] = [];
  allData: any = ([] = []);
  allDataWithoutNested: any = ([] = []);
  column: any;
  cellValue: any;
  cell: CellComponent | null = null;
  colMinWith: number = 0;
  levels: any;
  @Select(SheetState.getSheetData) data$: Observable<any> | undefined;
  @Select(SheetState.getSheetColumns) columns$: Observable<any> | undefined;
  @Select(ItemState.getItemData) itemData$: Observable<any> | undefined;
  @Select(ItemState.getEditItemData) editItemData$: Observable<any> | undefined;
  @Select(LanguageState.getLanguages) languages$: Observable<any> | undefined;
  @Select(FontState.getFontStyle) fontStyle$: Observable<any> | undefined;
  @Select(SheetState.formatLocalCol) res$: Observable<any> | undefined;
  @Select(SheetState.getVisitedData) visited$: Observable<any> | undefined;
  pageIdsData: any[] = [
    /* your array of page IDs */
  ];

  selectedPage: string = '';
  private languageDetect: any;
  newRowData = {
    row: 3000000017,
    page_id: 1000000017,
    page_name: '',
    page_type: '',
    page_edition: '',
    page_owner: '',
    page_url: 'URL to open this Page',
    page_seo: '',
    page_status: '',
    page_comment: '',
  };
  ngOnInit(): void {
    this.generateRecords();
    this.renderTabulator();
    this.freezeLevel();
    this.expandLevel();

    this.menuBarService.sortStatus.subscribe((sortStatus) => {
      if (sortStatus == 'Sort is Off') {
        this.sortStatus = false;
        this.tabulatorTable.clearSort();
      } else if (sortStatus == 'Sort is On') {
        this.sortStatus = true;
      }
    });

    this.menuBarService.filterStatus.subscribe((filterStatus) => {
      if (filterStatus == 'Filter is Off') {
        this.isFilter = false;
        this.updateTableWithFilters(false);
      } else if (filterStatus == 'Filter is On') {
        this.isFilter = true;
        this.updateTableWithFilters(true);
      }
    });
    this.setCurrencyList();
    this.adjustCheckboxSize();
    window.addEventListener('resize', this.adjustCheckboxSize.bind(this));
  }

  changeFontSize(newSize: number): void {
    // Update previous font size before changing it
    this.previousFontSize = this.currentFontSize;

    // Change the current font size
    this.currentFontSize = newSize;
    document.documentElement.style.fontSize = `${newSize}px`;
  }
  private adjustCheckboxSize() {
    const bodyFontSize = parseFloat(
      getComputedStyle(document.documentElement).fontSize
    );
    this.height = bodyFontSize / this.previousFontSize - 0.2;
    this.width = bodyFontSize / this.previousFontSize - 0.2;
  }

  activeSheet: any;

  ngOnChanges(changes: SimpleChanges): void {
    this.getData();

    if (changes['sheet'] && !changes['sheet'].firstChange) {
      this.renderTabulator();
    }
    this.clearErrorMessage();
    this.clearFilterCheckbox();

    this.activeSheet = changes['sheet']['currentValue']['name'];
    this.menuBarService.setPageEdition(this.sheet.page_edition);
  }

  clearFilterCheckbox() {
    const filterCheckBox = document.getElementById(
      'filterCheckbox'
    ) as HTMLInputElement;
    filterCheckBox.checked = false;

    this.menuBarService.refreshFilterCheckbox();
  }

  clearErrorMessage(): void {
    const errorMessageElement = document.getElementById('errorMessage');
    if (errorMessageElement) {
      errorMessageElement.style.display = 'none';
      this.currentCell.getElement().style.backgroundColor = 'none';
    }
  }

  // Usage
  getData() {
    // Reset state
    this.store.reset(new Sheet.FetchSheetData(this.sheet.page_id));
    // Dispatch new state
    this.store.dispatch(new Sheet.FetchSheetData(this.sheet.page_id));
    this.data$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      this.allDataWithoutNested = [...data];
      this.allData = this.buildNestedDataIterative(data);
    });
  }

  setCurrencyList() {}

  hasChildrenInAnyRow = false;
  buildNestedDataIterative(inputData: any) {
    this.hasChildrenInAnyRow = false;
    const map: { [key: string]: any } = {};
    const structuredData: any[] = [];

    inputData.forEach((item: any) => {
      const newItem = {
        ...item,
      };

      if (item?.ParentRow?.Row === null || item?.ParentRow === null) {
        structuredData.push(newItem);
      } else {
        if (item?.ParentRow?.Row !== null) {
          this.hasChildrenInAnyRow = true;
        }
        const parentRow = map[item?.ParentRow?.Row];
        if (parentRow) {
          if (!parentRow._children) {
            parentRow._children = []; // Initialize only if it doesn't exist
          }
          parentRow._children.push(newItem);
        }
      }

      map[item?.row] = newItem;
    });

    return structuredData;
  }

  changeModelReleaseDateFormat(serial: any) {
    var utc_days = Math.floor(serial - 25569);
    var utc_value = utc_days * 86400;
    var date_info = new Date(utc_value * 1000);

    var fractional_day = serial - Math.floor(serial) + 0.0000001;

    var total_seconds = Math.floor(86400 * fractional_day);

    var seconds = total_seconds % 60;

    total_seconds -= seconds;

    var hours = Math.floor(total_seconds / (60 * 60));
    var minutes = Math.floor(total_seconds / 60) % 60;

    return new Date(
      date_info.getFullYear(),
      date_info.getMonth(),
      date_info.getDate(),
      hours,
      minutes,
      seconds
    ).toLocaleDateString();
  }

  readFile(file: File): Promise<XLSX.WorkBook> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const binaryString = e.target.result;
        const workbook: XLSX.WorkBook = XLSX.read(binaryString, {
          type: 'binary',
        });
        resolve(workbook);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsBinaryString(file);
    });
  }

  showColumnContextMenu(event: MouseEvent, column: ColumnComponent) {
    event.preventDefault();
    // Check if the event target has the 'column-header' class
    if (
      !(event.target as HTMLElement).classList.contains('tabulator-col-title')
    ) {
      return;
    }
    event.preventDefault();
    this.showDropdown = !this.showDropdown;
    this.deletedColumnDef = column?.getDefinition();
    this.deleteColumnName = column.getDefinition().title;
    // Ensure the dropdown menu is available after setting showDropdown to true
    setTimeout(() => {
      const dropdownMenu = document.querySelector(
        '.dropdown-container'
      ) as HTMLElement;
      if (!dropdownMenu) {
        return;
      }

      // Get the position of the header cell
      const headerCell = event.target as HTMLElement;
      const rect = headerCell.getBoundingClientRect();

      // Adjust x and y coordinates
      const newX = rect.left; // Start of the header column
      const newY = rect.bottom; // Below the bottom border of the header column box

      dropdownMenu.style.top = `${newY}px`;
      dropdownMenu.style.left = `${newX}px`;
    }, 0);
    // Remove dropdown menu on clicking anywhere outside it
    this.column = column.getField();
    document.body.addEventListener('click', this.hideDropdown);
  }

  hideDropdown = (event: MouseEvent) => {
    // Ensure the click event is outside the dropdown menu
    const dropdownMenu = document.querySelector(
      '.dropdown-container'
    ) as HTMLElement;
    if (dropdownMenu && !dropdownMenu.contains(event.target as Node)) {
      this.showDropdown = false;
      // Remove the event listener to avoid multiple bindings
      document.body.removeEventListener('click', this.hideDropdown);
    }
  };

  deleteColumn() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: 'Column',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 'Column') {
        this.tabulatorTable.deleteColumn(this.deletedColumnDef.field);
        this.sheetService.deleteColumn(
          this.sheet.page_id,
          this.deletedColumnDef.col
        );
      }
    });

    this.showDropdown = false;
    document.body.removeEventListener('click', this.hideDropdown);
  }

  ngAfterViewInit(): void {
    this.renderTabulator();
    // Handle click outside to hide dropdown menu
    document.addEventListener('click', () => this.hideDropdownMenu());
    this.freezeLevel();
    this.expandLevel();
  }
  // Method to render Column Dynamic according to the selected sheet
  // TODO: Remove the below method in case of dynamic data

  dataSelection(sheet: any) {
    let selectedColumns;
    selectedColumns = this.allData;
    return selectedColumns;
  }

  underlineFormatter = (cell: { getValue: () => any }) => {
    const value = cell.getValue();
    if (value) {
      return `<span style="text-decoration: underline;">${value}</span>`;
    } else {
      return value;
    }
  };

  boldSectionHead = (cell: { getValue: () => any; getData: () => any }) => {
    const value = cell.getValue();
    const data = cell.getData();
    if (data.RowLevel == 0) {
      return `<span style="font-weight:bold;">${value}</span>`;
    }
    return value;
  };

  // Check if the column contains any semicolon
  checkColumnForSemicolons(column: any): boolean {
    return this.checkColStatus(column.status);
  }

  generateColumnCode(columnSelection: any = []) {
    const columns = columnSelection.map((column: any, index: number) => {
      const hasSemicolon = this.checkColumnForSemicolons(column); // Check if the column has semicolons
      const formatter = (cell: any, formatterParams: any, onRendered: any) => {
        const value = cell.getValue();

        // If the column has a semicolon, format all items in that column with chips
        if (hasSemicolon) {
          return this.chipFormater()(cell, formatterParams, onRendered);
        } else {
          return value; // Return the original value if no semicolon
        }
      };

      if (index < +this.freezLevel) {
        column.frozen = true;
      }

      if (column.status.includes('Nested')) {
        return { ...column, formatter: this.boldSectionHead };
      }

      // Use underline formatter for "Page URL" column
      if (column.field === 'page_url' && column.field !== 'undefined') {
        return { ...column, formatter: this.underlineFormatter };
      } else {
        return { ...column, formatter }; // Apply the dynamic formatter to other columns
      }
    });

    return columns;
  }

  chipFormater() {
    return (cell: any, formatterParams: any, onRendered: any) => {
      const value = cell.getValue();

      // Create a container for chips
      const chipContainer = document.createElement('div');
      chipContainer.classList.add('button-container');

      // Split by semicolon or treat as single item
      const items =
        value && typeof value === 'string'
          ? value.includes(';')
            ? value.split(';').map((item) => item.trim())
            : [value.trim()]
          : [];

      // Create chips for each item
      items.forEach((item) => {
        // Create the outer div for each item
        const outerDiv = document.createElement('div');
        outerDiv.className = 'menu-item-container'; // Set the class name

        // Create the Button div
        const chipDiv = document.createElement('div');
        chipDiv.className = 'Chip';
        chipDiv.title = item; // Set the chip tooltip
        chipDiv.textContent = item;

        // Append the Button div to the outer div
        outerDiv.appendChild(chipDiv);

        // Append the outer div to the chip container
        chipContainer.appendChild(outerDiv);
      });

      // Remove the cell tooltip by removing the title attribute
      cell.getElement().removeAttribute('title');

      return chipContainer.outerHTML;
    };
  }

  private renderTabulator(): void {
    let selectedColumns: any = [];
    let columnsWithHeaderContext: any = [];
    if (this.tabulatorTable) {
      this.tabulatorTable.destroy();
    }
    this.store.dispatch(new Sheet.FetchSheetColumns(this.sheet.page_id));
    this.load = true;
    this.columns$?.subscribe((columns) => {
      if (this.showRowHeader) {
        columnsWithHeaderContext.unshift({
          titleFormatter: 'rowSelection',
          titleFormatterParams: {
            rowHandle: true, // Ensure it is recognized as a row selection column
          },
          headerSort: false,
          resizable: false,
          hozAlign: 'center',
          cellClick: function (
            e: any,
            cell: { getRow: () => { toggleSelect: () => void } }
          ) {
            cell.getRow().toggleSelect();
          },
          width: 20,
          headerHozAlign: 'center',
          formatter: 'rowSelection',
        });
      }
      selectedColumns = columns;
      this.load = false;
      columnsWithHeaderContext = this.generateColumnCode(
        columnsWithHeaderContext
      )?.map((column: any) => {
        return {
          ...column,
          headerClick: (e: any, column: any) => {
            this.headerClickFunc(e, column);
          },
        };
      });
      columnsWithHeaderContext = selectedColumns?.map((column: any) => {
        const isVisible = !column.status.includes('Hidden');
        return {
          ...column,
          headerContext: this.showColumnContextMenu.bind(this),
          headerFilterLiveFilter: false,
          visible: isVisible,
          headerFilter: this.isFilter == true ? this.customFilterEditor : false,
          headerFilterFunc: this.customFilterFunction,
          headerClick: (e: any, column: any) => {
            this.headerClickFunc(e, column);
          },
          contextMenu:
            column.field !== 'page_id' && column.field !== 'row'
              ? (e: any, cell: any) => {
                  this.currentCell = cell;
                  this.showDropdownMenu(e, cell);
                  if (this.currentCell['_cell']['value'].length != 0) {
                    this.disabled = true;
                  } else {
                    this.disabled = false;
                  }
                }
              : '',
        };
      });

      const nestedColumn = columnsWithHeaderContext.find((column: any) =>
        column.status?.includes('Nested')
      )?.field;
      this.tabulatorTable = new Tabulator(this.tabulatorDiv?.nativeElement, {
        data: this.dataSelection(this.sheet),
        columns: this.generateColumnCode(columnsWithHeaderContext),
        frozenRows: 0,
        dataTree: true,
        dataTreeFilter: true,
        dataTreeStartExpanded: this.expandLevel(),
        dataTreeElementColumn: nestedColumn,
        dataTreeCollapseElement:
          '<div class="collapse-sign"></div>',
        dataTreeExpandElement:
          '<div class="collapse-sign"></div>',
        addRowPos: 'bottom',
        validationMode: 'highlight',
        columnHeaderSortMulti: true,
        headerSortClickElement: 'icon',
        columnDefaults: {
          resizable: true, // Ensure columns are resizable,
          headerSortTristate: true,
          maxWidth:600,
        },
        progressiveLoad: 'scroll',
        layout: 'fitDataFill',
        layoutColumnsOnNewData: true,
        movableColumns: true,
        spreadsheet: true,
        spreadsheetRows: 250,
        movableRows: false,
        headerSortElement: function (column, dir) {
          switch (dir) {
            case 'asc':
              return '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>';
            case 'desc':
              return (
                '<div class="sorter">' + `${UNICODE_DOWN_ARROW}` + '</div>'
              );
            default:
              return '<div class="sorter">' + `${UNICODE_UP_ARROW}` + '</div>';
          }
        },
        rowFormatter: (row) => {
          this.formatRow(row, nestedColumn);
          row.getCells().forEach((cell) => {
            const value = cell.getValue();
            // If the cell contains chips, remove the tooltip
            if (value && typeof value === 'string' && value.includes(';')) {
              cell.getElement().removeAttribute('title');
            } else {
              // Ensure tooltip is available for all other cells
              cell.getElement().setAttribute('title', value);
            }
          });

          const rowHandle = row
            .getElement()
            .querySelector(`[tabulator-field="row"]`) as HTMLElement;
          if (rowHandle) {
            rowHandle.addEventListener('contextmenu', (e: MouseEvent) => {
              this.createContextMenu(e, row);
            });
          }
          this.fontStyle$?.subscribe((result: any) => {
            if (result != undefined) {
              let res = result['pageFontStyle']?.split(':')[0];
              row.getElement().style.minWidth = this.colMinWith + 'px';
              row.getElement().style.backgroundColor = res.split(';')[5];
              row.getElement().style.color = res.split(';')[0];
              row.getElement().style.fontSize = res.split(';')[2];
              row.getElement().style.textAlign = res.split(';')[4];
              if (res.split(';')[3] == '0') {
                row.getElement().style.fontStyle =
                  res.split(';')[3] == '0' ? 'normal' : '';
              } else if (res.split(';')[3] == '2') {
                row.getElement().style.fontWeight =
                  res.split(';')[3] == '2' ? 'bold' : '';
              } else if (res.split(';')[3] == '1') {
                row.getElement().style.fontStyle =
                  res.split(';')[3] == '1' ? 'italic' : '';
              }
              if (res.split(';')[6] == 'italicText') {
                row.getElement().style.fontStyle = 'italic';
              } else if (res.split(';')[6] == 'boldText') {
                row.getElement().style.fontWeight = 'bold';
              } else if (res.split(';')[6] == 'strikeThrough') {
                row.getElement().classList.add('text-linethrough-class');
              } else if (res.split(';')[6] == 'underline') {
                row.getElement().classList.add('text-underline-class');
              } else if (res.split(';')[6] == 'textWrap') {
                row.getElement().classList.add('text-wrap-class');
              } else if (res.split(';')[6] == 'superScript') {
                const element = row.getElement();
                const originalText = element.innerHTML;
                element.innerHTML = originalText.replace(
                  originalText,
                  '<sup>' + originalText + '</sup>'
                );
              } else if (res.split(';')[6] == 'subScript') {
                const element = row.getElement();
                const originalText = element.innerHTML;
                element.innerHTML = originalText.replace(
                  originalText,
                  '<sub>' + originalText + '</sub>'
                );
              } else if (res.split(';')[6] == 'textBorder') {
                row.getElement().style.border = '1px solid';
              }
              row.getElement().style.fontFamily = res.split(';')[1];
            }
          });
        },
      });

      // Attach the event for updating column order
      this.tabulatorTable.on('columnMoved', (column, columns) => {
        this.sheetService.orderColumns(this.sheet.page_id, columns);
      });
      
      this.tabulatorTable.on("rowClick", (e, row) => {
        const element = e.target as HTMLElement;
        if (element.id === 'line-id') {
            // Toggle expansion state
            const isExpanded = row.isTreeExpanded();
            isExpanded ? row.treeCollapse() : row.treeExpand();
          }
      });

      // Applying filter editor upon re-rendering
      setTimeout(() => {
        if (this.isFilter) {
          this.updateTableWithFilters(true);
        } else {
          this.updateTableWithFilters(false);
        }
      }, 300);
    });
  }

  
  formatRow(row: any, nestedColumn: string): void {
    const depth = row.getData().RowLevel; // Get the RowLevel
    const hasChildren = row.getTreeChildren().length > 0;
    if (!this.hasChildrenInAnyRow) {
      row
        .getCells()
        .forEach((cell: { getElement: () => any; getField: () => string }) => {
          const cellElement = cell.getElement();
        });
    }
    row
      .getCells()
      .forEach((cell: { getElement: () => any; getField: () => string }) => {
        const field = cell.getField();
        if (field === nestedColumn) {
        
          const cellElement = cell.getElement();
          Array.from(cellElement.querySelectorAll('.line')).forEach((line) => {
            (line as HTMLElement).remove();
          });
          const parentDiv = document.createElement('div');
          parentDiv.classList.add('RowHeaderDiv');
          
          if (depth > 0) {
            for (let i = 0; i < depth; i++) {
              const lineDiv = document.createElement('div');
              if(hasChildren && i==depth-1){
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML=row.isTreeExpanded()?ASCII_DASH:ASCII_PLUS;
                lineDiv.id='line-id';
              }else{
                lineDiv.classList.add('RowHeader');
                lineDiv.innerHTML=ASCII_SPACE;
              }
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
            }
          }
          if(depth==0){
            const lineDiv = document.createElement('div');
              lineDiv.classList.add('SectionRowHeader');
              lineDiv.innerHTML=row.isTreeExpanded()?ASCII_DASH:ASCII_PLUS;
              lineDiv.id='line-id';
              parentDiv.append(lineDiv);
              cellElement.appendChild(parentDiv);
          }
        } else {
          const cellElement = cell.getElement();
          }
      });
  }

  private rowVisibilityFormatter(row: any) {
    this.menuBarService.Expand_Visibilt.subscribe((res) => {
      this.levels = res;
      const data = row.getData();
      const rowElement = row.getElement();

      if (rowElement) {
        // Ensure the row element exists
        if (
          data['status'] === 'hidden' &&
          (res.x == null || res.x == undefined)
        ) {
          rowElement.style.display = 'none'; // Hide the row
        } else {
          rowElement.style.display = ''; // Show the row
        }
      } else {
      }
    });
  }

  // Method to hide/show cells based on the 'Item_visibility' field for the specific column in each row
  private cellVisibilityFormatter(cell: any) {
    const rowData = cell.getRow().getData();
    const field = cell.getField();
    this.menuBarService.Expand_Visibilt.subscribe((res) => {
      // Check if the specific item should be hidden
      const cellElement = cell.getElement();
      if (cellElement) {
        if (
          rowData.Cell_visibility &&
          rowData.Cell_visibility[field] === false &&
          (res.y == null || res.y == undefined)
        ) {
          cell.getElement().style.display = 'none'; // Hide the cell
          return ''; // Return empty string or any other placeholder content
        } else {
          cell.getElement().style.display = ''; // Show the cell
          return cell.getValue(); // Return the original cell value
        }
      }
    });
  }

  private ItemVisibilityFormatter(cell: any) {
    const rowData = cell.getRow().getData();
    const field = cell.getField();
    const originalValueKey = `_original_${field}`;

    // Cache the original value if not already cached
    if (!rowData[originalValueKey]) {
      rowData[originalValueKey] = cell.getValue();
    }

    this.menuBarService.Expand_Visibilt.subscribe((res) => {
      const cellElement = cell.getElement();
      if (cellElement) {
        if (
          rowData.Item_visibility &&
          rowData.Item_visibility[field] === false &&
          (res.z == null || res.z == undefined)
        ) {
          cell.setValue(''); // Set the cell content to empty
        } else {
          cell.setValue(rowData[originalValueKey]); // Restore original value
        }
      } else {
      }
    });
  }

  freezeLevel() {
    this.freezLevel = 0;
    this.menuBarService.pageFormate.subscribe((res) => {
      if (res.pageFreezeColumn) {
        this.freezLevel = res.pageFreezeColumn;
        this.renderTabulator();
      }
    });
  }

  expandLevel(): boolean[] {
    if (!this.sub) {
      this.sub = this.menuBarService.pageFormate.subscribe((res) => {
        if (res.pageExpand) {
          this.expandLevels = Array(res.pageExpand.n).fill(true);
          this.renderTabulator();
        }
      });
    }
    return this.expandLevels;
  }

  sheetName(): string | boolean {
    if (this.sheet.name == 'All Pages') {
      return 'page_name';
    }
    return false;
  }

  createContextMenu(e: MouseEvent, row: RowComponent): void {
    e.preventDefault();
    const menu = document.createElement('div');
    menu.style.position = 'absolute';
    menu.style.zIndex = '10000';
    menu.style.top = `${e.pageY}px`;
    menu.style.left = `${e.pageX}px`;
    menu.style.backgroundColor = '#fff';
    menu.style.border = '1px solid #ccc';
    menu.style.borderRadius = '3px';
    menu.style.boxShadow = '0px 0px 10px rgba(0,0,0,0.1)';
    menu.classList.add('context-menu');

    const menuItems = [
      'Add Previous Row',
      'Add Next Row',
      'Add Child Row',
      'Delete Row',
      'Format Local-Row',
      'Format Shared-Row',
      'Select Row(s)',
    ];
    menuItems.forEach((label) => {
      const menuItem = document.createElement('div');
      menuItem.innerText = label;
      menuItem.style.lineHeight = '11px';
      menuItem.style.padding = '2px';
      menuItem.style.fontSize = '14px';
      menuItem.style.fontFamily = 'Arial Narrow';
      menuItem.style.cursor = 'pointer';

      menuItem.addEventListener('click', () => {
        this.handleMenuAction(label, row);
        if (menu.parentElement) {
          menu.parentElement.removeChild(menu);
        }
      });
      menu.appendChild(menuItem);
    });

    // Remove all previous context menus that was appended
    const contextMenus = Array.from(
      document.getElementsByClassName('context-menu')
    );
    if (contextMenus.length > 0) {
      contextMenus.forEach(function (menu) {
        if (menu != null && menu.parentElement != null) {
          menu.parentElement.removeChild(menu);
        }
      });
    }

    document.body.appendChild(menu);
    document.addEventListener(
      'click',
      () => {
        if (menu.parentElement) {
          menu.parentElement.removeChild(menu);
        }
      },
      { once: true }
    );
  }
  currentRow: any;
  lockTimer: any;
  acquireRowLock(callback: Function) {
    // Simulate row lock acquisition
    this.startLockTimer(callback);
  }

  releaseRowLock() {
    // Simulate row lock release
    clearTimeout(this.lockTimer);
  }

  startLockTimer(callback: Function) {
    this.lockTimer = setTimeout(() => {
      alert('Row lock timeout. Please try again.');
      this.releaseRowLock();
    }, 3000); // 30 seconds timeout

    callback();
  }
  private toggleRowHeader(): void {
    this.showRowHeader = !this.showRowHeader;
    this.renderTabulator();
  }
  handleMenuAction(action: string, row: RowComponent): void {
    switch (action) {
      case 'Add Previous Row':
        this.addRow('Add Previous Row', row);
        break;
      case 'Add Next Row':
        this.addRow('Add Next Row', row);
        break;
      case 'Add Child Row':
        this.addRow('Add Child Row', row);
        break;
      case 'Delete Row':
        this.deleteRow(row);
        break;
      case 'Format Local-Row':
        this.openFormatDialog('F_Local-Row', row);
        break;
      case 'Format Shared-Row':
        break;
      case 'Select Row(s)':
        this.toggleRowHeader();
        break;
    }
  }

  addRow(position: string, row: RowComponent): void {
    if (!row) {
      return; // Handle the absence of a row
    }

    const { sheetType, newRowData } = this.initializeNewRowData(row);
    if (!sheetType || !newRowData) {
      console.error('Unknown sheet type or initialization error');
      return;
    }

    const { currentRowData, currentRowIndex, parentRow } =
      this.getCurrentRowInfo(row);
    if (currentRowIndex === -1) {
      return;
    }

    const siblingInfo = this.getSiblingInfo(
      position,
      parentRow,
      row,
      currentRowIndex
    );
    const dialogRef = this.dialog.open(AddRowDialogComponent, {
      data: { rowDetails: position, rowData: row, siblingInfo: siblingInfo },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.updateNewRowData(newRowData, result, sheetType);
        console.log('New Row Data:', newRowData); // Log the new row data
        this.insertNewRow(
          position,
          row,
          parentRow,
          currentRowIndex,
          newRowData,
          sheetType
        );
      }
    });
  }

  private initializeNewRowData(row: RowComponent): {
    sheetType: string;
    newRowData: any;
  } {
    let sheetType: any = '';
    this.menuBarService.selectedId.subscribe((res) => {
      sheetType = res;
    });

    let newRowData: any = {};
    if (sheetType == pagesId.All_Pages) {
      newRowData = {
        row: '',
        page_id: '',
        page_name: '',
        page_type: '',
        page_edition: '',
        page_owner: '',
        page_url: '',
        page_seo: '',
        page_status: '',
        page_comment: '',
      };
    } else if (sheetType == pagesId.AllCols) {
      newRowData = {
        row: '',
        col_id: '',
      };
    }
    return { sheetType, newRowData };
  }

  private getCurrentRowInfo(row: RowComponent): any {
    const currentRowData = row.getData();
    const data = row.getTable().getData(); // Get all data from the table
    let currentRowIndex = -1;
    let parentRow: any = null;

    if (row.getTreeParent()) {
      parentRow = row.getTreeParent(); // Get the parent of the current row
      const siblings = parentRow.getData()['_children'] || [];
      currentRowIndex = siblings.findIndex(
        (item: { row: any }) => item.row === currentRowData['row']
      );
    } else {
      currentRowIndex = data.findIndex(
        (item) => item.row === currentRowData['row']
      );
    }

    return { currentRowData, currentRowIndex, parentRow };
  }

  private getSiblingInfo(
    position: string,
    parentRow: any,
    row: RowComponent,
    currentRowIndex: number
  ): any {
    const data = row.getTable().getData();
    let siblingInfo = null;

    switch (position) {
      case 'Add Previous Row':
        if (currentRowIndex > 0) {
          siblingInfo = parentRow
            ? parentRow.getData()['_children'][currentRowIndex - 1]
            : data[currentRowIndex - 1];
        }
        break;
      case 'Add Next Row':
        if (parentRow) {
          if (currentRowIndex + 1 < parentRow.getData()['_children'].length) {
            siblingInfo = parentRow.getData()['_children'][currentRowIndex + 1];
          }
        } else {
          if (currentRowIndex + 1 < data.length) {
            siblingInfo = data[currentRowIndex + 1];
          }
        }
        break;
      case 'Add Child Row':
        siblingInfo = row.getData(); // Current row is the parent for child
        break;
    }

    return siblingInfo;
  }

  private updateNewRowData(
    newRowData: any,
    result: any,
    sheetType: string
  ): void {
    if (+sheetType === pagesId.All_Pages) {
      newRowData.page_id = result?.createdPage?.Pg;
    } else if (+sheetType === pagesId.AllCols) {
      newRowData.col_id = result?.createdCol?.Col;
    }
    newRowData.row = result?.createdRow?.Row;
  }

  private insertNewRow(
    position: string,
    row: RowComponent,
    parentRow: any,
    currentRowIndex: number,
    newRowData: any,
    sheetType: string
  ): void {
    if (position === 'Add Previous Row') {
      this.addRowAtPosition(row, parentRow, currentRowIndex, newRowData, 0);
    } else if (position === 'Add Next Row') {
      this.addRowAtPosition(row, parentRow, currentRowIndex, newRowData, 1);
    } else if (position === 'Add Child Row') {
      let children = row.getData()['_children'] || [];
      children.push(newRowData);
      row.update({ _children: children });
      row.getTable().updateData([{ ...row.getData(), _children: children }]);
    }
  }

  private addRowAtPosition(
    row: RowComponent,
    parentRow: any,
    currentRowIndex: number,
    newRowData: any,
    offset: number
  ): void {
    if (parentRow) {
      const parentData = parentRow.getData();
      const siblings = parentData['_children'] || [];
      siblings.splice(currentRowIndex + offset, 0, newRowData);
      parentRow.update({ _children: siblings });
      row.getTable().updateData([{ ...parentData, _children: siblings }]);
      parentRow.reformat();
    } else {
      const data = row.getTable().getData();
      data.splice(currentRowIndex + offset, 0, newRowData);
      row.getTable().setData(data);
    }
  }

  deleteRow(row: RowComponent): void {
    let selectedPageId;
    this.menuBarService.selectedId.subscribe((res) => {
      selectedPageId = res;
    });
    let rowId = row.getData()?.['row'];
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data: "shall be Deleted? Click 'Delete' to confirm ",
        name: 'Row',
        rowId: rowId,
        selectedPage: selectedPageId,
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      const parentRow = row.getTreeParent();
      if (parentRow) {
        const parentData = parentRow.getData();
        const siblings = parentData['_children'] || [];
        const rowIndex = siblings.findIndex(
          (child: { page_id: any }) =>
            child.page_id === row.getData()['page_id']
        );
        if (rowIndex > -1) {
          siblings.splice(rowIndex, 1);
          parentRow.update({ _children: siblings });
          row.getTable().updateData([{ ...parentData, _children: siblings }]);
          parentRow.reformat();
        }
      } else {
        row.getTable().deleteRow(row);
      }
    });
  }
  openFormatDialog(position: string, row: RowComponent) {
    const id = row.getData()['row'];
    this.dialog
      .open(FormatRowDialogComponent, {
        data: {
          data: { title: 'Format Local-Row', id },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        row.getElement().style.backgroundColor = result.split(';')[5];
        row.getElement().style.color = result.split(';')[0];
        row.getElement().style.fontSize = result.split(';')[2];
        row.getElement().style.textAlign = result.split(';')[4];
        if (result.split(';')[3] == '0') {
          row.getElement().style.fontStyle =
            result.split(';')[3] == '0' ? 'normal' : '';
        } else if (result.split(';')[3] == '2') {
          row.getElement().style.fontWeight =
            result.split(';')[3] == '2' ? 'bold' : '';
        } else if (result.split(';')[3] == '1') {
          row.getElement().style.fontStyle =
            result.split(';')[3] == '1' ? 'italic' : '';
        }
        if (result.split(';')[6] == 'italicText') {
          row.getElement().style.fontStyle = 'italic';
        } else if (result.split(';')[6] == 'boldText') {
          row.getElement().style.fontWeight = 'bold';
        } else if (result.split(';')[6] == 'strikeThrough') {
          row.getElement().classList.add('text-linethrough-class');
        } else if (result.split(';')[6] == 'underline') {
          row.getElement().classList.add('text-underline-class');
        } else if (result.split(';')[6] == 'textWrap') {
          row.getElement().classList.add('text-wrap-class');
        } else if (result.split(';')[6] == 'superScript') {
          const element = row.getElement();
          const originalText = element.innerHTML;
          element.innerHTML = originalText.replace(
            originalText,
            '<sup>' + originalText + '</sup>'
          );
        } else if (result.split(';')[6] == 'subScript') {
          const element = row.getElement();
          const originalText = element.innerHTML;
          element.innerHTML = originalText.replace(
            originalText,
            '<sub>' + originalText + '</sub>'
          );
        } else if (result.split(';')[6] == 'textBorder') {
          row.getElement().style.border = '1px solid';
        }
        row.getElement().style.fontFamily = result.split(';')[1];
        this.releaseRowLock();
      });
  }

  public showDropdownMenu(e: MouseEvent, cell: CellComponent): void {
    e.preventDefault();

    const dropdownMenu = document.getElementById('dropdownMenu')!;
    const cellElement = cell.getElement();
    const cellRect = cellElement.getBoundingClientRect();

    // Display the menu
    dropdownMenu.style.display = 'block';

    // Calculate initial position
    let menuX = cellRect.left;
    let menuY = cellRect.bottom + window.scrollY;

    // Get menu dimensions
    const menuWidth = dropdownMenu.offsetWidth;
    const menuHeight = dropdownMenu.offsetHeight;

    // Check right boundary
    if (menuX + menuWidth > window.innerWidth) {
      menuX = window.innerWidth - menuWidth;
    }

    // Check bottom boundary
    if (menuY + menuHeight > window.innerHeight + window.scrollY) {
      menuY = cellRect.top + window.scrollY - menuHeight; // Position above the cell
    }

    // Apply the calculated positions
    dropdownMenu.style.left = `${menuX}px`;
    dropdownMenu.style.top = `${menuY}px`;

    // Stop propagation to prevent document click event from hiding the menu immediately
    const items = dropdownMenu.querySelectorAll('li');
    items.forEach((item) => {
      if (item.id) {
        item.addEventListener('click', (event) =>
          this.handleDropdownItemClick(event, cell, e)
        );
      }
    });

    e.stopPropagation();
  }

  private handleDropdownItemClick = (
    e: Event,
    cell: CellComponent,
    event: MouseEvent
  ) => {
    if (this.dialog) {
      this.dialog.closeAll();
    }
    const target = e.target as HTMLElement;
    const cellValue = event.target as HTMLElement;
    let id: number = 0;
    cell
      .getColumn()
      .getCells()
      .map((cell, index) => {
        if (cell.getData()['page_name'] == cellValue.innerHTML) {
          id = index;
        }
      });
    if (target && target.id) {
      const clickedItemId = target.id;
      if (clickedItemId == 'format-local-cell') {
        const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
          data: {
            clicked: 'Local Cell',
            data: {
              title: 'Format: Local Cell (ID:' + id + ')',
              id: id,
              cell: 'cell',
            },
          },
        });

        dialogRef.afterClosed().subscribe((result) => {
          const columnId = this.currentCell.getColumn().getDefinition().col;
          const rowId = this.currentCell.getData().row;
          const pageId = this.sheet.page_id;
          let res = result?.fontStyleMinWidth.split(':')[0];
          const parsedStyle =
            result.formatLocalColForm.fontStyle != ''
              ? JSON.parse(result.formatLocalColForm.fontStyle)
              : null;

          // Call API to update cell format
          this.formatService.formatLocalCell(
            pageId,
            columnId,
            rowId,
            result.formatLocalColForm
          );

          // Apply formating on cell
          if (parsedStyle != null) {
            this.formatService.applyFormatLocaCell(cell, parsedStyle);
          }
        });
      } else if (clickedItemId == 'format-local-item') {
        const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
          data: {
            clicked: 'Local Cell',
            data: { title: 'Format: Local Item (' + 'ID:' + id + ')', id: id },
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          let res = result?.fontStyleMinWidth.split(':')[0];
          const changeElement = cell.getElement();
          const originalText = changeElement.innerHTML;
          if (!/<span\b[^>]*>/i.test(originalText)) {
            // If <span> tag is not present, wrap the text with <span>
            changeElement.innerHTML = '<span>' + originalText + '</span>';
          }
          const element = changeElement.querySelector('span');
          if (element) {
            element.style.backgroundColor = res.split(';')[5];
            element.style.color = res.split(';')[0];
          }
          cell.getElement().style.fontSize = res.split(';')[2];
          cell.getElement().style.textAlign = res.split(';')[4];
          if (res.split(';')[3] == '0') {
            cell.getElement().style.fontStyle =
              res.split(';')[3] == '0' ? 'normal' : '';
          } else if (res.split(';')[3] == '2') {
            cell.getElement().style.fontWeight =
              res.split(';')[3] == '2' ? 'bold' : '';
          } else if (res.split(';')[3] == '1') {
            cell.getElement().style.fontStyle =
              res.split(';')[3] == '1' ? 'italic' : '';
          }
          if (res.split(';')[6] == 'italicText') {
            cell.getElement().style.fontStyle = 'italic';
          } else if (res.split(';')[6] == 'boldText') {
            cell.getElement().style.fontWeight = 'bold';
          } else if (res.split(';')[6] == 'strikeThrough') {
            cell.getElement().classList.add('text-linethrough-class');
          } else if (res.split(';')[6] == 'underline') {
            cell.getElement().classList.add('text-underline-class');
          } else if (res.split(';')[6] == 'textWrap') {
            cell.getElement().classList.add('text-wrap-class');
          } else if (res.split(';')[6] == 'superScript') {
            const element = cell.getElement();
            const originalText = element.innerHTML;
            element.innerHTML = originalText.replace(
              originalText,
              '<sup>' + originalText + '</sup>'
            );
          } else if (res.split(';')[6] == 'subScript') {
            const element = cell.getElement();
            const originalText = element.innerHTML;
            element.innerHTML = originalText.replace(
              originalText,
              '<sub>' + originalText + '</sub>'
            );
          } else if (res.split(';')[6] == 'textBorder') {
            cell.getElement().style.border = '1px solid';
          }
          cell.getElement().style.fontFamily = res.split(';')[1];
        });
      }
    }
  };
  private hideDropdownMenu(): void {
    const dropdownMenu = document.getElementById('dropdownMenu')!;
    dropdownMenu.style.display = 'none';
  }

  private generateRecords(): void {}

  private showErrorBelowElement(element: HTMLElement, message: string): void {
    const errorMessageElement = document.getElementById('errorMessage');
    if (errorMessageElement) {
      errorMessageElement.innerHTML = `<span style="color: red;background-color: white ">${message}</span>`;
      // Position the error message below the target element
      const rect = element.getBoundingClientRect();
      errorMessageElement.style.position = 'absolute';
      errorMessageElement.style.top = `${rect.bottom + window.scrollY}px`;
      errorMessageElement.style.left = `${rect.left}px`;
      errorMessageElement.style.display = 'block';
    }
  }
  getLanguageList() {
    this.store.dispatch(new Language.FetchLanguages('1000000012'));
    this.languages$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      data?.data['ALL Languages'].map((item: any, index: number) => {
        this.languageArray[index] = {
          language: Object.values(item),
          row: Object.keys(item),
        };
      });
    });
  }
  errorMessage = false;
  addItem(e: Event) {
    if (!this.currentCell) return;
    this.getLanguageList();
    const field = this.currentCell.getField();
    const column = this.tabulatorTable.getColumn(field);
    const colDataType = (column.getDefinition() as any).datatype;
    let row_id = 0;
    this.tabulatorTable.getRows().map((data) => {
      if (data.getData()['row'] == this.newRowData.row) {
        row_id = this.newRowData.row;
      }
    });
    let dialogRef;
    switch (colDataType) {
      case 'PageID':
        dialogRef = this.dialog.open(DialogAddComponent, {
          data: { value: this.currentCell.getValue() },
        });
        break;
      case 'DropDown':
        dialogRef = this.dialog.open(DropdownDialogComponent, {
          data: {
            cellValue: this.currentCell.getValue(),
            columnId: this.currentCell.getColumn().getDefinition().col,
            rowId: this.currentCell.getData().row,
            columnDatatype: 3000001035,
            pageId: this.sheet.page_id,
            mode: 'Add',
          },
        });
        break;
      case 'URL':
        dialogRef = this.dialog.open(DialogEditUrlComponent, {
          data: { value: this.currentCell.getValue() },
        });
        break;
      case 'MLText':
        let id: number = 0;
        this.currentCell
          .getColumn()
          .getCells()
          .map((cell: any, index: number) => {
            if (cell.getData()['page_name'] == this.currentCell.getValue()) {
              id = index;
            }
          });
        dialogRef = this.dialog.open(DialogAddComponent, {
          data: {
            value: this.currentCell.getValue(),
            language: this.languageArray,
            row_id: row_id,
            dataType: colDataType,
          },
        });
        dialogRef.afterClosed().subscribe((result) => {
          const languageGroups = result['languageGroups'] || [];
          // Extract 'id' and 'richText' from each item and join them into a comma-separated string
          const ids = languageGroups
            .map((data: any) => data?.language)
            .filter((id: any) => id != null); // Filter out any null or undefined values

          const richTexts = languageGroups
            .map((data: any) => data?.richText)
            .filter((text: any) => text != null); // Filter out any null or undefined values

          // Join them into comma-separated strings
          const commaSeparatedIds = ids.join(', ');
          const commaSeparatedRichTexts = richTexts.join(', ');
          const row = this.currentCell.getData()['row'];
          // Join the array in  to a semi-colon separated string
          if (row) {
            this.store.dispatch(
              new Item.AddItemData({
                colId:
                  this.currentCell.getColumn()['_column']['definition']['col'],
                rowId: row,
                DataType: '3000000601',
                Object: '3000001015',
                SmallInt: 5,
                BigInt: row,
                Color: 'red',
                DateTime: '2024-08-20T15:30:00Z',
                JSON: {
                  [commaSeparatedIds]: commaSeparatedRichTexts.replace(
                    /<[^>]*>?/gm,
                    ''
                  ),
                },
                Qty: 10,
                Unit: row,
                StdQty: 100,
                StdUnit: row,
              })
            );
          }
        });
        break;
      // Handle other colDataTypes as needed
      default:
        dialogRef = this.dialog.open(DialogAddComponent, {
          data: { value: this.currentCell.getValue() },
        });
    }

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        const field = this.currentCell.getField();
        if (field === 'page_name' || field === 'page_id') {
          const isDuplicate = this.allData.some(
            (row: any) =>
              row[field] === result &&
              row !== this.currentCell.getRow().getData()
          );

          if (isDuplicate) {
            this.currentCell.getElement().style.backgroundColor = 'red';
            this.errorMessage = true; // Show the error message
            this.showErrorBelowElement(
              this.currentCell.getElement(),
              `The ${field} "${result}" is already occupied by another entry.`
            );
          } else {
            this.currentCell.setValue(result);
            this.currentCell.getElement().style.backgroundColor = '';
            this.errorMessage = false; // Hide the error message
            this.showErrorBelowElement(this.currentCell.getElement(), '');
          }
        } else {
          this.currentCell.setValue(result);
          this.errorMessage = false; // Hide the error message
          this.showErrorBelowElement(this.currentCell.getElement(), '');
        }
      }
    });
  }

  editItem(e: Event) {
    if (!this.currentCell) return;
    this.getLanguageList();
    const field = this.currentCell.getField();
    const column = this.tabulatorTable.getColumnLayout();
    let matchingColumn = column.find((col) => col.field === field);
    let row_id = 0;
    this.tabulatorTable.getRows().map((data) => {
      if (data.getData()['page_name'] == this.currentCell.getValue()) {
        row_id = data.getData()['row'];
      }
    });

    if (matchingColumn) {
      const datatype = (matchingColumn as any).datatype; // Access the datatype using type assertion
      let dialogRef;
      switch (datatype) {
        case 'PageID':
          dialogRef = this.dialog.open(DialogEditComponent, {
            data: { value: this.currentCell.getValue() },
          });
          break;
        case 'URL':
          dialogRef = this.dialog.open(DialogEditUrlComponent, {
            data: { value: this.currentCell.getValue() },
          });
          break;
        case 'DropDown':
          dialogRef = this.dialog.open(DropdownDialogComponent, {
            data: {
              cellValue: this.currentCell.getValue(),
              columnId: this.currentCell.getColumn().getDefinition().col,
              rowId: this.currentCell.getData().row,
              pageId: this.sheet.page_id,
              mode: 'Edit',
            },
          });
          break;
        case 'RowID':
          dialogRef = this.dialog.open(DialogEditComponent, {
            data: { value: this.currentCell.getValue() },
          });
          break;
        case 'UserID':
          dialogRef = this.dialog.open(DialogEditComponent, {
            data: { value: this.currentCell.getValue() },
          });
          break;
        case 'MLText':
          let id: number = 0;
          this.currentCell
            .getColumn()
            .getCells()
            .map((cell: any, index: number) => {
              if (cell.getData()['page_name'] == this.currentCell.getValue()) {
                id = index;
              }
            });

          dialogRef = this.dialog.open(DialogEditComponent, {
            data: {
              value: this.currentCell.getValue(),
              language: this.languageArray,
              row_id: row_id,
              dataType: datatype,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            const languageGroups = result['languageGroups'] || [];
            // Extract 'id' and 'richText' from each item and join them into a comma-separated string
            const ids = languageGroups
              .map((data: any) => data?.language)
              .filter((id: any) => id != null); // Filter out any null or undefined values

            const richTexts = languageGroups
              .map((data: any) => data?.richText)
              .filter((text: any) => text != null); // Filter out any null or undefined values

            // Join them into comma-separated strings
            const commaSeparatedIds = ids.join(', ');
            const commaSeparatedRichTexts = richTexts.join(', ');
            const row = this.currentCell.getData()['row'];

            // Join the array into a semi-colon separated string

            if (row) {
              this.store.dispatch(
                new Item.EditItemData({
                  colId:
                    this.currentCell.getColumn()['_column']['definition'][
                      'col'
                    ],
                  rowId: row,
                  pageId: this.sheet.page_id,
                  DataType: '3000000601',
                  Object: '3000001015',
                  SmallInt: 5,
                  BigInt: row,
                  Color: 'red',
                  DateTime: '2024-08-20T15:30:00Z',
                  JSON: {
                    [commaSeparatedIds]: commaSeparatedRichTexts.replace(
                      /<[^>]*>?/gm,
                      ''
                    ),
                  },
                  Qty: 10,
                  Unit: row,
                  StdQty: 100,
                  StdUnit: row,
                })
              );
              // this.editItemData$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data:any) => {
              //   console.log(data);
              //   let itemValue=data['data']['Item-Creation']['createdItem']['JSON'][commaSeparatedIds];
              //   this.newRowData = {
              //     row: row_id,
              //     page_id: row?.getData()['page_id'],
              //     page_name: itemValue,
              //     page_type: '',
              //     page_edition: '',
              //     page_owner: '',
              //     page_url: 'URL to open this Page',
              //     page_seo: '',
              //     page_status: '',
              //     page_comment: '',
              //   };
              //   const parentRow = row.getTreeParent();

              // if (parentRow) {
              //   // In a tree structure
              //   const parentData = parentRow.getData();
              //   const siblings = parentData['_children'] || [];
              //   const rowIndex = siblings.findIndex(
              //     (child: { page_id: any }) =>
              //       child.page_id === row.getData()['page_id']
              //   );

              //   if (rowIndex !== -1) {
              //     // Replace the old row data with newRowData
              //     siblings[rowIndex] = this.newRowData;
              //     parentRow.update({ _children: siblings });
              //     row
              //       .getTable()
              //       .updateData([{ ...parentData, _children: siblings }]);
              //     parentRow.reformat();
              //   }
              // } else {
              //   // In a flat data table
              //   const data = row.getTable().getData();
              //   const rowIndex = data.findIndex(
              //     (item) => item.page_id == row.getData()['page_id']
              //   );

              //   if (rowIndex !== -1) {
              //     // Replace the old row data with newRowData
              //     data[rowIndex] = this.newRowData;
              //     row.getTable().setData(data);
              //   }
              // }
              // })
            }
          });

          break;
        // Handle other colDataTypes as needed
        default:
          dialogRef = this.dialog.open(DialogEditComponent, {
            data: { value: this.currentCell.getValue() },
          });
      }

      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          const field = this.currentCell.getField();
          if (field === 'page_name' || field === 'page_id') {
            const isDuplicate = this.allData.some(
              (row: any) =>
                row[field] === result &&
                row !== this.currentCell.getRow().getData()
            );
            if (isDuplicate) {
              this.currentCell.getElement().style.backgroundColor = 'red';
              this.errorMessage = true; // Show the error message
              setTimeout(() => {
                this.showErrorBelowElement(
                  this.currentCell.getElement(),
                  `The ${field} "${result}" is already occupied by another entry.`
                );
              }, 0);
            } else {
              this.currentCell.setValue(result);
              this.currentCell.getElement().style.backgroundColor = '';
              this.errorMessage = false; // Hide the error message
              this.showErrorBelowElement(this.currentCell.getElement(), '');
            }
          } else {
            this.currentCell.setValue(result);
            this.errorMessage = false; // Hide the error message
            this.showErrorBelowElement(this.currentCell.getElement(), '');
          }
        }
      });
    }
  }

  deleteItem() {
    const dialogRef = this.dialog.open(DialogDeleteComponent, {
      data: {
        data:
          'Alert! Item (ID:' +
          this.currentCell.getData()['page_name'] +
          ") shall be Deleted? Click 'Delete' to confirm.",
        name: 'Item',
        rowId: this.currentCell.getData()['row'],
        colId: this.currentCell.getColumn()['_column']['definition']['col'],
        selectedPage: this.sheet.page_id,
      },
    });

    this.showDropdown = false;
    document.body.removeEventListener('click', this.hideDropdown);
  }

  formatLocalItem() {
    // Handle format local item logic here
    const dialogRef = this.dialog.open(DialogFormatComponent, {
      data: {
        clicked: 'Local Item',
        data:
          'Format Local Item Here ' + this.currentCell._cell['value'] + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatSharedItem() {
    // Handle format shared item logic here
    const dialogRef = this.dialog.open(DialogFormatComponent, {
      data: {
        clicked: 'Shared Item',
        data:
          'Format Shared Item Here ' + this.currentCell._cell['value'] + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  formatSharedCell() {
    // Handle format shared cell logic here
    const dialogRef = this.dialog.open(DialogFormatComponent, {
      data: {
        clicked: 'Shared Cell',
        data: {
          data: { title: 'Format: Shared Cell (ID: 1)' },
        },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {});
  }

  viewCell() {
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Cell',
        data: 'View Cell Values Here ' + this.currentCell._cell['value'] + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  viewItem() {
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'Item',
        data: 'View Cell Values Here ' + this.currentCell._cell['value'] + ' ?',
      },
      panelClass: 'view-dialog',
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  viewAll() {
    // Handle view all logic here
    const dialogRef = this.dialog.open(DialogViewComponent, {
      data: {
        clicked: 'All',
        data: 'View Cell Values Here ' + this.currentCell._cell['value'] + ' ?',
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == true) {
      }
    });
  }

  formatLocalCol() {
    let id: any;
    let res = '';
    let res1 = '';
    let expanded: any;
    this.tabulatorTable.getColumns().map((col: any) => {
      if (col.getField() == this.column) {
        id = col.getDefinition()['col'];
      }
    });
    const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
      data: { data: { title: 'Formal Local-Column', id }, shared: false },
      panelClass: 'format-local-column', // Pass the column data and identifier as local
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.colMinWith = result.formatLocalColForm.minWidth;
        res1 = result.formatLocalColForm.fontStyle;
        this.store.dispatch(
          new Sheet.FormatLocalColData(
            {
              userId: 3000000099,
              colid: id,
              Status: [id, 3000000099],
              MinWidth: this.colMinWith,
              FontStyle: res1,
              Comment: 'For Testing Purpose Only',
              Transactions: 3000000099,
            },
            this.sheet.page_id
          )
        );
        this.getFormatLocalCol();
        this.tabulatorTable.on('dataTreeRowCollapsed', () => {
          this.getFormatLocalCol();
        });
        this.tabulatorTable.on('dataTreeRowExpanded', () => {
          this.getFormatLocalCol();
        });
        const columnDef = {
          ...this.currentColumn?.getDefinition(),
          ...result, // Apply formatted properties
        };

        // Update the column in Tabulator
        this.currentColumn?.updateDefinition(columnDef);

        // Update the columnData array
        const columnIndex = this.columnData.findIndex(
          (col) => col.field === this.currentColumn?.getField()
        );
        if (columnIndex > -1) {
          this.columnData[columnIndex] = columnDef;
        }

        // Refresh the table to apply changes

        this.showDropdown = false;
        document.body.removeEventListener('click', this.hideDropdown);
      }
    });
  }

  formatSharedCol() {
    const dialogRef = this.dialog.open(FormatLocalSharedColComponent, {
      // ...columnData
      data: {
        ...this.columnData,
        shared: true,
        data: { title: 'Format Shared-Column' },
      },
      panelClass: 'format-shared-column',
      // Pass the column data and identifier as shared
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        // Update the column definition
        const columnDef = {
          ...this.currentColumn.getDefinition(),
          ...result, // Apply formatted properties
        };

        // Update the column in Tabulator
        this.currentColumn.updateDefinition(columnDef);

        // Update the columnData array
        const columnIndex = this.columnData.findIndex(
          (col) => col.field === this.currentColumn.getField()
        );
        if (columnIndex > -1) {
          this.columnData[columnIndex] = columnDef;
        }

        // Refresh the table to apply changes
        this.tabulatorTable.redraw(true);
      }
      this.showDropdown = false;
      document.body.removeEventListener('click', this.hideDropdown);
    });
  }

  viewColumn() {
    this.columnData.map((col) => {
      if (col.field == this.column) {
        const dialogRef = this.dialog.open(ViewColDialogComponent, {
          data: col, // Pass the current column data to the dialog
        });

        dialogRef.afterClosed().subscribe(() => {
          this.showDropdown = false;
          document.body.removeEventListener('click', this.hideDropdown);
        });
      }
    });
  }

  headerClickFunc = (e: any, column: any) => {
    var tabulator = column.getTable();
    this.sortStatus ? '' : tabulator.clearSort();
  };

  updateTableWithFilters(enable: any) {
    // Replace tabulator data without nested data
    if (enable) {
      this.tabulatorTable.replaceData(this.allDataWithoutNested);
    } else {
      this.tabulatorTable.clearHeaderFilter();
      this.tabulatorTable.replaceData(this.allData);
    }

    var headers = document.querySelectorAll('.tabulator .tabulator-header');
    let columns = this.tabulatorTable
      ?.getColumnDefinitions()
      .map((colDef: any) => {
        colDef.headerFilter = enable ? this.customFilterEditor : false;
        return colDef;
      });

    this.tabulatorTable?.setColumns(columns);
  }

  // Debounce function
  debounce(func: any, wait: any) {
    let timeout: any;
    return (...args: any) => {
      const context = this;
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(context, args), wait);
    };
  }

  // Function to split the string based on [Like] and [And]
  splitString(input: string) {
    // Regular expression to match the delimiters [Like] and [And]
    var regex = /\[Like\]|\[And\]/g;

    // Split the string based on the regex
    var parts = input.split(regex);

    // Remove any empty strings resulting from the split
    parts = parts.filter((part) => part.trim() !== '');

    // Extract the delimiters for clarity (optional)
    var delimiters = input.match(regex);

    return { parts, delimiters };
  }

  applyFilters(filterField: string, filterString: string) {
    const filterTypes = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];
    const filters: { type: string[]; strings: string[] }[] = [];
    // Split the input string into filter types and filter strings
    let parts = filterString.match(/\[.*?\]|[^ ]+/g);

    // Check if parts is null and return an empty queryFilter if so
    if (!parts) {
      return [];
    }

    let currentFilterType: string[] | null = null;
    let currentFilterStrings: string[] = [];

    for (let i = 0; i < parts.length; i++) {
      if (parts[i].startsWith('[') && parts[i].endsWith(']')) {
        // If there is an existing filter type and strings, push them to the filters array
        if (currentFilterType) {
          filters.push({
            type: currentFilterType,
            strings: currentFilterStrings,
          });
        }

        // Start a new filter type
        currentFilterType = parts[i]
          .slice(1, -1)
          .split(' ')
          .filter((type) => filterTypes.includes(type));

        currentFilterStrings = [];
      } else {
        // Accumulate filter strings
        currentFilterStrings.push(parts[i].replace(/-+/g, ' '));
      }
    }

    // Push the last filter type and strings
    if (currentFilterType) {
      filters.push({
        type: currentFilterType,
        strings: currentFilterStrings,
      });
    }
    let queryFilter: any[] = [];
    const filterReady = filters.every((filter) => {
      return filter.type.every((filterType) => {
        return filter.strings.every((filterString) => {
          switch (filterType) {
            case 'Like':
              return queryFilter.push({
                field: filterField,
                type: 'like',
                value: filterString,
              });

            case 'Starting':
              return queryFilter.push({
                field: filterField,
                type: 'starts',
                value: filterString,
              });

            case 'Ending':
              return queryFilter.push({
                field: filterField,
                type: 'ends',
                value: filterString,
              });

            case 'Match':
              return queryFilter.push({
                field: filterField,
                type: '=',
                value: filterString,
              });

            case 'And':
              return queryFilter.push({
                field: filterField,
                type: '=',
                value: filterString,
              });

            case 'Or':
              return queryFilter.push([
                { field: filterField, type: '=', value: filterString },
              ]);

            case 'Regex':
              // Handle Regex matching here (if required)
              return;

            case 'Not':
              // Handle 'Not' logic here
              return;

            default:
              return;
          }
        });
      });
    });

    return queryFilter;
  }

  // custom header filter editor
  customFilterEditor = (
    cell: any,
    onRendered: any,
    success: any,
    cancel: any,
    editorParams: any
  ) => {
    var container = document.createElement('span');

    //create and style inputs
    var input = document.createElement('input');
    input.setAttribute('type', 'text');
    input.style.padding = '2px';
    input.style.width = '100%';
    input.value = cell.getValue();

    // Create and style the error message container
    const errorMessage = document.createElement('div');
    errorMessage.style.color = 'red';
    errorMessage.style.display = 'none';
    errorMessage.style.marginTop = '2px';
    errorMessage.style.fontWeight = '200';
    errorMessage.textContent = 'Invalid syntax';

    function buildValues() {
      success({
        inputColumnStatus: cell.getColumn().getDefinition().status,
        inputColumn: cell.getColumn().getField(),
        inputValue: input.value,
      });
    }

    function keypress(e: any) {
      if (e.keyCode == 13) {
        buildValues();
      }

      if (e.keyCode == 27) {
        cancel();
      }
    }

    const validateInputValue = () => {
      const validator = this.validateInput(input.value);
      const tableHeader = document.querySelector(
        '.tabulator-header[role="rowgroup"]'
      ) as HTMLDivElement;
      const tabulator = document.querySelector(
        '.tabulator[role="grid"]'
      ) as HTMLDivElement;
      const tabulatorTable = document.querySelector(
        '.tabulator[tabulator-layout=fitDataFill] .tabulator-tableholder .tabulator-table'
      ) as HTMLDivElement;

      if (!validator) {
        input.style.color = 'red';
        errorMessage.style.display = 'block';
      } else {
        input.style.color = 'black';
        errorMessage.style.display = 'none';
      }
    };

    const debounceBuildValues = this.debounceInput(buildValues, 300);
    const debounceValidateInput = this.debounceInput(validateInputValue, 300);
    function inputEvent(e: any) {
      debounceBuildValues();
      debounceValidateInput();
    }

    input.addEventListener('change', buildValues);
    input.addEventListener('blur', buildValues);
    input.addEventListener('keydown', keypress);
    input.addEventListener('input', inputEvent);

    container.appendChild(input);
    container.appendChild(errorMessage);

    return container;
  };

  debounceInput(func: any, wait: any) {
    let timeout: any;
    return (...args: any) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  validateInput(inputValue: string) {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
      'R',
      'L',
      'S',
      'E',
      '&',
      '|',
      'M',
      '!',
    ];
    const validationPassed = true;
    const validationFailed = false;

    // Split the input string into filter types and filter strings
    let parts = inputValue?.match(/\[.*?\]|[^ ]+/g);

    const filters = this.createFilters(parts);
    const validator = filters.every((filter: any) => {
      return filter.types.every((type: any) => {
        if (filterTypes.includes(type) && filter.strings.length > 0) {
          if (type == 'Regex' || type == 'R') {
            return filter.strings.every((string: string) => {
              if (!this.isValidRegex(string)) {
                return false;
              }
              if (string.trim() === '') {
                return false;
              }
              return true;
            });
          }
          return true;
        }
      });
    });

    // If validator fails or parts couldn't be split properly, return false
    if (!validator || (inputValue && !parts)) {
      return validationFailed;
    }

    // Specific edge cases for syntax validation
    if (parts && parts.length > 0 && parts[0] === '[]') {
      return validationFailed;
    }

    if (parts) {
      for (let i = 0; i < parts?.length; i++) {
        // Check for consecutive or mismatched brackets
        if (
          parts[i]?.startsWith('[') &&
          parts[i]?.endsWith(']') &&
          parts.length > 1 &&
          parts[i + 1]?.startsWith('[') &&
          parts[i + 1]?.endsWith(']')
        ) {
          return validationFailed;
        } else if (
          (parts[i]?.startsWith('[') && !parts[i]?.endsWith(']')) ||
          parts[i]?.startsWith(']')
        ) {
          return validationFailed;
        }
      }
    } else {
      return validationPassed;
    }
    return validationPassed;
  }

  isValidRegex(pattern: string) {
    try {
      new RegExp(pattern);
      return true; // If no error is thrown, the regex is valid
    } catch (e) {
      return false; // If an error is thrown, the regex is invalid
    }
  }

  // Custom filter function
  filterData(rowData: any, filterString: any) {
    const filterLower = filterString.toLowerCase();

    // Base case: Check if there are no children
    if (!rowData?._children || rowData._children.length === 0) {
      return rowData?.page_name?.toLowerCase().includes(filterLower)
        ? { page_name: rowData.page_name }
        : null;
    }

    // Check if the parent matches the filter string
    const parentMatches = rowData?.page_name
      ?.toLowerCase()
      .includes(filterLower);

    // Recursively check children for matches
    const matchedChildren = rowData._children
      .map((child: any) => this.filterData(child, filterString)) // Call recursively
      .filter((child: any) => child !== null); // Remove null results

    // If there are matched children, return them without the parent
    if (matchedChildren.length > 0) {
      // debugger;
      return matchedChildren; // Return matched children
    }

    // If the parent matches and no children matched, return the parent
    if (parentMatches && matchedChildren.length === 0) {
      return { page_name: rowData.page_name }; // Return only the parent if no children matched
    }

    // If neither matches, return null or handle accordingly
    return null; // or whatever you need in case of no matches
  }

  customFilterFunction = (
    headerValue: any,
    rowValue: any,
    rowData: any,
    filterParams: any
  ) => {
    // Split the input string into filter types and filter strings
    let parts = headerValue.inputValue.match(/\[.*?\]|[^ ]+/g);

    // Validate input
    const validator = this.validateInput(headerValue.inputValue);
    if (!validator) return true;

    const filters = this.createFilters(parts);

    // Return no records in case of no filter found
    if (filters.length == 0) {
      return true;
    }

    if (this.checkColStatus(headerValue.inputColumnStatus)) {
      const search = ';';
      const replacement = ' ';

      // Create a regular expression with the global flag
      rowValue = rowValue?.replace(new RegExp(search, 'g'), replacement);

      // Split the string by space, trim each word, and filter out any empty elements
      rowValue = rowValue
        ?.split(' ')
        .map((name: string) => name.trim())
        .filter((name: string) => name);

      // Join the cleaned names back into a single string
      rowValue = rowValue?.join(' ');
    }

    if (rowValue && headerValue.inputValue) {
      // Apply filters
      const filteration = filters.every((filter: any) => {
        return filter.types.every((filterType: string) => {
          return filter.strings.every((str: string) => {
            const value = rowValue?.toString();
            const filterString = str?.replace(/^['"](.*)['"]$/, '$1');
            const lowerValue = value?.toLowerCase();
            const lowerFilterString = filterString?.toLowerCase();
            switch (filterType) {
              case 'Like':
              case 'L': // Case-insensitive partial match
                return lowerValue?.includes(lowerFilterString);
              case 'Starting':
              case 'S': // Case-insensitive starting match
                return lowerValue?.startsWith(lowerFilterString);
              case 'Ending':
              case 'E': // Case-insensitive starting match
                return lowerValue?.endsWith(lowerFilterString);
              case 'Match':
              case 'M': // Case-sensitive partial match
                return rowValue?.includes(filterString);
              case 'Regex':
              case 'R':
                try {
                  return new RegExp(filterString).test(value);
                } catch (e) {
                  console.error('Invalid regex pattern:', filterString);
                  return false;
                }
              case 'And':
              case '&': // Case-insensitive "and" match for multiple terms
                return filterString
                  .toString()
                  .split(' ')
                  .every((str: string) =>
                    lowerValue.includes(str.toLowerCase())
                  );
              case 'Or':
              case '|': // Case-insensitive "or" match for multiple terms
                return filterString
                  .toString()
                  .split(' ')
                  .some((str: string) =>
                    lowerValue.includes(str.toLowerCase())
                  );
              case 'Not':
              case '!': // Case-insensitive "not" match
                return !lowerValue.includes(lowerFilterString);
              default: // Default case uses 'Like' and 'And' behavior if no filter type is given
                return filterString
                  .toString()
                  .split(' ')
                  .every((str: string) =>
                    lowerValue.includes(str.toLowerCase())
                  );
            }
          });
        });
      });
      return filteration;
    }
  };

  checkColStatus(statuses: []): boolean {
    const rules = ['Item#≥0', '≥1', '≤2', '=2', '≥2'];

    // Find the first status that includes 'Item'
    const status = statuses.find(
      (status: string) => typeof status === 'string' && status.includes('Item')
    );
    if (status && rules.includes(status)) return true;
    return false;
  }

  createFilters(parts: any) {
    const filters: any = [];
    let currentFilterTypes: any = [];
    let currentFilterStrings: any = [];

    if (!parts) return [];
    if (parts.length == 0) return [];

    // Creaet filters based on provided parts of filter-type and filter-string
    for (let i = 0; i < parts.length; i++) {
      if (parts[i].startsWith('[') && parts[i].endsWith(']')) {
        // If there is an existing filter type and strings, push them to the filters array
        if (currentFilterStrings.length > 0) {
          filters.push({
            types:
              currentFilterTypes.length == 0 ? ['Like'] : currentFilterTypes,
            strings: currentFilterStrings,
          });

          currentFilterTypes = [];
          currentFilterStrings = [];
        }

        // Push parsed filter type
        const filterType = this.parseFilterType(parts[i]);
        currentFilterTypes.push(...filterType);
      } else {
        // Accumulate filter strings
        currentFilterStrings.push(parts[i]);

        // TODO: Uncomment the below line of code, as this will be used to filter hyphrn (-) separated words
        // currentFilterStrings.push(parts[i].replace(/-+/g, ' '));
      }
    }

    // Push the last filter type and strings
    if (currentFilterTypes.length > 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: currentFilterTypes.map((type: any) => type),
        strings: currentFilterStrings,
      });
    }

    // Apply the "Like" if filter-type is not present
    if (currentFilterTypes.length == 0 && currentFilterStrings.length > 0) {
      filters.push({
        types: ['Like'],
        strings: currentFilterStrings,
      });
    }

    // Push empty filter-type for empty filter-type
    if (currentFilterTypes.length > 0 && currentFilterStrings.length == 0) {
      filters.push({
        types: currentFilterTypes,
        strings: currentFilterStrings,
      });
    }
    return filters;
  }

  parseFilterType(filterType: string) {
    const filterTypes: any = [
      'Like',
      'Starting',
      'Ending',
      'Match',
      'Regex',
      'And',
      'Or',
      'Not',
    ];
    if (!filterType.slice(1, -1)) {
      return ['[]'];
    }
    const parsedFilterType = filterType
      .slice(1, -1)
      .split(' ')
      .map(
        (type: string) => type[0]?.toUpperCase() + type.slice(1)?.toLowerCase()
      )
      .map((type: string) => {
        let processedType = type;
        if (type == 'Start(ing)') {
          processedType = 'Starting';
        } else if (type == 'End(ing)') {
          processedType = 'Ending';
        }
        return processedType;
      });

    return parsedFilterType;
  }
  onAddSheet(data: any) {
    this.sheetService.requestAddSheet(data);
  }
  getFormatLocalCol() {
    let res: any = '';
    let appliedStyle: any = '';
    this.res$?.pipe(takeUntil(this.unsubscribe$)).subscribe((data) => {
      if (data != undefined) {
        res = data?.data['Local Col']?.FontStyle;
        appliedStyle = JSON.parse(res)?.FontStyle;
        this.tabulatorTable.redraw(true);
        this.tabulatorTable.getColumns().map((col, index) => {
          if (col.getField() == this.column) {
            col.getCells().map((col) => {
              if (col.getElement().classList.contains('tabulator-cell')) {
                col.getElement().style.minWidth = this.colMinWith + 'px';
                col.getElement().style.backgroundColor =
                  appliedStyle['Background Color'];
                col.getElement().style.color = appliedStyle['Font Color'];
                col.getElement().style.fontSize = appliedStyle['Size'];
                col.getElement().style.textAlign =
                  appliedStyle['Text Alignment'];
                if (appliedStyle['Font Style'] == 'Regular') {
                  col.getElement().style.fontStyle =
                    appliedStyle['Font Style'] == 'Regular' ? 'normal' : '';
                } else if (appliedStyle['Font Style'] == 'Bold') {
                  col.getElement().style.fontWeight =
                    appliedStyle['Font Style'] == 'Bold' ? 'bold' : '';
                } else if (appliedStyle['Font Style'] == 'Italic') {
                  col.getElement().style.fontStyle =
                    appliedStyle['Font Style'] == 'Italic' ? 'italic' : '';
                }
                if (appliedStyle['Effects'] == 'italicText') {
                  col.getElement().style.fontStyle = 'italic';
                } else if (appliedStyle['Effects'] == 'boldText') {
                  col.getElement().style.fontWeight = 'bold';
                } else if (appliedStyle['Effects'] == 'strikeThrough') {
                  col.getElement().classList.add('text-linethrough-class');
                } else if (appliedStyle['Effects'] == 'underline') {
                  col.getElement().classList.add('text-underline-class');
                } else if (appliedStyle['Effects'] == 'textWrap') {
                  col.getElement().classList.add('text-wrap-class');
                } else if (appliedStyle['Effects'] == 'superScript') {
                  const element = col.getElement();
                  const originalText = element.innerHTML;
                  element.innerHTML = originalText.replace(
                    originalText,
                    '<sup>' + originalText + '</sup>'
                  );
                } else if (appliedStyle['Effects'] == 'subScript') {
                  const element = col.getElement();
                  const originalText = element.innerHTML;
                  element.innerHTML = originalText.replace(
                    originalText,
                    '<sub>' + originalText + '</sub>'
                  );
                } else if (appliedStyle['Effects'] == 'textBorder') {
                  col.getElement().style.border = '1px solid';
                }
                if (appliedStyle['Font'] != '') {
                  col.getElement().style.fontFamily = appliedStyle['Font'];
                } else if (appliedStyle['Asian text font'] != '') {
                  col.getElement().style.fontFamily =
                    appliedStyle['Asian text font'];
                } else {
                  col.getElement().style.fontFamily =
                    appliedStyle['Asian text font'] +
                    ',' +
                    appliedStyle['Font'];
                }
              }
            });
          }
        });
      }
    });
  }
}
