import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TabBarComponent } from '../modules/main/tab-bar/tab-bar.component';
import { MenuBarComponent } from '../modules/main/menu-bar/menu-bar.component';
import { SheetComponent } from '../modules/main/sheet/sheet.component';

import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { DialogEditComponent } from '../modals/dialog-edit/dialog-edit.component';
import { AngularWindowComponent } from '../modals/angular-window/angular-window.component';
import { TooltipComponent } from './components/tooltip/tooltip.component';
import { DialogDeleteComponent } from '../modals/dialog-delete/dialog-delete.component';
import { EditionDialogComponent } from '../modals/edition-dialog/edition-dialog.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DialogViewComponent } from '../modals/dialog-view/dialog-view.component';
import { DialogFormatComponent } from '../modals/dialog-format/dialog-format.component';
import { HttpClientModule } from '@angular/common/http';

import { DialogAddComponent } from '../modals/dialog-add/dialog-add.component';
import { DialogFontComponent } from '../modals/dialog-font/dialog-font.component';
import { ColorPickerModule } from 'ngx-color-picker';
// import { MAT_COLOR_FORMATS, NGX_MAT_COLOR_FORMATS } from '@angular-material-components/color-picker';
import { FormatLocalSharedColComponent } from '../modals/format-local-shared-col/format-local-shared-col.component';
import { ViewColDialogComponent } from '../modals/view-col-dialog/view-col-dialog.component';
import { EditionPGNameDialogComponent } from '../modals/edition-pgname-dialog/edition-pgname-dialog.component';
import { PGEditionDialogComponent } from '../modals/pgedition-dialog/pgedition-dialog.component';
import { OpenEditionDialogComponent } from '../modals/open-edition-dialog/open-edition-dialog.component';
import { FormatRowDialogComponent } from '../modals/format-row-dialog/format-row-dialog.component';
import { MatCardModule } from '@angular/material/card';
import { MatOptionModule } from '@angular/material/core';
import { FormatPageDialogComponent } from '../modals/format-page-dialog/format-page-dialog.component';
import { PGUserDialogComponent } from '../modals/pguser-dialog/pguser-dialog.component';
import { PGColDialogComponent } from '../modals/pgcol-dialog/pgcol-dialog.component';
import { ViewPageDialogComponent } from '../modals/view-page-dialog/view-page-dialog.component';
import { AddRowDialogComponent } from '../modals/add-row-dialog/add-row-dialog.component';
import { DialogEditUrlComponent } from '../modals/dialog-edit-url/dialog-edit-url.component';
import { AllPagesDialogComponent } from '../modals/all-pages-dialog/all-pages-dialog.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { DropdownDialogComponent } from '../modals/dropdown-dialog/dropdown-dialog.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';

import { MatTableModule } from '@angular/material/table';
import { MatExpansionModule } from '@angular/material/expansion';
import { EditAmountDialogComponent } from '../modals/edit-amount-dialog/edit-amount-dialog.component';
import { EditJSONDialogComponent } from '../modals/edit-json-dialog/edit-json-dialog.component';
import { AddEditTextComponent } from '../modals/add-edit-text/add-edit-text.component';
import { AddEditFormulaComponent } from '../modals/add-edit-formula/add-edit-formula.component';
import { PickDdiDialogueComponent } from '../modals/pick-ddi-dialogue/pick-ddi-dialogue.component';
import { PickddiPGColDialogComponent } from '../modals/pick-ddi-dialogue/pickddi-pgcol-dialog/pickddi-pgcol-dialog.component';
import { AutoAdjustWidthDirective } from './directives/auto-adjust-width.directive';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
@NgModule({
  declarations: [
    TabBarComponent,
    MenuBarComponent,
    SheetComponent,
    DialogEditComponent,
    DropdownDialogComponent,
    EditAmountDialogComponent,
    AngularWindowComponent,
    DialogDeleteComponent,
    EditionDialogComponent,
    DialogAddComponent,
    DialogViewComponent,
    DialogFormatComponent,
    DialogFontComponent,
    EditJSONDialogComponent,
    FormatLocalSharedColComponent,
    ViewColDialogComponent,
    AddEditFormulaComponent,
    EditionPGNameDialogComponent,
    PGEditionDialogComponent,
    OpenEditionDialogComponent,
    FormatRowDialogComponent,
    FormatPageDialogComponent,
    PGUserDialogComponent,
    PGColDialogComponent,
    ViewPageDialogComponent,
    AddRowDialogComponent,
    DialogEditUrlComponent,
    AllPagesDialogComponent,
    AddEditTextComponent,
    PickDdiDialogueComponent,
    PickddiPGColDialogComponent,
    AutoAdjustWidthDirective
  ],
  imports: [
    DragDropModule,
    CommonModule,
    MatDialogModule,
    MatToolbarModule,
    MatButtonModule,
    MatIconModule,
    MatMenuModule,
    MatCheckboxModule,
    FormsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatFormFieldModule,
    ColorPickerModule,
    MatCardModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatOptionModule,
    TooltipComponent,
    CKEditorModule,
    MatTableModule,
    MatExpansionModule,
    NgxMaskDirective,
    NgxMaskPipe,
  ],
  providers: [
    // { provide: MAT_COLOR_FORMATS, useValue: NGX_MAT_COLOR_FORMATS }
    provideNgxMask(),
   ],
  exports: [
    TabBarComponent,
    MenuBarComponent,
    SheetComponent,
    AngularWindowComponent,
  ]
})
export class SharedModule { }
