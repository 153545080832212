<h2>Editions</h2>
<ul>
  <li *ngFor="let edition of editions" (click)="selectEdition(edition)">
    {{ edition.name }}: {{ edition.description }}
    <button (click)="moveNext(edition)">Move Next</button>
  </li>
</ul>
<hr>
<h3>Create/Edit Edition</h3>
<input [(ngModel)]="editionName" placeholder="Edition Name" />
<input [(ngModel)]="editionDescription" placeholder="Edition Description" />
<label>
  <input type="checkbox" [(ngModel)]="isPrivate" /> Private
</label>
<br>
<div class="button-group">
  <div class="Chip" (click)="saveEdition()">Save As</div>
  <div class="Chip" (click)="closeDialog()">Close</div>
</div>