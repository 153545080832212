import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ModalDismissReasons, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { constant } from '../../constant';
import { Store } from '@ngxs/store';
import { Sheet } from '../../store/sheet/sheet.actions';

@Component({
  selector: 'app-dialog-edit-url',
  templateUrl: './dialog-edit-url.component.html',
  styleUrls: ['./dialog-edit-url.component.css']
})
export class DialogEditUrlComponent implements OnInit {
  url: string;
  isValidUrl: boolean = false;
  private modalRef!: NgbModalRef;
  closeResult: string = '';

  @ViewChild('content', { static: true }) content: any;

  constructor(
    private modalService: NgbModal,
    public dialogRef: MatDialogRef<DialogEditUrlComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,private store:Store
  ) {
    this.url = data.value ? data.value.originalUrl : '';
  }

  ngOnInit(): void {
    this.open(this.content);
  }

  open(content: any) {
    this.modalRef = this.modalService.open(content, {
      ariaLabelledBy: 'modal-basic-title',
    });
    this.modalRef.result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    switch (reason) {
      case ModalDismissReasons.ESC:
        this.onCancel();
        return 'by pressing ESC';
      case ModalDismissReasons.BACKDROP_CLICK:
        this.onCancel();
        return 'by clicking on a backdrop';
      default:
        return `with: ${reason}`;
    }
  }

  onUrlChange(): void {
    const iframe = document.getElementById('url-preview') as HTMLIFrameElement;
  
    // Check if the URL is valid before setting it as the iframe source
    this.isValidUrl = this.validateUrl(this.url);
  
    if (this.isValidUrl) {
      iframe.src = this.url;
      iframe.onload = () => {
        this.isValidUrl = true;
      };
      iframe.onerror = () => {
        this.isValidUrl = false;
        alert('This website refuses to be embedded.');
      };
    } else {
      iframe.src = '';  // Clear the iframe if the URL is not valid
      alert('Invalid URL format');
    }
  }
  

  validateUrl(url: string): boolean {
    const pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-zA-Z0-9$-_@.&+!*"(),]|(%[0-9a-fA-F][0-9a-fA-F]))+' + // domain
      '(:[0-9]{2,5})?))' + // port
      '(\\/[a-zA-Z0-9$-_@.&+!*"(),]|(%[0-9a-fA-F][0-9a-fA-F]))*$', 'i');
    return pattern.test(url);
  }

  onSave(): void {
    if (this.isValidUrl) {
      // Provide a fallback option to open the URL in a new tab
      if (confirm('This website may refuse to be embedded. Would you like to open it in a new tab?')) {
        window.open(this.url, '_blank');
      } else {
        // Simulate backup creation process
        const backupUrl = this.createBackupUrl(this.url);
        this.dialogRef.close({
          originalUrl: this.url,
          backupUrl: backupUrl
        });
        this.save(this.url, backupUrl);
      }
    }
  }
  

  onCancel(): void {
    this.dialogRef.close();
  }

  private createBackupUrl(originalUrl: string): string {
    return originalUrl + '_backup.html';
  }

  private save(originalUrl: string, backupUrl: string): void {
    const backupData = {
      [constant.original_Url]: originalUrl,
      [constant.backup_Url]: backupUrl
    };
    this.store.dispatch(new Sheet.addUrl(backupData))
  }
}
