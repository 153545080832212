import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TooltipService {
  private activeTooltipSubject = new Subject<string | null>();
  activeTooltip$ = this.activeTooltipSubject.asObservable();

  showTooltip(tooltipId: string) {
    this.activeTooltipSubject.next(tooltipId);
  }

  hideTooltip() {
    this.activeTooltipSubject.next(null);
  }
}