<div class="tab-container">
  <div
    *ngFor="let sheet of sheets; let i = index"
    class="tab"
    [class.active]="i === currentSheetIndex"
  >
    <app-tooltip
      tooltip="Page Name: {{ sheet.page_name }}
    Page Edition: {{ sheet?.page_edition }}
    Page Comment: {{ sheet?.page_comment }}"
    >
      <div class="tab-contain">
        <div
          ngbDropdown
          class="d-inline-block tab-outer-container"
        >
          <div
            class="tab-button"
            (keydown.Shift.Tab)="onShiftTabKeyPress($event, i)"
            (keydown.Tab)="onKeyDown($event, i)"
            (click)="selectSheet(i)"
          >
          <span class="tab-name">{{getTrimmedPageName(sheet)}}</span>
          <span (click)="deleteSheet(i)" class="superscript">ˣ</span>
          </div>
        </div>
      </div>
    </app-tooltip>
  </div>
  <div class="add-sheet" (click)="addSheet()">
    <div>
      {{circle}}
    </div>
    <div class="plusIcon">
      {{plusIcon}}
    </div>
  </div>
</div>
