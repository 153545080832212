<ng-template #content let-modal>
  <angular-window class="add-item-modal" style="background-color:white"  [dragHolder]="header">
    <!-- Modal Header -->
    <div class="modal-header" #header>
      <span class="heading">Edit-JSON-dialog</span>
    </div>

    <!-- Modal Body -->
    <div class="edit-item-container">
      <form>
        <!-- First Row of Inputs -->
        <div class="form-group row-group first-group">
          <div class="first-half-width">
            <!-- Textarea for Input -->
            <textarea
              type="text"
              placeholder="Input"
              class="font-style input-field"
              name="url"
            ></textarea>
          </div>
        </div>

        <!-- Button Group: Save & Cancel -->
        <div class="button-group">
          <div class="Chip" type="button">Save</div>
          <div class="Chip" type="button">Cancel</div>
        </div>
      </form>
    </div>
  </angular-window>
</ng-template>
