<ng-template #content let-modal>
    <angular-window style="background-color:white" class="container-modal" [dragHolder]="header">
        <div class="modal-header" #header>
          <span class="form-title">{{data.data.title}}</span>
        </div>
      <div class="edit-item-container">
        <div class="title-container">
        </div>
        <form>
          <div class="form-group">
            <div class="label-container">
            <label>Format ID*</label>
            </div>
            <input class="custom-input" matInput formControlName="formatId" >   <!-- disabled -->
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Format User*</label>
            </div>
            <input  class="custom-input" matInput formControlName="formatUser">
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Local-Cell ID*</label>
            </div>
            <input  class="custom-input" matInput formControlName="columnId" >   <!-- disabled -->
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Data-Type</label>
            </div>
            <input  class="custom-input" matInput formControlName="columnId" >   <!-- disabled -->
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell DropDown-Source</label>
            </div>
            <input  class="custom-input" matInput formControlName="columnId" >   <!-- disabled -->
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Default-Data</label>
            </div>
            <input  class="custom-input" matInput formControlName="columnId" >   <!-- disabled -->
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Status</label>
            </div>
            <input  class="custom-input" matInput formControlName="status">
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Formula</label>
            </div>
            <input  class="custom-input" matInput formControlName="minWidth">
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Font Style</label>
            </div>
            <input  class="custom-input" matInput formControlName="fontStyle">
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Comment</label>
            </div>
            <input  class="custom-input" matInput formControlName="comment">
          </div>
          <div class="form-group">
            <div class="label-container">
            <label>Cell Transactions</label>
            </div>
            <input  class="custom-input" matInput formControlName="transactions" >   <!-- disabled -->
          
          </div>
        </form>
      </div>
      <div class="button-group">
        <div class="button-container">
        <div class="Chip">Save</div>
        </div>
        <div class="Chip" (click)="onCancel();modal.dismiss('Cross click');dialogRef.close(); ">Cancel</div>
      </div>
    </angular-window>
  </ng-template>