import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-edition-pg-name-dialog',
  templateUrl: './edition-pgname-dialog.component.html',
  styleUrls: ['./edition-pgname-dialog.component.css']
})
export class EditionPGNameDialogComponent {
  pageName!: string;
  pageEdition: string | undefined;

  constructor(public dialogRef: MatDialogRef<EditionPGNameDialogComponent>) {}

  onSave(): void {
    console.log('Save clicked with:', this.pageName, this.pageEdition);
    this.dialogRef.close({ pageName: this.pageName, pageEdition: this.pageEdition });
  }

  onCancel(): void {
    this.dialogRef.close();
  }
}