export namespace Item {
  export class AddItemData {
    static readonly type = '[Item] AddItemData';
    constructor(public payload: any) {}
  }
  
  export class GetItemDropdown {
    static readonly type = '[Item] GetItemDropdown';
    constructor(public payload: any) {}
  }

  export class UpdateItemThroughDropdown {
    static readonly type = '[Item] UpdateItemThroughDropdown';
    constructor(public payload: any) {}
  }

  export class AddItemThroughDropdown {
    static readonly type = '[Item] AddItemThroughDropdown';
    constructor(public payload: any) {}
  }
  
  export class EditItemData {
    static readonly type = '[Item] EditItemData';
    constructor(public payload: any) {}
  }
}
