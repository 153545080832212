<ng-template #content let-modal>
  <angular-window style="background-color:white" [dragHolder]="header">
    <div class="modal-header" #header>
      <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click');dialogRef.close()">
        <span aria-hidden="true">{{closeTab}}</span>
      </button>
    </div>
    <div class="edit-item-container">
      <span class="font-style">{{data?.rowDetails}}</span>
      <div class="button-group">
        <div class="Chip" (click)="onSave()">Save</div>
      <div class="Chip" (click)="onCancel()">Cancel</div>
      </div>
    </div>
  </angular-window>
</ng-template>