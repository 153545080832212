import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { ColumnComponent } from 'tabulator-tables';
import { Select, Store } from '@ngxs/store';
import { Sheet } from '../../store/sheet/sheet.actions';
import { ItemState } from '../../store/item/item.store';
import { Item } from '../../store/item/item.actions';

@Injectable({
  providedIn: 'root'
})
export class ItemService {
  constructor(
    private store: Store,
  ) { }

  updateCellItem(formData: any) {
    // API call through store dispatch
    const payload = {
      pageId: formData.pageId,
      colId: formData.columnId,
      rowId: formData.rowId,
      Object : formData.tokenId
    }
    this.store.dispatch(new Item.UpdateItemThroughDropdown(payload));
  }

  addCellItem(formData: any) {
    // API call through store dispatch
    const payload = {
      pageId: formData.pageId,
      colId: formData.columnId,
      rowId: formData.rowId,
      DataType: formData.columnDatatype,
      Object : formData.tokenId
    }
    this.store.dispatch(new Item.AddItemThroughDropdown(payload));
  }

  getDropdownData(colId?: number) {
    // API call through store dispatch
    this.store.dispatch(new Item.GetItemDropdown(colId));
    const data = [
      {tokenId: 3000000251, pageType: 'Each Page'},
      {tokenId: 3000000252, pageType: 'Pages List'},
      {tokenId: 3000000253, pageType: 'Category'},
      {tokenId: 3000000254, pageType: 'Product'},
      {tokenId: 3000000255, pageType: 'LevelSet'},
      {tokenId: 3000000256, pageType: 'SearchSet'},
    ];

    return data;
  }
}
