import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Edition } from '../page.modal';
import { MenuBarService } from '../../services/menu-bar-service/manu-bar.service';

@Component({
  selector: 'app-edition-dialog',
  templateUrl: './edition-dialog.component.html',
  styleUrls: ['./edition-dialog.component.css'],
})
export class EditionDialogComponent implements OnInit {
  editions: any[] = [];
  editionName: string = '';
  editionDescription: string = '';
  isPrivate: boolean = false;

  constructor(
    public dialogRef: MatDialogRef<EditionDialogComponent>,
    private editionService: MenuBarService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.editionService.getEditions().subscribe((editions) => {
      this.editions = editions;
    });
  }

  selectEdition(edition: Edition): void {
    // this.dialogRef.close(edition);
  }

  saveEdition(): void {
    if (this.editionName && this.editionDescription) {
      const newEdition: Edition = {
        id: 0,
        name: this.editionName,
        description: this.editionDescription,
        isPrivate: this.isPrivate,
      };
      this.editionService.saveEdition(newEdition).subscribe((edition:any) => {
        this.editions.push(edition);
      });
    }
  }

  moveNext(edition: Edition): void {
    const index = this.editions.indexOf(edition);
    if (index < this.editions.length - 1) {
      const temp = this.editions[index];
      this.editions[index] = this.editions[index + 1];
      this.editions[index + 1] = temp;
    }
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
