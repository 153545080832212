import {
  Directive,
  HostListener,
  ElementRef,
  Renderer2,
  AfterViewInit,
} from '@angular/core';

@Directive({
  selector: '[appAutoAdjustWidth]',
})
export class AutoAdjustWidthDirective implements AfterViewInit {
  private shadowElement!: HTMLElement;

  constructor(
    private el: ElementRef<HTMLInputElement>,
    private renderer: Renderer2
  ) {
    this.createShadowElement();
  }

  @HostListener('input')
  onInput(): void {
    this.adjustWidth();
  }

  public adjustWidth(): void {
    const input = this.el.nativeElement;
    const value = input.value || '0';
    this.shadowElement.textContent = value;
    input.style.width = `${this.shadowElement.offsetWidth}px`;
  }

  private createShadowElement(): void {
    this.shadowElement = this.renderer.createElement('span');
    this.renderer.setStyle(this.shadowElement, 'visibility', 'hidden');
    this.renderer.setStyle(this.shadowElement, 'position', 'absolute');
    this.renderer.setStyle(this.shadowElement, 'white-space', 'pre');
    this.renderer.appendChild(document.body, this.shadowElement);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.adjustWidth(); // Adjust width initially
    }, 0);
  }

  ngOnDestroy(): void {
    this.renderer.removeChild(document.body, this.shadowElement); // Cleanup
  }
}
