<div class="form-card">
  <p class="form-title">View Column</p>
    <div class="form-group">
      <label>Column Name: </label>
      {{ data.title }}
    </div>
    <div class="form-group">
      <label>Field: </label>
      {{ data.field }}
    </div>
    <div class="form-group">
      <label>Data Type: </label>
      {{ data.dataType || 'N/A' }}
    </div>
    <div class="form-group">
      <label>Tooltip: </label>
      {{ data.tooltip ? 'Yes' : 'No' }}
    </div>
    <div class="form-group">
      <label>Width: </label>
      {{ data.width || 'auto' }}
    </div>
    <div class="form-group">
      <label>Editor: </label>
      {{ data.editor || 'N/A' }}
    </div>
  <div class="button-group">
    <div class="Chip" (click)="onClose()">Close</div>
  </div>
</div>
