import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { Format } from '../../store/format/format.actions';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor(
    private store: Store,
  ) { }

  formatLocalCell(pageId: number, columnId: number, rowId: number, formatLocalColForm: any) {
    // Prepare payload
    const payload = {
      "userId": 3000000099,
      "colId": columnId,
      "rowId": rowId,
      "pageId": pageId,
      "status": formatLocalColForm.status,
      "minWidth": formatLocalColForm.minWidth,
      "fontStyle": formatLocalColForm.fontStyle != '' ? JSON.parse(formatLocalColForm.fontStyle) : null,
      "comment": formatLocalColForm.comment,
      "txList": formatLocalColForm.transactions
    };

    // API call through store dispatch
    this.store.dispatch(new Format.FormatLocalCell(payload));
  }

  applyFormatLocaCell(cell: any, parsedStyle: any) {
    // Apply styling
    cell.getElement().style.backgroundColor = parsedStyle.FontStyle['Background Color'];
    cell.getElement().style.color = parsedStyle.FontStyle['Font Color'];
    cell.getElement().style.fontSize = parsedStyle.FontStyle['Size'];
    cell.getElement().style.textAlign = parsedStyle.FontStyle['Text Alignment'];
    
    if (parsedStyle.FontStyle['Effects'] == '0') {
      cell.getElement().style.fontStyle = parsedStyle.FontStyle['Effects'] == '0' ? 'normal' : '';
    } else if (parsedStyle.FontStyle['Effects'] == '2') {
      cell.getElement().style.fontWeight = parsedStyle.FontStyle['Effects'] == '2' ? 'bold' : '';
    } else if (parsedStyle.FontStyle['Effects'] == '1') {
      cell.getElement().style.fontStyle = parsedStyle.FontStyle['Effects'] == '1' ? 'italic' : '';
    }

    if (parsedStyle.FontStyle['Font Style'] == 'italicText') {
      cell.getElement().style.fontStyle = 'italic';
    } else if (parsedStyle.FontStyle['Font Style'] == 'boldText') {
      cell.getElement().style.fontWeight = 'bold';
    } else if (parsedStyle.FontStyle['Font Style'] == 'strikeThrough') {
      cell.getElement().classList.add('text-linethrough-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'underline') {
      cell.getElement().classList.add('text-underline-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'textWrap') {
      cell.getElement().classList.add('text-wrap-class');
    } else if (parsedStyle.FontStyle['Font Style'] == 'superScript') {
      const element = cell.getElement();
      const originalText = element.innerHTML;
      element.innerHTML = originalText.replace(
        originalText,
        '<sup>' + originalText + '</sup>'
      );
    } else if (parsedStyle.FontStyle['Font Style'] == 'subScript') {
      const element = cell.getElement();
      const originalText = element.innerHTML;
      element.innerHTML = originalText.replace(
        originalText,
        '<sub>' + originalText + '</sub>'
      );
    } else if (parsedStyle.FontStyle['Font Style'] == 'textBorder') {
      cell.getElement().style.border = '1px solid';
    }

    // Set font family
    cell.getElement().style.fontFamily = parsedStyle.FontStyle['Font'];
  }
}
